.staticPage.howItWorks {
    margin: 40px auto;
}
.staticPage.howItWorks .mainHead {
    text-align: center;
    max-width: 895px;
    width: 100%;
    margin: auto;
}
.staticPage.howItWorks .confidence {
    text-align: left;
    width: 100%;
    margin: auto;
}
.staticPage.howItWorks .stepper {
    max-width: 1200px;
    width: 100%;
    margin: auto;
}
.staticPage.howItWorks .stepper .MuiStepLabel-iconContainer svg {
    width: 60px;
    height: 60px;
    fill: #1d52dd;
    z-index: 1;
}
.staticPage.howItWorks .stepper .MuiStepLabel-label {
    margin-left: 10px;
}
.staticPage.howItWorks .stepper .MuiStepLabel-label h3 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}
.staticPage.howItWorks .stepper .MuiStepConnector-lineVertical {
    display: none;
}
.staticPage.howItWorks .stepper .MuiStepLabel-label p {
    font-weight: 400;
    color: #000;
}
.staticPage.howItWorks .stepper .MuiStepLabel-labelContainer {
    margin-top: 20px;
}
.staticPage.howItWorks .stepper .MuiStepLabel-root {
    align-items: flex-start;
}
.staticPage.howItWorks .mainHead h2 {
    font-weight: 600;
}
.staticPage.howItWorks .mainHead h4 {
    color: #1d52dd;
    font-weight: 600;
    margin-top: 20px;
}
.staticPage.howItWorks .mainHead p {
    margin-top: 12px;
}
.staticPage.howItWorks .mainHead p a {
    color: #1d52dd;
}
.staticPage.howItWorks .stepCounter {
    margin: 70px 0px;
}
.staticPage.howItWorks .stepCounter .line {
    margin: 30px 0px;
}
.staticPage.howItWorks .stepCounter .line::before {
    height: 1.5px;
    content: '';
    width: 100%;
    background-color: #c5cee5;
    position: absolute;
    z-index: -1;
}
.staticPage.howItWorks .stepCounter .line .steps {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    max-width: 1200px;
    margin: auto;
}
.staticPage.howItWorks .stepCounter .line .steps .counts {
    margin-top: -13px;
    text-align: center;
    position: relative;
}
.staticPage.howItWorks .MuiStepper-vertical {
    position: relative;
}
.staticPage.howItWorks .MuiStepper-vertical::before {
    height: 97%;
    content: '';
    width: 3px;
    background-color: #1d52dd;
    position: absolute;
    z-index: 0;
    margin-left: 29px;
}
.staticPage.howItWorks .stepCounter .line .steps .counts::after {
    content: '>';
    font-size: 25px;
    color: #1d52dd;
    position: absolute;
    top: -4px;
    right: 0px;
}
.staticPage.howItWorks .stepCounter .line .counts span.first {
    padding: 6px 16px;
}
.staticPage.howItWorks .stepCounter .line .counts span.last {
    padding: 6px 10px;
}
.staticPage.howItWorks .stepCounter .line .counts span {
    padding: 6px 13px;
    border-radius: 25px;
    background-color: #1d52dd;
    color: #fff;
    font-size: 18px;
}
.staticPage.howItWorks .stepCounter .line .counts p {
    margin-top: 15px;
    position: relative;
}
.staticPage.howItWorks .twoPortions {
    display: flex;
    align-items: flex-start;
}
@media (max-width: 768px) {
    .staticPage.howItWorks .mainHead h2 {
        font-size: 26px;
    }
    .staticPage.howItWorks .stepCounter {
        display: none;
    }
    .staticPage.howItWorks .twoPortions {
        flex-wrap: wrap;
    }
    .staticPage.howItWorks .twoPortions div:nth-child(2) {
        margin-top: 10px;
    }
    .staticPage.howItWorks .mainHead h4 {
        font-size: 20px;
    }
    .staticPage.howItWorks .stepper .MuiStepLabel-iconContainer svg {
        height: 40px;
        width: 40px;
    }
    .staticPage.howItWorks .MuiStepper-vertical::before {
        margin-left: 20px;
    }
    .staticPage.howItWorks .stepper .MuiStepLabel-labelContainer {
        margin-top: 10px;
    }
    /* .staticPage.howItWorks .stepCounter .line .steps
    {
        grid-template-columns: 1fr 1fr;
    } */
}
