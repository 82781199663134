.Collectibles .footer-main-standard {
    padding: 0;
    color: var(--footerFontColor);
    background: var(--footerColor);
    font-size: 15px;
    margin-top: 0;
    padding-top: 40px;
}

.Collectibles .ftrDtlCntnr .footerLogo {
    width: 100%;
    height: 60px;
    object-fit: contain;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 30px;
}

.Collectibles .footer-main-standard-social-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-left: -7px;
    text-align: center;
    margin-bottom: 40px;
}

.Collectibles .footer-main-standard-social-list > li > a {
    background-color: var(--primColor);
    width: 30px;
    height: 30px;
    border-radius: 30px;
    color: #fff;
    opacity: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.Collectibles .footerLinks {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.Collectibles .footer-main-standard a {
    color: var(--footerFontColor);
}

.Collectibles .footer-copy-right {
    padding: 10px 30px;
    background: var(--footerColor);
}

.Collectibles .footer-copy-right p {
    margin: 0;
}

.Collectibles a.cvc-link {
    font-size: 15px;
}

.Collectibles .footer-main-standard-title {
    margin-bottom: 15px;
    color: var(--primColor);
    font-size: 20px;
    position: relative;
    padding-bottom: 15px;
}

.footer-main-standard-newsletter {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
}

.Collectibles .newsletter-wrapper {
    position: relative;
}
.Collectibles .footer-main-standard-newsletter-input {
    width: 100%;
    border: none;
    border-radius: 45px;
    padding: 13px 26px;
    color: #4a4031;
    font-size: 16px;
    border: 1px solid #ddd;
}

.Collectibles .footer-main-standard-newsletter-submit {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    height: 52px;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    background: var(--primColor);
    color: #ffffff;
    border: none;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    padding: 0px 20px;
}

.Collectibles .ftrDtlCntnr .fa,
.Collectibles .ftrDtlCntnr .fab,
.Collectibles .ftrDtlCntnr .fal,
.Collectibles .ftrDtlCntnr .far,
.Collectibles .ftrDtlCntnr .fas {
    font-size: inherit;
    margin: 0 auto;
}

.Collectibles .footerLinks li:not(:last-child) {
    margin-right: 45px;
}

.Collectibles .footerLinks li a {
    font-size: 15px;
    font-weight: 500;
    color: #333;
}

.Collectibles footer .copyright {
    font-size: 12px;
    color: #6f6f6f;
}

.Collectibles .footerLinks li a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.Collectibles .footerLinks li a:hover {
    color: var(--primColor);
}

.Collectibles .footerLinks li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: var(--primColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -ms-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.Collectibles .footerLinks li a:hover:after {
    width: 100%;
    left: 0;
}

@media (max-width: 600px) {
    .Collectibles .footerLinks li a:hover:after {
        display: none;
    }
    /* .Collectibles .footerLinks li:not(:last-child) {
        margin-left: 8px;
        margin-right: 8px;
    } */

    .Collectibles .ftrDtlCntnr .footerLogo,
    .Collectibles .footerLinks {
        margin-bottom: 10px;
    }

    .Collectibles .footer-main-standard-social-list {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .Collectibles footer .copyright {
        margin: 0;
    }

    .Collectibles .footerLinks li a {
        font-size: 13px;
    }
}
@media (max-width: 700px) {
    .Collectibles .footerLinks {
        display: grid !important;
        grid-template-columns: 1fr !important;
        text-align: center;
    }
    .Collectibles .footerLinks li:not(:last-child) {
        margin-right: 0px !important;
    }
    .Collectibles .igive {
        display: flex;
        justify-content: center;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}
