.Fundraiser .slCsBtn {
    border: 1px solid gray;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
}

.Fundraiser .slick-next,
.Fundraiser .slick-prev {
    left: calc(50% - 15px) !important;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -50px;
}

.Fundraiser .slick-next {
    top: unset;
    bottom: -70px;
}

.Fundraiser .slick-next:before,
.Fundraiser [dir='rtl'] .slick-prev:before {
    content: unset !important;
}
