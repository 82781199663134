.cardSelect .cardmain .radio-item {
    display: inline-block;
    position: relative;
}
.cardSelect label.cardmain .vcRad {
    position: relative;
}
.cardSelect label.cardmain .vcRad:before {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 0px;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid var(--primColor);
    background-color: transparent;
}

.cardSelect .cardmain input[type='radio'] {
    opacity: 0;
}

.cardSelect label.cardmain .radio-item input[type='radio']:checked + .vcRad:after {
    border-radius: 11px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 1px;
    right: 5px;
    content: ' ';
    display: block;
    background: var(--primColor);
}
/* radiobox ened */

.cardSelect .cardmain {
    background: #fffaee;
    border: 1px solid var(--primColor);
    border-radius: 10px;
    padding: 12px 20px;
    line-height: 25px;
    margin: 15px auto;
    width: 100%;
}
.cardSelect .flx p {
    margin: auto 0;
}
.cardSelect .flx:nth-child(1) p {
    font-weight: 600;
    font-size: 18px;
}
.cardSelect .flx:nth-child(2) p {
    font-weight: 400;
    color: #333;
    font-size: 16px;
}
.cardSelect .flx {
    display: flex;
    justify-content: space-between;
}
