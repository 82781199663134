.productViewStandalone .mpTop {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
}

.productViewStandalone .plTitle span {
    display: block;
}

.productViewStandalone .plTitle span:first-child {
    font-size: 16px;
    color: #747474;
    margin-bottom: 8px;
}

.productViewStandalone .plTitle span:last-child {
    font-size: 25px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 15px;
}

.productViewStandalone .image-gallery {
    width: 520px;
}

.productViewStandalone .image-gallery-thumbnails-container {
    text-align: start;
}

/* .productViewStandalone .image-gallery-thumbnails {
    width: 75px;
} */

.productViewStandalone .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.productViewStandalone .image-gallery-slide-wrapper.left {
    width: 100%;
}

.productViewStandalone .image-gallery-icon:hover {
    color: var(--primColor);
}

.productViewStandalone .image-gallery-thumbnail {
    border: 2px solid transparent;
    width: 75px;
}

.productViewStandalone .image-gallery-thumbnail.active,
.productViewStandalone .image-gallery-thumbnail:hover,
.productViewStandalone .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 0px;
}

.productViewStandalone .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 60px;
    object-fit: cover;
    border-radius: 0px;
}

.productViewStandalone .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 350px;
    min-height: 300px;
    cursor: initial;
}

.productViewStandalone .image-gallery-content .image-gallery-slide > div {
    background: #efefef;
}

.productViewStandalone .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}

.productViewStandalone .productInfo h5 {
    font-size: 18px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 25px;
}

.productViewStandalone .productInfo h5 span {
    font-size: 15px;
    font-weight: 500;
    width: 200px;
    display: inline-block;
}

.productViewStandalone .pvsCnt {
    display: grid;
    grid-template-columns: 520px auto;
    gap: 20px;
}

.productViewStandalone .sl-pbids.slidView {
    width: auto;
    margin-top: 0;
    margin-left: auto;
}

.productViewStandalone .biddingCnt .customInput {
    margin-bottom: 15px;
}

.productViewStandalone form:last-child .biddingCnt .customInput {
    margin-bottom: 0px;
}

.productViewStandalone .mssBidItem {
    display: flex;
}

.productViewStandalone .mssBidHistory {
    float: right;
    height: 45px;
    width: auto;
    text-transform: initial;
    color: var(--primColor);
}

.productViewStandalone .mssBidHistory .material-icons {
    margin-right: 10px;
}

@media (max-width: 1060px) {
    .productViewStandalone .pvsCnt {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .productViewStandalone .image-gallery {
        width: 100%;
    }
}

@media (max-width: 960px) {
    .productViewStandalone .mpTop {
        flex-wrap: wrap-reverse;
    }

    .productViewStandalone .mpTop .plTitle {
        width: 100%;
    }

    .productViewStandalone .mpTop span {
        margin: auto;
    }

    .productViewStandalone .timerWrapper .timeLabel::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 75%;
        width: 141%;
        background: #dedede;
        height: 1px;
    }
    .ArtAuction .productViewStandalone .timerWrapper .timeLabel::after {
        left: 0%;
    }
    .productViewStandalone .timerWrapper .timeLabel::before {
        position: absolute;
        content: '';
        top: 50%;
        right: 75%;
        left: unset;
        width: 141%;
        background: #dedede;
        height: 1px;
    }

    .productViewStandalone .MuiTabScrollButton-root .MuiSvgIcon-root {
        color: var(--primColor);
    }
}

@media (max-width: 600px) {
    .pvsBottom > header {
        overflow: hidden;
    }
}
