.searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.FoodIndustry .siteBanner {
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.FoodIndustry .siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.FoodIndustry .siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.FoodIndustry .siteBanner .bannerContent {
    position: absolute;
    top: 80px;
    left: 60px;
    z-index: 999999999;
}

.FoodIndustry .siteBanner .bannerContent h2 {
    font-size: 34px;
    margin-bottom: 20px;
    color: var(--bannerTextColor);
    font-weight: 500;
    letter-spacing: 1px;
    max-width: 500px;
    width: 100%;
    line-height: 50px;
}

.FoodIndustry .siteBanner .bannerContent p {
    font-size: 15px;
    margin-bottom: 45px;
    color: #333;
    width: 100%;
    max-width: 600px;
}

.FoodIndustry .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.FoodIndustry .searchContainer .searchLt {
    position: -webkit-sticky;
    position: sticky;
    top: 115px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.searchContainer .searchRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.FoodIndustry .searchContainer .lotResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.FoodIndustry .card.buynow .gridItemAct,
.FoodIndustry .card .gridItemAct,
.FoodIndustry .unauth .card.buynow .timer-text .primButton {
    /* margin-top: 22px; */
}

.FoodIndustry .liquidationBuyCard.auth .mslMisc .biddingCnt button {
    border-radius: 4px !important;
    margin-left: 5px !important;
}

.FoodIndustry .grid-card .hostedBy {
    min-height: 18px;
}

.FoodIndustry .grid-card .gcTimer {
    margin: 5px 0;
}

@media (max-width: 1145px) {
    .searchContainer .searchLt {
        max-width: 250px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }
    .searchContainer .searchLt {
        display: none;
    }

    .searchContainer .searchRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff;
        border-color: var(--primColor) !important;
    }
    .customBreadcrumbs ol {
        width: 100%;
    }
    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }

    .FoodIndustry .mblStckyFilter {
        position: sticky;
        top: 65px;
        background: white;
        z-index: 999;
        display: flex;
        align-items: center;
        margin: 0px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .FoodIndustry .mblStckyFilter .customSelect {
        margin-bottom: 0px;
        width: 25%;
    }

    .FoodIndustry .mblStckyFilter .customSelect .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 33px;
    }

    .FoodIndustry .mblStckyFilter .filterButton {
        width: 24% !important;
        min-width: unset;
        height: 40px;
    }

    .FoodIndustry .mblStckyFilter .filterButton.srchBtn {
        color: var(--primColor);
    }
}

@media (max-width: 992px) {
    .FoodIndustry .searchContainer .lotResults.Grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
    }
    .grid-card .grid-img-wrap img {
        height: 250px;
    }
    .FoodIndustry .grid-card .grid-img-wrap img {
        height: 200px;
    }
}

@media (max-width: 600px) {
    .FoodIndustry .gridListToggle {
        display: none;
    }

    .FoodIndustry .searchContainer .lotResults.Grid {
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }

    .FoodIndustry .liquidationBuyCard .card.grid-card .grid-img-wrap img {
        height: 115px;
    }

    .FoodIndustry .liquidationBuyCard .card.grid-card,
    .FoodIndustry .liquidationAuctions .card.grid-card {
        padding: 5px;
    }

    .FoodIndustry .grid-card .gridItemAct .favoriteCheck {
        width: 30px;
    }

    .FoodIndustry .grid-card .gridItemAct .favoriteCheck label span {
        font-size: 22px;
    }

    .FoodIndustry .grid-card .gridItemAct {
        display: flex;
        align-items: center;
    }

    .FoodIndustry .Grid.liquidationBuyCard .primButton button .MuiButton-label {
        font-size: 12px;
    }

    .FoodIndustry .grid-card .gridItemAct .cartBtn .MuiButton-label .material-icons {
        font-size: 15px;
    }

    .FoodIndustry .grid-card .gridItemAct .cartBtn {
        height: 44px;
        margin: 0 5px;
        width: 41px;
        min-width: unset;
    }

    .FoodIndustry .grid-card .galleryPrice {
        font-size: 14px;
    }

    .FoodIndustry .grid-card .hostedBy {
        min-height: 18px;
        font-size: 13px;
    }

    .FoodIndustry .grid-card .gcTimer {
        margin: 5px 0px;
    }

    .FoodIndustry .mblStckyFilter .filterButton {
        width: max-content !important;
        min-width: unset;
        height: 40px;
    }

    .FoodIndustry
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .MuiFormGroup-root.MuiFormGroup-row,
    .FoodIndustry
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .RadioBox
        .MuiFormControlLabel-root {
        width: 100% !important;
    }

    .FoodIndustry .siteBanner .bannerContent {
        position: absolute;
        top: 80px;
        left: 20px;
    }

    .FoodIndustry .siteBanner .bannerContent h2 {
        font-size: 26px;
        line-height: 37px;
    }
}

@media (max-width: 475px) {
    .toggleRespDrawer.MuiButton-root {
        padding: 3px 10px;
    }
}

@media (max-width: 500px) {
    .FoodIndustry .timerWrapper .timeLabel::before {
        left: 10%;
    }
    .FoodIndustry .timerWrapper .timeLabel::after {
        right: 10%;
    }
    .FoodIndustry .card.grid-card .biddingCnt button .material-icons-outlined {
        display: none;
    }
}
