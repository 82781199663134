.thisflex {
    max-width: 1000px;
    margin: auto;
}
.liquidationTwo .pdtNmClmn {
    white-space: nowrap;
}
.lctnDialogWrapper {
    max-height: 700px;
}
.amountPop b {
    white-space: nowrap;
}
.cancelButton {
    background-color: #ed4b4b !important;
    padding: 6px 15px !important;
    color: #fff !important;
}
.Auctioneer .datePickerLabel {
    position: absolute;
    z-index: 1;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    top: -7px;
    left: 24px;
    background-color: #fff;
    padding: 0px 5px;
}
.Auctioneer .react-datepicker-wrapper input {
    height: 56px;
    margin: 0;
    display: block;
    padding: 10px;
    width: 100%;
}
.Auctioneer .react-datepicker-wrapper {
    position: relative;
}
.firstContent .name .MuiRating-root .MuiRating-decimal span:first-child {
    top: 36px;
    right: 32px;
}
.feedBck span {
    color: var(--primColor);
}
.feedBck:hover span {
    color: #fff;
}
.card-errors {
    color: red;
    font-size: 12px;
}
.dashboard-tabs .table-responsive button {
    white-space: nowrap;
}
input[type='text'],
input[type='number'],
select:focus,
textarea {
    font-size: 16px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
    }
    select,
    textarea,
    input {
        font-size: 16px;
    }
}
