.RadioBox {
    text-align: start;
}

.RadioBox .MuiFormControl-root {
    text-align: start;
}

.RadioBox .MuiFormGroup-root {
    flex-direction: row;
}

.RadioBox legend.MuiFormLabel-root {
    font-size: 1.6em;
    color: var(--secColor);
}

@media (max-width: 600px) {
    .MultiAuction .RadioBox .MuiFormGroup-root {
        gap: 10px;
    }
}
