.Fundraiser .footer-main-standard {
    padding: 0;
    color: var(--footerFontColor);
    background: var(--footerColor);
    font-size: 15px;
    margin-top: 0;
}

.Fundraiser .footer-main-standard .ftrFAB.primButton {
    position: fixed;
    bottom: 30px;
    right: 40px;
    width: 75px;
    height: 75px;
    z-index: 99;
    transition: ease-in-out 0.3s;
}

.Fundraiser .footer-main-standard .ftrFAB.fxdFab.primButton {
    bottom: 300px;
}

.Fundraiser .footer-main-standard .ftrFAB.primButton button {
    border-radius: 100px;
    height: 100%;
}

.Fundraiser .footer-main-standard a {
    color: var(--footerFontColor);
}

.Fundraiser .footer-copy-right {
    padding: 10px 30px;
    background: var(--footerColor);
}

.Fundraiser .footer-copy-right p {
    margin: 0;
}

.Fundraiser a.cvc-link {
    font-size: 15px;
}

.Fundraiser .footer-main-standard-title {
    margin-bottom: 15px;
    color: var(--primColor);
    font-size: 20px;
    position: relative;
    padding-bottom: 15px;
}

.Fundraiser .footer-main-standard-newsletter {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
}

.Fundraiser .newsletter-wrapper {
    position: relative;
}
.Fundraiser .footer-main-standard-newsletter-input {
    width: 100%;
    border: none;
    border-radius: 45px;
    padding: 13px 26px;
    color: #4a4031;
    font-size: 16px;
    border: 1px solid #ddd;
}

.Fundraiser .footer-main-standard-newsletter-submit {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    height: 52px;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    background: var(--primColor);
    color: #ffffff;
    border: none;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    padding: 0px 20px;
}

.Fundraiser .ftrDtlCntnr .footerMisc {
    justify-content: flex-end;
}

.ftrDtlCntnr .footerMisc {
    max-width: initial !important;
}

.footer-main-standard-newsletter-submit {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
    line-height: 16px;
}

.footer-main-standard-social-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-left: -7px;
}

.footer-main-standard-social-list > li {
    display: inline-block;
    margin: 5px 6px;
}

.footer-main-standard-social-list > li > a {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    color: var(--primColor);
    opacity: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.footer-main-standard-social-list > li > a > svg {
    fill: #fff;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
}

.Fundraiser .ftrDtlCntnr {
    text-align: center;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    padding-top: 35px;
}

.footer-main-standard-social-list {
    margin: 0;
    width: 100%;
    text-align: right;
}

.Fundraiser .ftrDtlCntnr .footerLogo {
    width: 100px;
    height: 60%;
    object-fit: contain;
    border-radius: 10px;
    margin: auto auto 15px;
}

.Fundraiser .footLogoCnt {
    text-align: left;
}

.ftrDtlCntnr .cvc-link {
    color: var(--footerFontColor);
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
}

.ftrDtlCntnr .cvc-link:hover {
    text-decoration: none;
}

.ftrDtlCntnr .cvc-link .material-icons {
    padding-right: 10px;
    color: var(--footerFontColor);
}

.ftrDtlCntnr .fa,
.ftrDtlCntnr .fab,
.ftrDtlCntnr .fal,
.ftrDtlCntnr .far,
.ftrDtlCntnr .fas {
    font-size: inherit;
    margin: 0 auto;
}

.footerLinks {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    display: flex;
    justify-content: flex-end;
}

.Fundraiser .footerLinks li:not(:last-child) {
    margin-right: 45px;
}

.Fundraiser .footerLinks li a {
    font-size: 15px;
    font-weight: 500;
}

.Fundraiser footer .copyright {
    font-size: 12px;
    color: white;
}

.Fundraiser .footerLinks li a {
    color: inherit;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.Fundraiser .footerLinks li a:hover {
    color: var(--primColor);
}

.Fundraiser .footerLinks li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: var(--primColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.Fundraiser .footerLinks li a:hover:after {
    width: 100%;
    left: 0;
}

.Fundraiser .modal-body.cm-body {
    color: black;
}

@media (max-width: 991px) {
    .footerLinks {
        display: none;
    }

    .Fundraiser .footer-main-standard .ftrFAB.primButton {
        position: fixed;
        bottom: 40px;
        right: 40px;
        width: 75px;
        height: 75px;
        z-index: 99;
    }
}

@media (max-width: 600px) {
    .ftrDtlCntnr .footerLogo {
        width: 100px;
    }
    .ftrDtlCntnr {
        margin-bottom: 20px !important;
    }
    .Fundraiser .ftrDtlCntnr {
        justify-content: center;
        flex-wrap: wrap;
    }
}
