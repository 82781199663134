.postProductContainer {
    max-width: 930px;
    width: 100%;
    margin: 60px auto;
    padding: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
}

.ppTitle {
    font-size: 28px;
    font-weight: 700;
    padding-top: 35px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    color: #2e2e2e;
}

.postProductContainer .MuiTabs-fixed {
    text-align: center;
}

.postProductContainer .MuiTabs-fixed .MuiTabs-flexContainer {
    border-bottom: none;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root {
    position: relative;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root.Mui-selected {
    background: #fff;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root.Mui-selected::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 50%;
    height: 3px;
    background: var(--primColor);
}

.postProductContainer .MuiTab-wrapper {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
}

.postProductContainer .MuiTabs-root {
    margin-bottom: 45px;
}

.postProductContainer .pp-btns-wrap .primButton {
    margin-bottom: 10px;
}

.ArtAuction .postProductContainer .mutlImgUpld .dropzone {
    padding: 20px;
    min-height: 130px;
}

.ArtAuction .postProductContainer .mutlImgUpld .dropzone h6 p {
    font-size: 14px;
}

.ArtAuction .smplImgGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
}

.ArtAuction .smplImgCrd img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
}

.ArtAuction .imgInfoCntnr {
    padding: 15px;
    border: 1px dashed #c9c5d8;
    border-radius: 5px;
    margin-bottom: 35px;
}

.ArtAuction .imgInfoCntnr h5 {
    font-size: 17px;
    margin-bottom: 10px;
}

.ArtAuction .imgInfoCntnr p {
    font-size: 14px;
}

.ArtAuction .imgInfoCntnr label {
    font-size: 14px;
}
