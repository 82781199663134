.Automobile .home {
    background: #fff;
}
.Automobile .siteBanner {
    width: 100%;
    height: 650px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
    border-radius: 0;
}

.siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.search-wrapper {
    margin-bottom: 30px;
}

.Automobile .home .auctionListCnt {
    position: relative;
    margin-top: 0px;
    padding-top: 0;
    background: #fff;
    border-radius: 0px;
}

.Automobile .home .auctionListCnt .auctionListTitle {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #474747;
    text-align: left;
    text-transform: initial;
    margin: 45px 0;
    position: relative;
    text-align: center;
}

.Automobile .home .auctionListCnt .auctionListTitle::after {
    position: absolute;
    display: block;
    content: '';
    top: auto;
    bottom: -10px;
    right: 0;
    left: 0;
    width: 105px;
    background: var(--primColor);
    height: 2px;
    margin: auto;
}

.auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.Automobile .home .siteBanner .bannerContent {
    position: absolute;
    top: -35%;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Automobile .home .siteBanner .bannerContent h2 {
    font-size: 56px;
    margin-bottom: 0;
    color: var(--bannerTextColor);
    z-index: 999;
    font-weight: 600;
    letter-spacing: 0;
    max-width: -moz-fit-content;
    max-width: fit-content;
    width: 100%;
    line-height: 50px;
    max-width: 70%;
}

.Automobile .home .siteBanner .bannerContent p {
    font-size: 15px;
    margin-bottom: 45px;
    color: #333;
    width: 100%;
    max-width: 600px;
}

.Automobile .home .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.homeActiveAuction {
    background: #efefef;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.homeActiveAuction h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--primColor);
}

.homeActiveAuction h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2f2f2f;
}

.homeActiveAuction .primButton button {
    width: 180px;
    border-radius: 25px;
}

.Automobile .homeCnt {
    display: flex;
    justify-content: flex-start;
}

.Automobile .homeCnt .homeLt {
    position: sticky;
    top: 10px;
    max-width: 255px;
    width: 100%;
    margin-right: 20px;
    margin-top: 0px;
}

.Automobile .homeCnt .homeRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.Automobile .homeLt h3 {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
}

.Automobile .homeLt h3::after {
    position: absolute;
    content: '';
    bottom: -10px;
    left: 0;
    height: 2px;
    width: 80px;
    background: var(--primColor);
}

.Automobile .homeLt .homeCatg {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
}

.Automobile .homeLt .homeCatg .homeCatgInner .MuiCheckbox-root {
    display: none;
}

.Automobile .homeLt .homeCatg .homeCatgInner > div {
    width: 100%;
}

.Automobile .homeLt .homeCatg .homeCatgInner > div > .customCheckbox,
.Automobile .homeLt .homeCatg label,
.Automobile .homeLt .homeCatg .MuiTypography-root {
    width: 100%;
}

.Automobile .homeLt .homeCatg .homeCatgInner .catCount {
    height: 25px;
    font-size: 14px;
    background: #e9e9e9;
    color: #2e2e2e;
    padding: 0px 15px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.Automobile .homeLt .homeCatg .homeCatgInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Automobile .homeLt .homeCatg .homeCatgInner label {
    margin: 0;
}

.Automobile .homeRt .MuiTabs-fixed {
    text-align: center;
}

.Automobile .homeRt .MuiTabs-fixed .MuiTabs-flexContainer {
    border-bottom: none;
}

.Automobile .homeRt .MuiTabs-fixed .MuiTab-root {
    position: relative;
}

.Automobile .homeRt .MuiTabs-fixed .MuiTab-root.Mui-selected {
    background: #fff;
}

.Automobile .homeRt .MuiTabs-fixed .MuiTab-root.Mui-selected::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 50%;
    height: 3px;
    background: var(--primColor);
}

.Automobile .homeRt .MuiTab-wrapper {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
}

.homeCnt .toggleRespDrawer.MuiButton-root {
    display: none;
}

.Automobile .home .lotResults.Grid .aucGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.Automobile .lotResults.Grid .viewAll {
    margin-top: 30px;
}

.Automobile .home .filterPanel .MuiAccordionDetails-root {
    padding: 0;
    margin-top: 10px;
}

.Automobile .home .filterPanel .MuiAccordionSummary-root {
    padding: 0px 10px;
}

.Automobile .home .hotAuctions {
    background: #0c0c0c;
    padding-top: 60px;
    padding-bottom: 60px;
}

.Automobile .home .hotAuctions .auctionTitle {
    font-size: 120px;
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin: 0;
}

.Automobile .home .hotAuctions .auctionSubTitle {
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    margin: 20px 0 60px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    letter-spacing: 4px;
}

/* .Automobile .hotAuctions .lotResults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 25px;
} */

.Automobile .hotAuctions .grid-card .card-body,
.Automobile .hotAuctions .grid-card .card-body .hostedBy,
.Automobile .hotAuctions .grid-card .card-body .list-title,
.Automobile .hotAuctions .grid-card .card-body .list-title,
.Automobile .hotAuctions .grid-card .card-body .timer-wrapper,
.Automobile .hotAuctions .grid-card .timerCnt {
    color: #d8d8d8;
}

.Automobile .hotAuctions .grid-card .card-body .list-title {
    margin-top: 10px;
}

.Automobile .hotAuctions .autoGridInfo h6 {
    margin: 0;
    font-size: 16px;
}

.Automobile .hotAuctions .autoGridInfo .aucDivider {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #d8d8d8;
    margin: 0 15px;
}

.Automobile .hotAuctions .lotResults .card-body > h5 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 500;
    margin: 25px 0 10px;
    line-height: 24px;
    overflow: hidden;
    height: auto;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.Automobile .hotAuctions .grid-card .grid-img-wrap img {
    height: 300px;
}

.Automobile .grid-card .timerCnt {
    font-size: 16px;
    font-weight: normal;
}

.Automobile .grid-card .timerCnt span {
    padding-right: 5px;
}

.Automobile .home .activeAuctions {
    background: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
}

.Automobile .home .activeAuctions .auctionTitle {
    font-size: 120px;
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin: 0;
}

.Automobile .home .activeAuctions .auctionSubTitle {
    font-size: 16px;
    font-weight: 300;
    color: #000;
    margin: 20px 0 60px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    letter-spacing: 4px;
}

.Automobile .activeAuctions .lotResults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.Automobile .hotAuctions .lotResults .slick-slider .slick-slide {
    margin: 0 12px;
}

/* Responsive Code */

@media (min-width: 992px) {
    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 991px) {
    .Automobile .homeCnt .homeRt {
        max-width: 100%;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
        position: absolute;
        top: 0px;
        right: auto;
        left: 60px;
    }
    .Automobile .homeCnt .homeLt {
        display: none;
    }
}

@media (max-width: 767px) {
    .siteBanner {
        margin-bottom: 0;
    }

    .Automobile .home .auctionListCnt .auctionListTitle {
        margin: 20px auto;
    }

    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .siteBanner {
        height: 300px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }
}

@media (max-width: 600px) {
    .Automobile .home .auctionListCnt .auctionListTitle {
        font-size: 22px;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        left: 15px;
    }
    .Automobile .home .siteBanner .bannerContent h2 {
        font-size: 24px;
        margin-bottom: 10px;
        max-width: 500px;
        width: 100%;
        line-height: 35px;
    }
    .Automobile .home .siteBanner .bannerContent {
        position: absolute;
        top: 70px;
        left: 25px;
    }
}

@media (max-width: 475px) {
    .Automobile .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
    .homeCnt .toggleRespDrawer.MuiButton-root {
        right: auto;
        left: 15px;
        padding: 3px 10px;
    }
}

@media (max-width: 450px) {
    .siteBanner {
        height: 250px;
        top: 40%;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }
    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }
}
