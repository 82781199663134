.CarAuction .productViewStandalone {
    padding-top: 44px;
}

.CarAuction .productViewStandalone .mpTop {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
    position: sticky;
    top: 64px;
    z-index: 6;
    background: white;
}

.CarAuction .productViewStandalone .productStatus {
    position: fixed;
    top: 104px;
    width: 100vw;
    left: 0;
    z-index: 99;
}

.CarAuction .productViewStandalone .productStatus h4 {
    transform: unset;
    border-radius: 0px;
    padding: 10px 0;
    height: max-content;
}

.CarAuction .productViewStandalone .plTitle span {
    display: block;
}

.CarAuction .productViewStandalone .plTitle span.pdtTtle {
    font-size: 28px;
    color: black;
    margin-bottom: 8px;
    font-weight: 600;
}

.CarAuction .productViewStandalone .multiSellerTimerView h6 {
    color: var(--primColor);
    font-weight: 600;
    min-width: 25px;
}

.CarAuction .productViewStandalone .multiSellerTimerView h6:not(:last-child) {
    margin-right: 20px;
}

.CarAuction .productViewStandalone .multiSellerTimerView h6 span {
    color: gray;
}

.CarAuction .productViewStandalone .plTitle span:last-child {
    font-size: 25px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 15px;
}

.CarAuction .vdoGridWrpr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.CarAuction .vdoGridWrpr > div {
    width: 100% !important;
    height: 200px !important;
}

.CarAuction .productViewStandalone .image-gallery {
    width: 100%;
}

.CarAuction .productViewStandalone .imgGallery {
    height: 700px;
    margin-bottom: 40px;
}

.CarAuction
    .productViewStandalone
    .image-gallery-content
    .image-gallery-slide
    .image-gallery-image {
    height: 700px;
    min-height: unset;
    max-height: unset;
    object-fit: contain;
    background: whitesmoke;
}

.CarAuction .docInfo .MuiAccordionSummary-content .accTitle {
    display: flex;
    align-items: center;
}

.CarAuction .docInfo .MuiAccordionSummary-content .accTitle .material-icons {
    color: gray;
    margin-right: 6px;
}

.CarAuction .docInfo .MuiPaper-root {
    border: 1px solid rgb(207, 207, 207);
    box-shadow: none;
    margin-bottom: 0px;
}

.CarAuction .docInfo .MuiPaper-root.MuiAccordion-root:before {
    content: none;
}

.CarAuction .docInfo .docView {
    padding: 10px;
    border: 1px solid rgb(188, 188, 188);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CarAuction .docInfo .docView:hover {
    text-decoration: none;
    box-shadow: 0 0 10px #00000016;
}

.CarAuction .docInfo .docView .material-icons {
    font-size: 50px;
    color: gray;
}

.CarAuction .docInfo .dcmntVw {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 10px;
    width: 100%;
}

.productViewStandalone .image-gallery-thumbnails-container {
    text-align: start;
}

/* .productViewStandalone .image-gallery-thumbnails {
    width: 75px;
} */

.productViewStandalone .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.CarAuction .productViewStandalone .image-gallery-slide-wrapper.left,
.CarAuction .productViewStandalone .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 210px);
}

.CarAuction .productViewStandalone .image-gallery-thumbnails-wrapper.left,
.CarAuction .productViewStandalone .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 200px;
}

.CarAuction .productViewStandalone .image-gallery-thumbnail {
    width: 100%;
}

.productViewStandalone .image-gallery-icon:hover {
    color: var(--primColor);
}

/* .productViewStandalone .image-gallery-thumbnail {
    border: 2px solid transparent;
    width: 75px;
} */

.productViewStandalone .image-gallery-thumbnail.active,
.productViewStandalone .image-gallery-thumbnail:hover,
.productViewStandalone .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 0px;
}

.CarAuction .productViewStandalone .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: unset;
    object-fit: cover;
    border-radius: 0px;
}

.productViewStandalone .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 350px;
    min-height: 300px;
    cursor: initial;
}

.productViewStandalone .image-gallery-content .image-gallery-slide > div {
    background: #efefef;
}

.productViewStandalone .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}

.productViewStandalone .productInfo h5 {
    font-size: 18px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 25px;
}

.productViewStandalone .productInfo h5 span {
    font-size: 15px;
    font-weight: 500;
    width: 200px;
    display: inline-block;
}

.productViewStandalone .pvsCnt {
    display: grid;
    grid-template-columns: 520px auto;
    gap: 20px;
}

.productViewStandalone .sl-pbids.slidView {
    width: auto;
    margin-top: 0;
    margin-left: auto;
}

.productViewStandalone .biddingCnt .customInput {
    margin-bottom: 15px;
}

.productViewStandalone form:last-child .biddingCnt .customInput {
    margin-bottom: 0px;
}

.productViewStandalone .mssBidItem {
    display: flex;
}

.productViewStandalone .mssBidHistory {
    float: right;
    height: 45px;
    width: auto;
    text-transform: initial;
    color: var(--primColor);
}

.productViewStandalone .mssBidHistory .material-icons {
    margin-right: 10px;
}

@media (max-width: 1366px) {
    .CarAuction .productViewStandalone .sl-pbids.slidView {
        margin-left: 0;
    }

    .CarAuction .productViewStandalone .mssBidItem .productInfo {
        margin-right: 10px;
    }

    .CarAuction .productViewStandalone .mssBidItem {
        flex-wrap: wrap;
    }

    .CarAuction .productViewStandalone .pvsCnt {
        display: grid;
        grid-template-columns: 400px auto;
        gap: 20px;
    }
}

@media (max-width: 1200px) {
    .CarAuction .vehicleProduct .image-gallery-thumbnails-wrapper {
        display: none;
    }
}

@media (max-width: 990px) {
    .CarAuction .productViewStandalone .pvsCnt {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media (max-width: 960px) {
    .CarAuction .productViewStandalone .mpTop .plTitle {
        width: 100%;
        text-align: center;
    }

    .CarAuction .productViewStandalone .mpTop span {
        margin: auto;
        width: 100%;
    }

    .CarAuction .vehicleProduct .lotChip {
        margin-left: auto;
        margin-right: auto;
    }

    .CarAuction .productViewStandalone .timerWrapper .timeLabel::after {
        width: 40%;
        right: 0;
        left: unset !important;
    }

    .CarAuction .productViewStandalone .timerWrapper .timeLabel::before {
        width: 40%;
        left: 0;
        right: unset !important;
    }

    .CarAuction .productViewStandalone .MuiTabScrollButton-root .MuiSvgIcon-root {
        color: var(--primColor);
    }
}

@media (max-width: 700px) {
    .CarAuction .vdoGridWrpr,
    .vehicleProduct .bdngWrapper .dtInfo {
        grid-template-columns: 1fr;
    }

    .vehicleProduct .bdngWrapper .dtInfo h5 {
        font-size: 13px;
    }

    .CarAuction .productViewStandalone .mpTop {
        flex-wrap: wrap;
    }

    .CarAuction .productViewStandalone .mpTop > div {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .productViewStandalone .image-gallery {
        width: unset !important;
    }

    .CarAuction .productViewStandalone .productInfo h5 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 14px;
        margin: 15px 0;
        line-height: 23px;
    }

    .CarAuction .productViewStandalone .mssBidItem {
        flex-wrap: wrap;
    }

    .CarAuction .productViewStandalone .biddingCnt .primButton,
    .CarAuction .productViewStandalone .biddingCnt .secButton {
        width: 100%;
        max-width: 145px;
    }

    .CarAuction .productViewStandalone .pvsBottom .tabBody {
        padding: 10px;
    }
}
