.maintainPage {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 60px;
}

.maintenancePageWrpr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.maintenancePageWrpr img {
    max-width: 850px;
}

.maintenancePageWrpr .infoContainer {
    margin-right: 30px;
}

.maintenancePageWrpr .infoContainer h2 {
    font-size: 60px;
    font-weight: 400;
}

.maintenancePageWrpr .infoContainer p {
    font-size: 29px;
    margin-top: 16px;
    line-height: 38px;
    color: gray;
}

.maintenancePageWrpr .logoContnr img {
    width: 240px;
    height: 60px;
    object-fit: scale-down;
}

@media (max-width: 1400px) {
    .maintenancePageWrpr img {
        max-width: 100%;
        height: 90vh;
    }

    .maintenancePageWrpr .infoContainer h2 {
        font-size: 45px;
    }

    .maintenancePageWrpr .infoContainer p {
        font-size: 22px;
    }
}

@media (max-width: 800px) {
    .maintenancePageWrpr {
        flex-wrap: wrap-reverse;
        text-align: center;
    }

    .maintenancePageWrpr img {
        max-width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        max-height: 300px;
    }

    .maintenancePageWrpr .infoContainer {
        width: 100%;
        margin-right: 0;
    }
}
