.productViewStandalone .mpTop {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
}

.productViewStandalone .plTitle span {
    display: block;
}

.productViewStandalone .plTitle span:first-child {
    font-size: 16px;
    color: #747474;
    margin-bottom: 8px;
}

.productViewStandalone .plTitle span:last-child {
    font-size: 25px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 15px;
}
.Auctioneer .productViewStandalone .image-gallery {
    width: 400px;
}
.productViewStandalone .image-gallery {
    width: 520px;
}

.productViewStandalone .image-gallery-thumbnails-container {
    text-align: start;
}

/* .productViewStandalone .image-gallery-thumbnails {
    width: 75px;
} */

.productViewStandalone .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.productViewStandalone .image-gallery-slide-wrapper.left {
    width: 100%;
}

.productViewStandalone .image-gallery-icon:hover {
    color: var(--primColor);
}

.productViewStandalone .image-gallery-thumbnail {
    border: 2px solid transparent;
    width: 75px;
}

.productViewStandalone .image-gallery-thumbnail.active,
.productViewStandalone .image-gallery-thumbnail:hover,
.productViewStandalone .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 0px;
}

.productViewStandalone .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 60px;
    object-fit: cover;
    border-radius: 0px;
}

.productViewStandalone .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 350px;
    min-height: 300px;
    cursor: initial;
}

.productViewStandalone .image-gallery-content .image-gallery-slide > div {
    background: #efefef;
}

.productViewStandalone .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}
.Auctioneer .productViewStandalone .productInfo h5 {
    margin-bottom: 10px !important;
}

.productViewStandalone .productInfo h5 {
    font-size: 18px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 25px;
}
.Auctioneer .productViewStandalone .productInfo h5 span {
    min-width: 180px;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    width: unset;
}
.Auctioneer .prodAuctioneer .MuiAccordion-root .MuiAccordionSummary-root {
    background-color: transparent;
}
.Auctioneer .prodAuctioneer .MuiAccordion-root .table-responsive {
    background-color: #fff;
}
.Auctioneer .filterPanel::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
}
.Auctioneer .searchLt .filterPanel {
    position: sticky;
    top: 15px;
    height: calc(100vh - 115px);
    overflow-y: scroll;
}
.Auctioneer .filterPanel::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.Auctioneer .filterPanel:hover::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}
.Auctioneer .co-breadcrumb a {
    color: grey;
    font-size: 17px;
}
.Auctioneer .co-breadcrumb a svg {
    margin-right: 10px;
}
.Auctioneer .prodAuctioneer .MuiAccordion-root {
    background-color: #ebebeb;
    color: #000;
    margin-bottom: 15px;
    border-color: transparent;
}
.Auctioneer .pvsRight .mssBidItem {
    display: none;
}
.productViewStandalone .productInfo h5 span {
    font-size: 15px;
    font-weight: 500;
    width: 200px;
    display: inline-block;
}

.productViewStandalone .pvsCnt {
    display: grid;
    grid-template-columns: 520px auto;
    gap: 20px;
}
.Auctioneer .productViewStandalone .pvsCnt {
    grid-template-columns: 400px auto;
    gap: 50px;
}
.productViewStandalone .sl-pbids.slidView {
    width: auto;
    margin-top: 0;
    margin-left: auto;
}

.productViewStandalone .biddingCnt .customInput {
    margin-bottom: 15px;
}

.productViewStandalone form:last-child .biddingCnt .customInput {
    margin-bottom: 0px;
}

.productViewStandalone .mssBidItem {
    display: flex;
}

.productViewStandalone .mssBidHistory {
    float: right;
    height: 45px;
    width: auto;
    text-transform: initial;
    color: var(--primColor);
}

.productViewStandalone .mssBidHistory .material-icons {
    margin-right: 10px;
}

@media (max-width: 990px) {
    .Auctioneer .productViewStandalone .pvsCnt {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media (max-width: 600px) {
    .productViewStandalone .image-gallery {
        width: unset !important;
    }

    .Auctioneer .productViewStandalone .productInfo h5 {
        display: flex;
        flex-wrap: wrap;
    }
}
