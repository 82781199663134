.bubble-container {
    background: #b9d1f8;
}
.bubble-container .bubbles {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 0;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
}
.bubble-container .bubbles .bubble {
    position: absolute;
    bottom: -100px;
    width: 40px;
    height: 40px;
    background: #f1f1f1;
    border-radius: 50%;
    opacity: 0.5;
    animation: rise 10s infinite ease-in;
}
.bubble-container .bubbles .bubble:nth-child(1) {
    width: 40px;
    height: 40px;
    left: 10%;
    animation-duration: 8s;
}
.bubble-container .bubbles .bubble:nth-child(2) {
    width: 20px;
    height: 20px;
    left: 20%;
    animation-duration: 5s;
    animation-delay: 1s;
}
.bubble-container .bubbles .bubble:nth-child(3) {
    width: 50px;
    height: 50px;
    left: 35%;
    animation-duration: 7s;
    animation-delay: 2s;
}
.bubble-container .bubbles .bubble:nth-child(4) {
    width: 80px;
    height: 80px;
    left: 50%;
    animation-duration: 11s;
    animation-delay: 0s;
}
.bubble-container .bubbles .bubble:nth-child(5) {
    width: 35px;
    height: 35px;
    left: 55%;
    animation-duration: 6s;
    animation-delay: 1s;
}
.bubble-container .bubbles .bubble:nth-child(6) {
    width: 45px;
    height: 45px;
    left: 65%;
    animation-duration: 8s;
    animation-delay: 3s;
}
.bubble-container .bubbles .bubble:nth-child(7) {
    width: 90px;
    height: 90px;
    left: 70%;
    animation-duration: 12s;
    animation-delay: 2s;
}
.bubble-container .bubbles .bubble:nth-child(8) {
    width: 25px;
    height: 25px;
    left: 80%;
    animation-duration: 6s;
    animation-delay: 2s;
}
.bubble-container .bubbles .bubble:nth-child(9) {
    width: 15px;
    height: 15px;
    left: 70%;
    animation-duration: 5s;
    animation-delay: 1s;
}
.bubble-container .bubbles .bubble:nth-child(10) {
    width: 90px;
    height: 90px;
    left: 25%;
    animation-duration: 10s;
    animation-delay: 4s;
}
.bubble-container .bubbles .bubble:nth-child(11) {
    width: 40px;
    height: 40px;
    left: 45%;
    animation-duration: 10s;
    animation-delay: 4s;
}
.bubble-container .bubbles .bubble:nth-child(12) {
    width: 20px;
    height: 20px;
    left: 40%;
    animation-duration: 5s;
    animation-delay: 1s;
}
.bubble-container .bubbles .bubble:nth-child(13) {
    width: 50px;
    height: 50px;
    left: 65%;
    animation-duration: 7s;
    animation-delay: 2s;
}
.bubble-container .bubbles .bubble:nth-child(14) {
    width: 80px;
    height: 80px;
    left: 90%;
    animation-duration: 11s;
    animation-delay: 0s;
}
.bubble-container .bubbles .bubble:nth-child(15) {
    width: 35px;
    height: 35px;
    left: 85%;
    animation-duration: 6s;
    animation-delay: 1s;
}
.bubble-container .bubbles .bubble:nth-child(16) {
    width: 45px;
    height: 45px;
    left: 75%;
    animation-duration: 8s;
    animation-delay: 3s;
}
.bubble-container .bubbles .bubble:nth-child(17) {
    width: 90px;
    height: 90px;
    left: 10%;
    animation-duration: 12s;
    animation-delay: 2s;
}
.bubble-container .bubbles .bubble:nth-child(18) {
    width: 25px;
    height: 25px;
    left: 20%;
    animation-duration: 6s;
    animation-delay: 2s;
}
.bubble-container .bubbles .bubble:nth-child(19) {
    width: 15px;
    height: 15px;
    left: 70%;
    animation-duration: 5s;
    animation-delay: 1s;
}
.bubble-container .bubbles .bubble:nth-child(20) {
    width: 90px;
    height: 90px;
    left: 15%;
    animation-duration: 10s;
    animation-delay: 4s;
}
@keyframes rise {
    0% {
        bottom: -100px;
        transform: translateX(0);
    }
    50% {
        transform: translate(100px);
    }
    100% {
        bottom: 1080px;
        transform: translateX(-200px);
    }
}
