.gameRoom {
    text-align: center;
    margin: 50px auto;
}
.gameRoom h2 {
    margin-bottom: 30px;
}
.gameRoom h2,
.gameRoom h4 {
    text-transform: uppercase;
    font-weight: 600;
}
.gameRoom h4 {
    color: #1d52dd;
    margin-bottom: 25px;
}
.journey {
    margin-top: 40px;
}
.journey h5 {
    margin-bottom: 10px;
}
.journey p {
    margin: 30px auto;
}
