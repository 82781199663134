.pv-media .media-left {
    max-width: 450px;
    width: auto;
    margin-right: 40px;
}

.pv-media .image-gallery-content .image-gallery-slide .image-gallery-image {
    /* max-height: 350px; */
    width: 420px;
    max-width: initial;
    height: 350px;
    object-fit: contain;
    border-radius: 10px;
}

.pv-media .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

.tmm {
    background: #ba122b;
    color: #fff;
    padding: 3px 10px;
    border-radius: 4px;
    display: block;
    width: max-content;
    margin: 0 auto;
}
.pv-media .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 100px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
}

.pv-media .image-gallery-thumbnail {
    width: 108px;
}

.pv-media .image-gallery-thumbnail.active,
.pv-media .image-gallery-thumbnail:hover,
.pv-media .image-gallery-thumbnail:focus {
    border: 4px solid var(--primColor);
    border-radius: 8px;
}
.pv-media .image-gallery-thumbnails-container {
    text-align: left;
}
.pv-title {
    font-weight: 600;
    font-size: 22px;
    text-align: left;
    color: #000;
    margin-bottom: 15px;
}

.pv-con {
    font-size: 16px;
    text-align: left;
    color: #000;
    margin-bottom: 15px;
}

.pv-con.aucDesc {
    margin-bottom: 5px;
}

.pv-con1 {
    font-size: 18px;
    color: #000;
    margin: 10px 0;
    line-height: 30px;
    font-weight: 500;
    min-width: 200px;
}

.MultiSeller .pv-con.aucDesc .display-text-group {
    font-size: 15px;
    line-height: 24px;
}

.pv-con span {
    font-weight: 600;
}

.mt-100 {
    margin-top: 90px;
}

.pbid-igroup .primary-btn {
    width: 200px;
    height: 50px;
    margin-left: 15px;
    min-width: 200px;
}

.pbid-igroup .bda-input input {
    padding-right: 40px;
}

.pbid-igroup .bda-input {
    position: relative;
    max-width: 250px;
    margin-left: 25px;
}

.ig-addon {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 34px;
    height: 48px;
    border-radius: 0px 5px 5px 0px;
    background: #ebebff;
    padding: 8px 5px;
    text-align: center;
    font-size: 20px;
}

a.read-more {
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    color: #afafaf !important;
    margin-left: 5px;
}

.tb-link {
    color: var(--primColor) !important;
    cursor: pointer !important;
}

.time-left {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #000;
    margin: 15px 0 30px;
}

.time-left img {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
}

.bn-con {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #000;
    margin-bottom: 15px;
}

.bn-con span {
    color: var(--primColor);
}

.bnow-form {
    max-width: 275px;
    margin: 35px auto;
    display: block;
}

.bnow-form .primary-btn {
    width: 100%;
}

.cancel-bid {
    font-size: 18px;
    text-align: left;
    color: #afafaf !important;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    text-transform: uppercase;
}

.won-msg,
.outbid-msg,
.hb-msg {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 18px;
    text-align: center;
    background: #ffecd9;
    color: #ff8801;
    padding: 10px 15px;
}

.outbid-msg {
    background: #ffe1e1;
    color: #df0d0d;
}

.hb-msg {
    background: #e0fbe2;
    color: #00910e;
}

.lv-timer-new {
    margin: 10px auto;
    width: 45px;
    height: 45px;
    object-fit: contain;
}

.lv-timer-new img {
    height: 75px;
}

.sbid-price {
    color: var(--primColor);
    font-size: 24px;
    font-weight: 600;
}
.lineItemBidTable .table thead th {
    font-size: 13px;
}

.lineItemBidTable .table td,
.lineItemBidTable .table th {
    font-size: 14px;
    vertical-align: top;
}
.lineItemBidTable .sl-pbids {
    width: auto;
}

.lineItemBidTable .sl-pbids .input-group {
    width: 215px;
}

.lineItemBidTable .sl-pbids .pink-btn.pbtn-sm {
    margin-left: 10px;
    font-size: 14px;
    width: 130px;
    min-width: 100px !important;
}
.descBox {
    position: relative;
}

.descBox .descContent {
    background: #e2e2e2;
    color: #444;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    width: max-content;
    max-width: 400px;
    height: auto;
    position: absolute;
    top: 10px;
    left: 80px;
    visibility: hidden;
    text-align: left;
    z-index: 1000;
}
.descBox .descLabel {
    /* cursor: pointer;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
    cursor: pointer;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}
.descBox:hover .descContent {
    visibility: visible;
}
.prodInfoSlider .slidImg {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.prodInfoSlider .favoriteCheck {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: #dbdbdb;
}

.prodInfoSlider .favoriteCheck label {
    color: var(--primColor);
}

.prodInfoSlider .plTitle {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    color: #323749;
}
.prodInfoSlider .pvTitleContainer {
    margin-top: 25px;
}
.sl-pbids.slidView {
    width: 450px;
    margin-top: 15px;
}
.sl-pbids.slidView .input-group-append,
.sl-pbids.slidView .form-control,
.sl-pbids.slidView button {
    height: 50px;
}
.sl-pbids.slidView button {
    width: 200px;
    margin: initial;
}
.sl-pbids.slidView .input-group {
    margin-right: 15px;
    width: 200px;
}
.sl-pbids.slidView .input-group span {
    padding: 12px;
}
.sl-pbids.slidView .input-group p.entAm {
    margin-top: 10px;
}
.prodInfoSlider .pv-con span,
.prodInfoSlider .svInfo span {
    font-weight: 500;
    font-size: 16px;
}

.prodInfoSlider .svInfo {
    font-size: 16px;
    font-weight: 400;
}

.prodInfoSlider .pv-con {
    margin-bottom: 5px;
    margin-top: 5px;
}

.prodInfoSlider .displayed-text {
    margin-bottom: 0;
}
.prodInfoSlider .image-gallery-thumbnails .image-gallery-thumbnails-container {
    text-align: left;
}
.prodInfoSlider .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px;
    object-fit: cover;
}

.prodInfoSlider .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 400px;
    object-fit: contain;
}

.prodInfoSlider .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

.sublots-table td.sl-img-wrap img {
    cursor: pointer;
    width: 275px;
    height: 275px;
    object-fit: contain;
}

.subLotLine .slAct {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    max-width: 350px;
}

.subLotLine .slAct .biddingCnt .customInput {
    margin-bottom: 15px;
}

.subLotLine .slAct .biddingCnt .customInput fieldset,
.slidView .biddingCnt .customInput fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.subLotLine .slAct .biddingCnt button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 160px;
}

.slidView .biddingCnt button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.timer-wrapper .li-timer {
    font-size: 16px;
    font-weight: 700;
}

.ndCTA {
    display: inline-block;
    position: relative;
    text-align: center;
}

.ndCTA .primButton button,
.ndCTA .secButton button {
    width: 200px;
}

.ndCTA .arrow {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}
.ndCTA .arrow span {
    display: block;
    width: 25px;
    height: 25px;
    border-bottom: 5px solid var(--primColor);
    border-right: 5px solid var(--primColor);
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
.ndCTA .arrow span:nth-child(2) {
    animation-delay: -0.2s;
}
.ndCTA .arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

.pvMiscActions {
    display: flex;
    background: #e8e8e8;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.pvMiscActions button:not(:first-child) {
    margin-left: 15px;
}

.pvMiscActions a,
.prodInfoSlider .favoriteCheck {
    border-radius: 0 !important;
    background: transparent !important;
    margin-left: 0;
}

.pvMiscActions a:not(:only-child) {
    border-right: 1px solid #d7d7d7;
}

.msSublots .lotResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.msSublots .lotResults.Grid .biddingCnt .customInput {
    margin-right: 10px;
}

/* HeavyEquipment css */

.Automobile .css-nzyzm1-SkeletonTheme .react-loading-skeleton {
    width: 100% !important;
    height: 203px !important;
}

.Automobile .css-nzyzm1-SkeletonTheme .multisellerListCard .react-loading-skeleton {
    width: 200px !important;
    height: 175px !important;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

@media (max-width: 1200px) {
    .Liquidation .product-media-wrapper .media-body {
        width: 100%;
    }

    .Liquidation .product-media-wrapper .media-left {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
    }
    .ndCTA .arrow {
        display: none;
    }
    .lineItemBidTable .table .biddingCnt {
        flex-wrap: wrap;
    }

    .lineItemBidTable .slAct .biddingCnt > div {
        width: 100%;
    }

    .lineItemBidTable .slAct .biddingCnt .customInput {
        margin-bottom: 10px;
        width: 100%;
    }
    .lineItemBidTable .slAct .biddingCnt .primButton {
        width: 100%;
        margin-bottom: 15px;
    }
    .lineItemBidTable .slAct .biddingCnt .primButton button {
        width: 100%;
    }

    .lineItemBidTable .subLotLine .slAct {
        max-width: initial;
    }
    .lineItemBidTable .sl-pbids .actCnt {
        max-width: initial !important;
        width: 100% !important;
    }
}

@media (max-width: 1100px) {
    .pv-media .media-left {
        max-width: 350px;
        margin-right: 20px;
    }
    .pv-media .image-gallery-content .image-gallery-slide .image-gallery-image {
        width: 220px;
    }
}

@media (max-width: 767px) {
    .Automobile .timer-wrapper {
        padding-top: 20px;
        margin-top: 20px;
    }
    .Automobile .ndCTA {
        display: flex;
        justify-content: flex-end;
    }
    .product-media-wrapper {
        display: block;
    }
    .pv-media.product-media-wrapper
        .image-gallery-content
        .image-gallery-slide
        .image-gallery-image {
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .timer-wrapper {
        display: flex;
        align-items: center;
        align-content: center;
        margin-bottom: 10px;
        background: #e8e8e8;
        padding: 10px;
        justify-content: center;
    }

    .prodInfoSlider .pvTitleContainer {
        flex-direction: column-reverse;
        align-items: flex-start !important;
        margin-bottom: 10px;
    }

    .timer-wrapper .lv-timer-new {
        margin: 0 10px 0 0;
        height: 30px;
    }
    .timer-wrapper .li-timer {
        font-weight: 500;
        font-size: 14px;
        color: #2c2c37;
        margin-bottom: 0;
    }

    .timer-wrapper .lv-timer {
        margin: 0 10px 0 0;
        height: 24px;
    }

    .product-media-wrapper .css-nzyzm1-SkeletonTheme .react-loading-skeleton {
        max-width: 100%;
        max-height: 200px;
    }

    .pv-media.product-media-wrapper .media-left {
        width: 100%;
        margin: 0 auto;
        max-width: 475px;
    }

    .sublots-table table,
    .sublots-table tbody tr,
    .sublots-table tbody,
    .sublots-table tbody td {
        display: block !important;
        width: 100%;
    }
    .sublots-table table thead {
        display: none !important;
    }

    .sublots-table tbody tr {
        border: 1px solid #dbdbdb;
        padding: 10px 10px 10px 10px;
        position: relative;
    }

    .sublots-table tbody tr td {
        border: none;
        position: relative;
        padding: 0 0 3px;
    }

    .sublots-table tbody tr td:first-child {
        /* display: none !important; */
    }

    .sublots-table tbody tr td.sl-img-wrap {
        /* position: absolute; */
        width: 150px;
        height: 150px;
        margin: 0 auto;
        /* left: 5px; */
        /* top: 10px; */
    }

    .sublots-table td.sl-img-wrap img {
        width: 100%;
        height: 100%;
    }

    .sublots-table tbody tr td.pro-title {
        padding-right: 30px;
    }

    .sublots-table tbody tr td.pro-title a {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .sublots-table tbody tr td.descBox .descLabel {
        cursor: pointer;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-size: 14px;
    }

    .sublots-table tbody tr td.price-con {
        min-width: 48%;
        display: inline-block !important;
        width: auto;
    }

    .sublots-table .sl-pbids .row {
        margin: 0;
        flex-wrap: wrap !important;
        position: relative;
    }

    .sublots-table .sl-pbids .row > div {
        width: 100%;
    }
    .subLotLine {
        max-width: max-content;
        width: 100%;
    }

    .subLotLine .slAct:first-child {
        margin-bottom: 15px;
    }

    .sublots-table .sl-pbids .row .flex-wrap {
        width: auto !important;
        padding-right: 80px;
    }

    .sublots-table .sl-pbids .input-group {
        margin: 0 0 10px;
        width: 100% !important;
    }

    .sublots-table .sl-pbids .row .input-group-append {
        padding: 5px 5px;
        height: 34px;
        background: #f8f8f8;
        width: 40px;
        border: 1px solid #ccc;
        border-radius: 4px 0 0 4px;
        font-size: 12px;
    }

    .sublots-table .sl-pbids .row .form-control {
        height: 34px;
        margin-left: -1px;
        font-size: 13px;
    }

    .sublots-table .sl-pbids .row > div.pbid-btn-wrap {
        position: absolute;
        width: 75px;
        right: 0;
        top: 0;
    }
    .sl-pbids.slidView button,
    .lineItemBidTable .sl-pbids .pink-btn.pbtn-sm {
        margin-left: 0;
        font-size: 13px;
        width: 100%;
        min-width: 100% !important;
        display: block;
        max-width: 100%;
        padding: 5px;
        height: 34px;
    }
    .sublots-table tbody tr td.nob-wrap {
        position: absolute;
        width: auto;
        right: 10px;
        top: 10px;
    }

    .descBox .descContent {
        left: 0;
        right: 0;
        width: 100%;
    }

    .lineItemBidTable .responsiveView {
        padding-left: 80px;
        font-weight: 500;
    }

    .lineItemBidTable .responsiveView:before {
        position: absolute;
        content: attr(data-title) ':';
        font-weight: 400;
        left: 0;
        top: 0;
        color: #606060;
    }

    .sl-pbids.slidView button,
    .lineItemBidTable .sl-pbids .pink-btn.pbtn-sm {
        height: auto;
        min-width: max-content !important;
    }

    .subLotLine .slAct {
        justify-content: left;
        width: 100%;
    }

    .sublots-table .sl-pbids .actCnt {
        padding-right: 10px !important;
        max-width: 150px;
    }

    .sublots-table .pbid-btn-wrap button {
        height: 34px !important;
    }

    .subLotLine .slAct .pbid-btn-wrap {
        width: auto !important;
        position: relative !important;
    }
}

@media (max-width: 500px) {
    .prodInfoSlider .biddingCnt {
        flex-wrap: wrap;
    }
    .prodInfoSlider .biddingCnt .customInput {
        margin-bottom: 10px;
        width: 100%;
    }

    .prodInfoSlider .biddingCnt > div {
        width: 100%;
    }

    .prodInfoSlider .biddingCnt .primButton {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media (max-width: 479px) {
    .sli-pbids .input-group ~ p {
        position: absolute;
        bottom: 0;
    }
    .prodInfoSlider .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 300px;
    }
}

@media (max-width: 425px) {
    .Automobile .ndCTA {
        display: flex;
        justify-content: center;
    }
    .css-nzyzm1-SkeletonTheme .react-loading-skeleton {
        max-width: 100%;
        max-height: 200px;
        width: 100% !important;
    }
}
