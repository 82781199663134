.bidAcordent {
    /* display: flex; */
    padding: 0.2rem 0rem;
}
.bidAcordent .hisDateTime {
    font-size: 13px;
    font-weight: 400;
    white-space: nowrap;
    color: #afafaf;
    margin: 0 0.5rem 0 0;
}
.bid-time {
    /* width: 100%;
    padding: 6px 0;
    margin: 0 0.5rem; */
    text-align: left;
    margin: 0.5rem 0;
}
.hisDateTime {
    margin-bottom: 0;
}
.bidAcordent .accorLabel {
    background-color: #ebf5f4;
    width: 100%;
}
.accorLabel .comment-dtls {
    padding: 6px;
    text-align: left;
    margin-bottom: 0;
    font-size: 16px;
    color: #383838;
}
.post-by-seller {
    background-color: #f8f8f8;
    max-width: 100%;
    width: 100%;
    padding: 6px 10px;
}
.post-by-seller .post-seller-heading {
    display: flex;
    padding-bottom: 5px;
}
.post-seller-heading .seller-heading {
    padding: 0 0;
    text-transform: capitalize;
    margin-bottom: 0;
    text-align: left;
    max-width: 350px;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.post-seller-heading .seller-likes {
    margin: 0 0.3rem;
    padding: 0rem 0;
}
.post-seller-heading .seller-likes .fa {
    font-weight: 500;
    font-size: 14px;
}
.post-seller-heading .seller-likes {
    display: flex;
    align-items: center;
    color: #b5b5b5;
    padding-left: 10px;
}

.post-seller-heading .seller-likes .thumb-count {
    margin: 0px 0;
    font-size: 14px;
    font-weight: 500;
}
.post-by-seller .thumbs-up-two .second-thumb {
    background-color: var(--primColor);
    padding: 1rem;
    max-width: 55px;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
}
.post-by-seller .thumbs-up-two .second-thumb {
    display: flex;
    justify-content: center;
}
.post-by-seller .thumbs-up-two {
    display: flex;
    align-items: center;
}
.thumbs-up-two .second-thumb,
.thumbs-up-two .contrative-link {
    margin: 0 0.5rem;
}

.thumbs-up-two .contrative-link {
    padding: 1rem 0px;
}
.post-seller-heading .seller-likes .fa-thumbs-up,
.post-by-seller .thumbs-up-two .second-thumb .fa-thumbs-up {
    padding-right: 5px;
}
.post-by-seller .thumbs-up-two .second-thumb {
    color: white;
    height: 30px;
    max-width: 65px;
    align-items: center;
    border-radius: 3px;
    /* padding-left: 5px; */
    font-size: 14px;
}
.post-seller-heading .seller-likes .thumb-icon-one {
    color: var(--primColor);
    margin: 0px 10px;
}
.post-by-seller .seller-content {
    text-align: left;
    font-size: 14px;
    overflow: auto;
}

.thumbs-up-two .contrative-link a {
    color: var(--primColor);
    font-size: 14px;
}
.historyView.primButton button .MuiButton-label {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}
.historyView.primButton button {
    height: 39px;
    width: 125px;
    border-radius: 0;
}
.historyView {
    text-align: right;
    margin: 20px 0px;
}
.seller-content .seller-attach-img {
    width: 150px;
}

.seller-content .pswp-thumbnails {
    display: flex;
}

.seller-content .pswp-thumbnails img {
    object-fit: cover;
    margin-right: 10px;
}
@media (max-width: 1025px) {
    .bidAcordent {
        /* display: flex; */
        /* flex-wrap: wrap; */
    }
}
