.LiquidationPalletDashboardLayout table .ssActBtn button:first-child {
    color: var(--primColor);
}

.LiquidationPalletDashboardLayout table .ssActBtn button:last-child {
    color: #ff6c6c;
}

.LiquidationPalletDashboardLayout .savedSearches .ssBtn {
    margin: 0 0 30px auto;
    width: max-content;
}
