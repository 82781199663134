.FoodIndustry .mainHeader {
    height: 100%;
    /* position: relative; */
    top: 0;
    z-index: 10;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
    position: sticky;
    top: 0;
}

.FoodIndustry a {
    color: var(--primColor);
}

.FoodIndustry .mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 4%;
}

.FoodIndustry .mainHeader .headRt .hdrRtLt {
    width: 100%;
}

.FoodIndustry .mainHeader .headRt ul li {
    color: inherit;
    white-space: pre;
}

.FoodIndustry .mainHeader .headRt ul a {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.FoodIndustry .mainHeader .headRt ul a.active {
    color: var(--primColor);
}

.headerSellBtn a {
    display: flex;
    align-items: center;
}

.headerSellBtn .material-icons {
    margin-left: 10px;
}

.mainHeader .headRt ul li button {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
}

.mobHeader .headRt ul li button {
    min-width: initial !important;
    margin: 0;
    width: 40px;
}

.mainHeader .headRt ul li button .material-icons {
    padding-left: 10px;
}

.FoodIndustry .mainHeader .headRt ul a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: inherit;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.mainHeader .headRt ul a.active:after {
    width: 100%;
    left: 0;
}

.mainHeader .headRt ul a:hover:after {
    width: 100%;
    left: 0;
}

.mainHeader .headRt ul li:not(:last-child) {
    margin-right: 30px;
}

.nav-standard-top-area-search-inner {
    position: relative;
    width: 100%;
}

.FoodIndustry .mainHeader .nav-standard-top-area-search-inner {
    background: #f5f5f5;
    width: 100%;
    padding-right: 45px;
    border: 1px solid #9b9b9b;
    border-radius: 100px;
}

.FoodIndustry .mainHeader .srchLstCntnr {
    width: 100%;
    max-width: 600px;
    margin-left: 10%;
}

.FoodIndustry .mainHeader .nav-standard-top-area-search-inner > div {
    display: flex;
}

.FoodIndustry .nav-standard-top-area-search-input:first-child {
    border-radius: 100px 0 0 100px;
    background: #fff;
    /* border-right: 1px solid #9b9b9b; */
    width: 100%;
}

.FoodIndustry .nav-standard-top-area-search-input {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    background: #f5f5f5;
}

.FoodIndustry .nav-standard-top-area-search-inner select {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    width: 220px;
    font-size: 15px;
    color: #9b9b9b;
    background: #f5f5f5;
    border-left: 1px solid #9b9b9b;
}

.FoodIndustry .nav-standard-top-area-search-input::placeholder {
    font-size: 15px;
    color: #9b9b9b;
}

.FoodIndustry .nav-standard-top-area-search-inner select:focus {
    box-shadow: none;
    outline: 0;
}

.nav-standard-top-area-search-input::placeholder {
    color: #333;
}

.nav-standard-top-area-search-input:focus {
    outline: none;
    border-color: #ddd;
}

.FoodIndustry .nav-standard-top-area-search-sumbit {
    width: 45px;
    height: 45px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: var(--secColor);
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.FoodIndustry .mainHeader .headLt .headerLogo {
    width: 180px;
    object-fit: contain;
    height: 70px;
    margin-right: 20px;
}

.headerDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 300px;
    background: #fff;
}

.headerDrawer .mobShopLocation.MuiListItem-root {
    flex-wrap: wrap;
}

.headerDrawer .mobShopLocation.MuiListItem-root p {
    font-size: 14px;
    padding-inline-start: 8px;
    margin: 0;
}

.headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
    width: 100%;
    justify-content: space-between;
}

.headerDrawer .headerDrawerNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: calc(100vh - 100px);
    height: auto;
}

.headerDrawer .headerDrawerNavLinks hr {
    width: 100%;
}

.respNav,
.respNavBtn {
    display: none !important;
}

.respHeaderSearch .MuiPaper-root {
    padding: 15px;
}

.respHeaderSearch .nav-standard-top-area-search-input {
    height: 50px;
}

.respHeaderSearch button {
    height: 100%;
}

.headerAuthBtn {
    background: #f5f5f5;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 10%);
    height: 45px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.headerAuthBtn .material-icons {
    color: #2e2e2e;
    font-size: 24px;
    margin-left: 12px;
}

.mainHeader .headRt ul .headerAuthBtn a {
    color: #2e2e2e;
    font-weight: 600;
}

.mainHeader .headRt .headerIcons {
    min-width: initial;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.mainHeader .headRt .headerIcons .material-icons {
    padding: 0;
    margin: 0;
}

.FoodIndustry .headBottomNav {
    border-top: 1px solid #dbdbdb;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.FoodIndustry .headBottomNav ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.FoodIndustry .headBottomNav li,
.FoodIndustry .headBottomNav li a {
    font-size: 15px;
    font-weight: 500;
    color: #2e2e2e;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.FoodIndustry .headBottomNav a:hover,
.FoodIndustry .headBottomNav a.active {
    color: var(--secColor);
}

.FoodIndustry .headBottomNav a:after {
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: inherit;
    width: 0;
}

.FoodIndustry .headBottomNav a:hover:after,
.FoodIndustry .headBottomNav a.active:after {
    width: 0%;
    left: 0;
    background: var(--secColor);
}

.FoodIndustry .headBottomNav li > button .MuiButton-label {
    font-size: 16px;
    font-weight: 600;
    color: #2e2e2e;
    text-transform: capitalize;
}

.FoodIndustry .headBottomNav li:first-child {
    margin-right: 25px;
}

.FoodIndustry .contactNav li a:after {
    display: none;
}

.FoodIndustry .contactNav li:first-child {
    border-right: 1px solid #d0d0d0;
    margin-right: 16px;
    padding-right: 16px;
}

.FoodIndustry .contactNav .socialIcons a {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: var(--primColor);
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.FoodIndustry .contactNav .socialIcons a i {
    margin: 0 auto;
}

.FoodIndustry .contactNav .socialIcons a:not(:last-child) {
    margin-right: 10px;
}

.catgDrawer .MuiDrawer-paper {
    width: 350px;
}

.catgDrawer .noCatg {
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.catgDrawer .noCatg img {
    width: 200px;
    height: auto;
    object-fit: contain;
}

.catgDrawer .noCatg h6 {
    font-size: 16px;
    margin: 15px 0 0;
}

.catgDrawer .catgHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
}

.catgDrawer .catgHead h3 {
    font-size: 24px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
    position: relative;
}

.catgDrawer .viewAllAuc {
    color: var(--primColor);
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 10px;
    border-radius: 0;
}

.catgDrawer .viewAllAuc:hover {
    background: var(--primColor);
    color: #fff;
}

.catgDrawer .viewAllAuc .MuiButton-label {
    font-weight: 500;
}

.catgDrawer .catgHead h3::after {
    position: absolute;
    content: '';
    width: 70px;
    height: 3px;
    bottom: -10px;
    left: 0;
    border-radius: 5px;
    background: var(--primColor);
}

.catgDrawer .catgHead button {
    min-width: auto;
    position: absolute;
    background: var(--primColor);
    top: 20px;
    right: 0;
    width: 35px;
    height: 25px;
    color: #fff;
    border-radius: 25px 0 0 25px;
}

.catgDrawer .catgLinks {
    margin: 0;
    margin-top: 20px;
}

.catgDrawer .catgLinks .homeCatgInner {
    padding: 15px 20px;
}

.homeCatgInner.auctions .multiSellerTimerView h6 {
    font-size: 15px;
}

.catgDrawer.auctions .MuiDrawer-paper {
    width: 550px;
}

.catgDrawer.auctions .auctionListInner h5 {
    font-weight: 600;
    font-size: 18px;
}

.catgDrawer.auctions .timerCnt {
    display: inline-block;
    margin-left: auto;
}

.catgDrawer.auctions .catgLinks .homeCatgInner:not(:last-child) {
    border-bottom: 3px solid #f7f7f7;
}

.FoodIndustry .headRt .primButton {
    color: white;
    margin-right: 25px;
}

.FoodIndustry .headRt .headerBtmDivider {
    width: 2px;
    height: 25px;
    margin: 0 10px !important;
    background: var(--primColor);
}

.FoodIndustry .mainHeader .headRt ul.hdrRtLt li {
    margin-right: 0;
}

.FoodIndustry .mainHeader .headRt ul li .lin-magnifier {
    border-radius: 100px;
}

.FoodIndustry .mainHeader .headRt ul li .lin-magnifier .material-icons {
    padding-left: 0;
    color: white;
}

.FoodIndustry .sdDvdrHdr {
    padding: 0 20px;
    color: #c1c6cd;
    font-weight: bold;
    font-size: 14px;
}

.FoodIndustry .sdBrDvdr {
    border-top: 2px solid #c1c6cd;
    margin-top: 5px;
}

.nocardBanner {
    background: #4aa168;
    padding: 12px 20px;
}

.nocardBanner p {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    cursor: pointer;
}

/* Responsive Code */

@media (max-width: 1366px) {
    .FoodIndustry .headBottomNav li:not(:last-child) {
        margin-right: 25px;
        font-size: 14px;
    }
}

@media (max-width: 1100px) {
    .FoodIndustry .mainHeader .nav-standard-top-area-search-inner {
        width: auto;
        max-width: 520px;
        min-width: 350px;
    }
    .FoodIndustry .headBottomNav li:not(:last-child) {
        margin-right: 25px;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .FoodIndustry .mainHeader .srchLstCntnr {
        margin-left: 0px;
    }
}

@media (max-width: 1024px) {
    .mainHeader {
        height: 80px;
    }

    .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 10px;
    }

    header .headLt img {
        max-width: 180px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .deskNav {
        display: none !important;
    }
    .respNav {
        display: block !important;
    }
    .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: var(--headerFontColor) !important;
    }

    .FoodIndustry .respNavBtn {
        color: var(--primColor);
    }

    .FoodIndustry .respNavBtn .material-icons,
    .crtIconBtn .MuiSvgIcon-root {
        color: var(--primColor);
    }

    .FoodIndustry .mnLftNav.respNavBtn {
        margin-inline-start: 0 !important;
    }

    .headRt ul {
        list-style: none;
        margin: 0;
    }

    .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-family: var(--FuturaStdMedium);

        /* justify-content: space-between; */
    }

    .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }

    header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: flex-start;
        font-family: var(--FuturaStdMedium);
        flex-wrap: nowrap;
    }

    .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .headRt.respNav .navRespLinks a.active,
    .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .headRt.respNav .naLogoHead {
        padding: 20px 0px 20px 30px;
        padding-inline-start: 20px;
        padding-inline-end: 0;
    }

    .headRt.respNav .naLogoHead img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    .FoodIndustry .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
        background: #fff;
        height: 100vh;
        overflow: hidden;
        /* overflow: -webkit-paged-x; */
    }

    .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .navCategories .navBack {
        color: #8a8a8a;
    }

    .navCategories .MuiListItem-button {
        width: 100%;
    }
    .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }
    .headerSearchForm {
        width: 90%;
    }
    .mainHeader .headLt .headerLogo {
        width: 100%;
        height: 90px;
    }
}

@media (max-height: 600px) {
    .headRt.respNav .navRespLinks {
        height: 70vh;
        overflow: scroll;
        padding-bottom: 100px;
    }
}

@media (max-width: 992px) {
    .FoodIndustry .headBottomNav li:not(:last-child) {
        margin-right: 15px;
    }

    .FoodIndustry .mainHeader .headLt .headerLogo {
        margin-right: 0px;
    }
}

@media (max-width: 990px) {
    .FoodIndustry .nav-standard-top-area-search-sumbit {
        width: 50px;
        height: 50px;
    }
    .headRt.respNav .naLogoHead {
        padding: 15px 0px 15px 30px;
    }
    .FoodIndustry .headRt.respNav .naLogoHead img {
        height: 50px;
        width: 180px;
    }

    .FoodIndustry .headerDrawer .headRt.respNav .naLogoHead {
        padding: 20px 30px;
    }

    .FoodIndustry .headerDrawer .headDrawerClose {
        position: fixed;
        background: white;
        width: 30px;
        height: 30px;
        min-width: unset;
        border-radius: 100px;
    }

    .FoodIndustry .mainHeader .headRt ul li button .material-icons {
        padding: 0 8px;
    }

    .FoodIndustry .mnHdrLftDrwr .headDrawerClose {
        left: 319px;
    }

    .FoodIndustry .acntRtDrwr .headDrawerClose {
        right: 319px;
    }

    .FoodIndustry .headRt.respNav .navRespLinks a {
        font-size: 14px;
    }

    .FoodIndustry .headRt.respNav .MuiListItem-root span.material-icons {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .FoodIndustry .headerDrawer .headRt.respNav .naLogoHead {
        height: 80px;
        padding: 0px;
    }

    .FoodIndustry .mainHeader .headLt .headerLogo {
        width: fit-content;
        height: 60px;
        object-fit: contain;
    }

    .FoodIndustry .headRt.respNav .naLogoHead img {
        width: fit-content;
        height: 60px;
        object-fit: contain;
    }

    .FoodIndustry .mobHeader .headRt ul li button {
        width: auto;
        margin-left: 0px !important;
        padding: 0;
    }
    .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 0;
    }

    .FoodIndustry .multisellerProductDrawer .mssBidIntDet .mssBidMisc {
        align-items: flex-start;
    }

    .FoodIndustry .respNav {
        height: 100vh;
        overflow: hidden;
    }

    .FoodIndustry .respNav .navRespLinks {
        height: calc(100vh - 100px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
}

@media (max-width: 425px) {
    .FoodIndustry .mainHeader .headLt .headerLogo {
        height: 45px;
    }

    .FoodIndustry .headerDrawer .headRt.respNav .naLogoHead {
        height: 65px;
        padding: 0px;
    }

    .FoodIndustry .headRt.respNav .naLogoHead img {
        width: 100%;
        height: 45px;
        object-fit: contain;
    }

    .FoodIndustry .grid-card .gcTimer {
        font-size: 12px;
    }

    .FoodIndustry .searchRt .grid-card .galleryPrice {
        font-size: 12px;
    }

    .FoodIndustry .Grid.liquidationBuyCard .primButton button .MuiButton-label {
        font-size: 11px;
    }

    .FoodIndustry .searchRt .grid-card .hostedBy {
        font-size: 12px;
    }

    .FoodIndustry .respNav {
        height: 100vh;
        overflow: hidden;
    }

    .FoodIndustry .respNav .navRespLinks {
        height: calc(100vh - 100px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }

    .headRt.respNav .navRespLinks {
        padding-bottom: 100px;
    }
}

@media (max-width: 600px) {
    .invcActnBtns {
        flex-wrap: wrap;
    }

    .FoodIndustry .headRt {
        padding-right: 6px;
        padding-left: 10px;
    }

    .FoodIndustry .headRt ul li.actnIcon button .material-icons {
        padding-right: 6px;
    }

    .nocardBanner {
        background: #4aa168;
        padding: 5px 12px;
    }

    .nocardBanner p {
        color: white;
        font-size: 15px;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
    }
}

@media (max-width: 500px) {
    .invoiceCard .card-header {
        flex-wrap: wrap;
    }
}
