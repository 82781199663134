.Marine .mainHeader {
    max-height: 190px;
    height: 100%;
    top: 0;
    z-index: 10;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
    position: sticky;
    top: 0;
}

.Marine .headLt .nav-standard-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Marine .headLt .nav-standard-logo,
.Marine .headLt .nav-standard-logo img,
.Marine .mainHeader .headLt .headerLogo {
    max-height: 125px;
    object-position: center;
    height: 100%;
}

.Marine .headerLogo {
    object-fit: scale-down;
    width: 100%;
    height: 100%;
    max-height: 145px;
    padding-top: 0;
    padding-bottom: 0;
}

.Marine .hrToWrpr .popupLgo {
    width: 100%;
    /* max-width: 180px; */
    height: 50px;
    object-fit: scale-down;
    margin-left: auto;
    margin-right: auto;
}
.Marine .add-button-sell {
    padding-right: 50px;
}
.Marine .add-button-sell a {
    color: black !important;
}
.Marine .hrToWrpr .popHr {
    font-size: 25px;
    margin-top: 40px;
}

.Marine .hrToWrpr .radCntnr {
    margin-top: 56px;
}

.Marine .popRadio {
    margin: 0 15px;
    position: relative;
}

.Marine .popRadio input {
    position: absolute;
    visibility: hidden;
}

.Marine .popRadio input:checked + label,
.Marine .popRadio input:hover + label {
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    color: white;
}

.Marine .popRadio input:checked + label img,
.Marine .popRadio input:hover + label img {
    filter: invert(1);
}

.Marine .popRadio label {
    width: 200px;
    height: 200px;
    border: 1px solid var(--secColor);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
    font-weight: 600;
    transition: ease-in-out 0.3s;
}

.Marine .popRadio label img {
    margin-bottom: 20px;
}

.Marine .hrToWrpr .login-ip-box h1 {
    display: none;
}

.Marine .hrToWrpr .popHr span {
    color: var(--primColor);
}

.Marine .hrToWrpr .popTgLn {
    color: #393838;
    font-weight: 500;
    font-size: 17px;
}

.Marine .hrToWrpr .lgnWthSclMda {
    margin-top: 28px;
}

.Marine .hrToWrpr .lgnWthSclMda p {
    font-size: 15px;
    color: #393838;
}

.Marine .hrToWrpr .lgnWthSclMda img {
    width: 38px;
    height: 38px;
    margin: 0 15px;
}

.Marine .hrToWrpr .orTxt {
    color: rgb(156, 156, 156);
    text-align: center;
    margin-top: 24px;
    font-size: 15px;
}

.Marine .hrToWrpr .customCheckbox .MuiFormHelperText-root {
    display: none;
}
.Marine .hrToWrpr .customCheckbox .MuiFormControlLabel-root {
    margin-bottom: 0px;
}

.Marine .hrToWrpr .fpRm {
    margin-bottom: 20px;
}

.Marine .hrToWrpr .fpRm a {
    color: var(--primColor);
}

.Marine .hrToWrpr .newAcc a {
    color: var(--primColor);
    font-weight: 500;
}

.Marine .hrToWrpr .lgnCntnr .newAcc {
    display: none;
}

.Marine .hrToWrpr .imHrTo {
    text-align: center;
}

.Marine .hrToWrpr .brSlrWrp {
    max-width: max-content;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.Marine .hrToWrpr .brSlrWrp .bsrIpt {
    position: relative;
    display: flex;
    align-items: center;
}

.Marine .hrToWrpr .brSlrWrp .bsrIpt input {
    position: absolute;
    visibility: hidden;
}

.Marine .hrToWrpr .brSlrWrp .bsrIpt label {
    width: 114px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.3s;
    margin: 0;
    border-radius: 7px;
    cursor: pointer;
}

.Marine .hrToWrpr .brSlrWrp .bsrIpt input:checked + label,
.Marine .hrToWrpr .brSlrWrp .bsrIpt input:hover + label {
    background: var(--primColor);
    color: white;
}

.Marine .hrToWrpr .customCheckbox a {
    margin-left: 6px;
    color: var(--primColor);
    font-weight: 500;
}

.Marine .hrToWrpr .reg-step1 .primButton {
    margin-top: 20px;
}

.Marine .popClrBtn {
    position: absolute;
    top: 10px;
    right: 16px;
    padding: 4px;
    color: var(--secColor);
    border: 1px solid var(--secColor);
}

.Marine .hdrMenuIcon {
    min-width: unset;
    padding: 0;
}

.Marine .mnSideMenu .catgHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
}

.Marine .mnSideMenu .catgHead button {
    min-width: auto;
    position: absolute;
    background: var(--primColor);
    top: 20px;
    right: 0;
    width: 35px;
    height: 25px;
    color: #fff;
    border-radius: 25px 0 0 25px;
}

.Marine .mnSideMenu .catgHead .nav-standard-logo {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0px;
}

.Marine .mnSideMenu .MuiDrawer-paper {
    width: 350px;
}

.Marine .navList .navRespLinks a {
    color: black;
    display: flex;
    align-items: center;
    padding: 17px 15px;
    width: 100%;
}

.Marine .navList .navRespLinks a.active {
    color: white;
    background: var(--secColor);
}

.Marine .navList .navRespLinks .colpsLnk a.active {
    color: white;
    background: var(--secColor);
    opacity: 0.9;
}

.Marine .navList .navRespLinks a:hover {
    text-decoration: none;
}

.Marine .navList .navRespLinks a .material-icons {
    margin-right: 10px;
    text-decoration: none;
}

.Marine .navList .navRespLinks .MuiListItem-root {
    padding: 0px;
}

.Marine .navList h4.sdDvdrHdr {
    font-size: 15px;
    padding-left: 16px;
    color: gray;
}

.customDialog.lgnWrpr .MuiPaper-root.MuiDialog-paper {
    min-width: 500px;
}

.customDialog.lgnWrpr .lgnCntnr h1 {
    display: none;
}

.customDialog.lgnWrpr .forgotPasswordCta {
    margin-top: 10px;
}

.Marine .nav-standard-top-area-search-inner {
    position: relative;
    width: 100%;
}

.Marine .nav-standard-top-area-search-input:first-child {
    border-radius: 100px 65px 65px 100px;
    background: #f5f5f5;
    /* border-right: 1px solid #9b9b9b; */
    width: 100%;
}

.Marine .nav-standard-top-area-search-input {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    background: #f5f5f5;
}

.Marine .nav-standard-top-area-search-inner select {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    width: 220px;
    font-size: 15px;
    color: #9b9b9b;
    background: #f5f5f5;
    border-left: 1px solid #9b9b9b;
}

.Marine .nav-standard-top-area-search-input::placeholder {
    font-size: 15px;
    color: #9b9b9b;
}

.Marine .nav-standard-top-area-search-inner select:focus {
    box-shadow: none;
    outline: 0;
}

.Marine .nav-standard-top-area-search-input::placeholder {
    color: #333;
}

.Marine .nav-standard-top-area-search-input:focus {
    outline: none;
    border-color: #ddd;
}

.Marine .nav-standard-top-area-search-sumbit {
    width: 45px;
    height: 45px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: var(--secColor);
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.Marine .lgnCntnr .customCheckbox .MuiFormHelperText-root {
    display: block;
}

.Marine .crncySlt .customSelect {
    margin-bottom: 0px !important;
}

.Marine .navList .navRespLinks .MuiListItem-root.prsnNmTag {
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 5px;
}

/* @media (min-width: 1024px) {
    .Marine .mainHeader .headLt .headerLogo {
        height: 65px;
    }
} */

.Marine .topAdtnFtrHeader {
    background: whitesmoke;
    display: flex;
    align-items: center;
}

.Marine .topAdtnFtrHeader .customContainer > div {
    margin-left: 2px;
}

.Marine .topAdtnFtrHeader .customSelect {
    margin-bottom: 0px;
    background: #00000026;
}

.Marine .topAdtnFtrHeader .customSelect .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.Marine .topAdtnFtrHeader .customSelect .MuiOutlinedInput-root {
    border-radius: 0px;
}

.Marine .rtPd a {
    color: #333;
    margin-right: 20px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
}

.Marine .rtPd a span {
    margin-right: 8px;
}

.Marine .rtPd a:hover {
    text-decoration: none;
    color: var(--primColor);
}

@media (max-width: 600px) {
    .Marine .headerLogo {
        height: 100%;
        max-height: 120px;
        padding-top: 10px;
    }
    .Marine .mainHeader .headLt .headerLogo {
        width: 100%;
        height: 55px;
        padding-top: 0px;
    }
    .Marine .customDialog.lgnWrpr .MuiPaper-root.MuiDialog-paper {
        min-width: fit-content;
        margin: 30px 15px;
    }
    .Marine .hrToWrpr .customCheckbox .MuiFormControlLabel-root .MuiTypography-root,
    .Marine .hrToWrpr .fpRm a {
        font-size: 14px;
    }
    .Marine
        .hrToWrpr
        .customCheckbox
        .MuiFormControlLabel-root
        .MuiTypography-root
        .MuiButtonBase-root {
        padding-left: 0;
        padding-right: 0;
    }
    .Marine .customDialog .MuiDialogContent-root {
        padding: 8px 15px;
    }

    .Marine .crncySlt .customSelect {
        margin-bottom: 0px !important;
        width: 100% !important;
        margin-right: 0px !important;
    }

    .Marine .crncySlt {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .Marine .topAdtnFtrHeader .customContainer > div {
        margin-left: 2px;
        width: 100%;
        max-width: 115px;
    }

    .Marine
        .topAdtnFtrHeader
        .customSelect
        .MuiFormControl-root.MuiTextField-root
        .MuiSelect-outlined.MuiSelect-outlined {
        padding: 6px 14px;
    }

    .Marine .hrToWrpr .radCntnr {
        flex-wrap: wrap;
        margin-top: 30px;
    }

    .Marine .popRadio,
    .Marine .popRadio label {
        width: 100%;
        max-width: 400px;
    }

    .Marine .popRadio label {
        height: 150px;
    }
}

@media (max-width: 475px) {
    .Marine .mnSideMenu .MuiDrawer-paper {
        width: 260px;
    }
}
