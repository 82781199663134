.msgPgWrpr {
    display: grid;
    grid-template-columns: 330px 1fr;
    gap: 20px;
    padding: 15px;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
}

.msgPgWrpr .topCntnr {
    background: white;
    height: 55px;
    box-shadow: 0 0 25px #0000001c;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;
    margin-bottom: 25px;
}

.msgPgWrpr .topCntnr .srchBoxCntnr {
    display: flex;
    align-items: center;
}

.msgPgWrpr .topCntnr .srchBoxCntnr .material-icons,
.msgPgWrpr .topCntnr .srchBoxCntnr input::placeholder {
    color: #c1c1c1;
}

.msgPgWrpr .topCntnr .srchBoxCntnr input {
    height: 100%;
    border: none;
    padding: 0 10px;
    width: 100%;
}

.msgPgWrpr .prflWrpr {
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: #f5f5f5;
    margin-right: 10px;
    color: #bbbbbb;
    text-transform: uppercase;
}

.msgPgWrpr .userCard {
    display: flex;
    align-items: flex-start;
    max-height: 70px;
    border-radius: 6px;
    padding: 12px 9px;
    width: 100%;
    background: #fbfbfb;
    cursor: pointer;
    transition: ease-in-out 0.35s;
    margin-bottom: 10px;
}

.msgPgWrpr .userCard .msgInfo {
    width: 100%;
}

.msgPgWrpr .userCard .msgInfo small {
    color: #b2b2b2;
}

.msgPgWrpr .userCard .prflWrpr {
    max-width: 45px;
    width: 100%;
    height: 45px;
}

.msgPgWrpr .userCard .rctnMsgTxt {
    width: 170px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
}

.msgPgWrpr .userCard:hover,
.msgPgWrpr .userCard.active {
    background: white;
    box-shadow: 0 0 25px #0000001c;
    border-radius: 0px;
}

.msgPgWrpr .messageBody {
    background: whitesmoke;
    height: 550px;
    border-radius: 6px;
    padding: 15px;
}

.msgPgWrpr .messageBody .msgIptWrpr.topCntnr {
    padding: 5px;
}

.msgPgWrpr .messageBody .msgIptWrpr textarea {
    width: 100%;
    border: none;
    resize: none;
    padding: 10px;
    height: 100%;
    font-size: 14px;
    padding-right: 35px;
    border-right: 1px solid rgba(128, 128, 128, 0.2);
    margin-right: 5px;
}

.msgPgWrpr .messageBody .msgIptWrpr textarea::placeholder {
    color: #b2b2b2;
    font-size: 13px;
}

.msgPgWrpr .messageBody .msgIptWrpr .sndButn {
    color: var(--primColor);
}

.msgPgWrpr .messageBody .msgInrBody {
    height: calc(100% - 70px);
    overflow: auto;
    margin-bottom: 15px;
    padding: 0 25px;
}

.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    flex-direction: column;
}
.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr.justify-content-start .chatBubble {
    margin-right: auto;
    margin-left: unset;
    margin-top: 5px;
    margin-bottom: 5px;
}
.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr .chatBubble {
    background: white;
    border-radius: 10px;
    position: relative;
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr.sndWrpr .chatBubble {
    background: var(--primColor);
    color: white;
}

.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr .chatBubble::after {
    content: '';
    position: absolute;
    top: 0;
    left: -19px;
    border-radius: 0;
    border-top: 15px solid white;
    border-right: 15px solid white;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
    z-index: 1;
}

.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr.sndWrpr .chatBubble::after {
    left: unset;
    right: -19px;
    border-top: 15px solid var(--primColor);
    border-left: 15px solid var(--primColor);
    border-bottom: 15px solid transparent;
    border-right: 15px solid transparent;
}
.Auctioneer .messageSearch {
    display: none;
}
.Auctioneer .rtMsgWrpr {
    overflow: hidden;
}
.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr .chatBubble pre {
    margin: 0;
    font-family: var(--primFont);
    white-space: pre-wrap;
    max-width: 350px;
    margin: 10px 25px;
}
.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr .chatBubble #CommentPhotos {
    margin: 10px 25px;
}
.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr.sndWrpr .chatBubble pre {
    color: white;
}

.msgPgWrpr .messageBody .msgInrBody .chatBblWrpr small {
    color: #b2b2b2;
    margin: auto;
    text-align: center;
}

.msgPgWrpr .userCard .msgCnt {
    background: var(--primColor);
    color: white;
}

.usrListWrpr {
    height: 550px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.username {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 138px;
}

.msgPgWrpr::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.msgPgWrpr::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(217, 217, 217);
    border-radius: 10px;
}

/* Handle */
.msgPgWrpr::-webkit-scrollbar-thumb {
    background: rgb(96, 96, 96);
    border-radius: 10px;
}

/* Handle on hover */
.msgPgWrpr::-webkit-scrollbar-thumb:hover {
    background: rgb(96, 96, 96);
}

@media (max-width: 700px) {
    .msgPgWrpr {
        grid-template-columns: 1fr;
        position: relative;
    }

    .msgPgWrpr .lftMsgWrpr {
        position: absolute;
        width: 100%;
        height: 100%;
        background: white;
        padding: 10px;
        left: -107%;
        transition: ease-in-out 0.3s;
        z-index: 9;
    }

    .msgPgWrpr .lftMsgWrpr.showUsers {
        left: 0;
    }
}
