.productViewStandalone .mpTop {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
}

.productViewStandalone .plTitle span {
    display: block;
}

.productViewStandalone .plTitle span:first-child {
    font-size: 16px;
    color: #747474;
    margin-bottom: 8px;
}

.ArtAuction .productViewStandalone .plTitle span:first-child {
    font-size: 22px;
    color: #747474;
    font-weight: 400;
}

.productViewStandalone .plTitle span:last-child {
    font-size: 25px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 15px;
}

.ArtAuction .productViewStandalone .plTitle span:last-child {
    margin-bottom: 22px;
}

.productViewStandalone .image-gallery {
    width: 520px;
}

.ArtAuction .productViewStandalone .image-gallery {
    width: 100%;
}

.productViewStandalone .image-gallery-thumbnails-container {
    text-align: start;
}

/* .productViewStandalone .image-gallery-thumbnails {
    width: 75px;
} */

.productViewStandalone .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.productViewStandalone .image-gallery-slide-wrapper.left {
    width: 100%;
}

.productViewStandalone .image-gallery-icon:hover {
    color: var(--primColor);
}

.productViewStandalone .image-gallery-thumbnail {
    border: 2px solid transparent;
    width: 75px;
}

.productViewStandalone .image-gallery-thumbnail.active,
.productViewStandalone .image-gallery-thumbnail:hover,
.productViewStandalone .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 0px;
}

.productViewStandalone .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 60px;
    object-fit: cover;
    border-radius: 0px;
}

.productViewStandalone .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 350px;
    min-height: 300px;
    cursor: initial;
}

.productViewStandalone .image-gallery-content .image-gallery-slide > div {
    background: #efefef;
}

.productViewStandalone .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}

.productViewStandalone .productInfo h5 {
    font-size: 18px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 25px;
}

.productViewStandalone .productInfo h5 span {
    font-size: 15px;
    font-weight: 500;
    width: 200px;
    display: inline-block;
}

.productViewStandalone .pvsCnt {
    display: grid;
    grid-template-columns: 520px auto;
    gap: 20px;
}

.ArtAuction .productViewStandalone .pvsCnt {
    grid-template-columns: 50% auto;
    gap: 60px;
}

.productViewStandalone .sl-pbids.slidView {
    width: auto;
    margin-top: 0;
    margin-left: auto;
}

.ArtAuction .productViewStandalone .mssBidItem.ArtbidderChanges .primButton.small button {
    background: #000;
    border-color: #000;
}
.ArtAuction .productViewStandalone .mssBidItem.ArtbidderChanges .primButton.small button,
.ArtAuction .productViewStandalone .mssBidItem.ArtbidderChanges .secButton.small button,
.ArtAuction .productViewStandalone .mssBidItem.ArtbidderChanges .tertButton.small button {
    height: 50px;
    border-radius: 4px;
}

.ArtAuction .ArtProviewBiddingSection .AuctionTytpe .bidingTypeTit {
    font-size: 20px;
    font-weight: 400;
    color: #000;
    margin-bottom: 9px;
}
.ArtAuction .ArtProviewBiddingSection .AuctionTytpe .BidingAmt {
    font-size: 34px;
    font-weight: 500;
    color: #000;
}
.ArtAuction .ArtProviewBiddingSection .AuctionTytpe {
}
.ArtAuction .ArtProviewBiddingSection {
    margin-bottom: 20px;
}

.ArtAuction .productViewStandalone .sl-pbids.slidView {
    margin-left: 0;
}

.ArtAuction .productViewStandalone .sl-pbids.slidView .artBiddingFlex {
    display: flex;
}

.productViewStandalone .biddingCnt .customInput {
    margin-bottom: 15px;
}

.ArtAuction .productViewStandalone .biddingCnt .customInput {
    margin-right: 13px;
    width: 175px;
}

.ArtAuction .productViewStandalone .biddingCnt .customInput .MuiInputBase-input {
    padding-top: 15.2px;
    padding-bottom: 15px;
}

.productViewStandalone form:last-child .biddingCnt .customInput {
    margin-bottom: 0px;
}

.productViewStandalone .mssBidItem {
    display: flex;
}

.productViewStandalone .mssBidHistory {
    float: right;
    height: 45px;
    width: auto;
    text-transform: initial;
    color: var(--primColor);
}

.ArtAuction .ProductViewArtThemeAuctionDetails .ActionDecription h4 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5px;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ActionDecription p {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #373737;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ArtProductInformation .accordionUlist li span {
    min-width: 140px;
    width: auto;
    display: inline-block;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ArtProductInformation .accordionUlist li {
    font-size: 20px;
    font-weight: 400;
    color: #373737;
    margin-bottom: 11px;
}
.ArtAuction
    .ProductViewArtThemeAuctionDetails
    .ArtProductInformation
    .accordionUlist
    .MuiTypography-root {
    font-size: 18px;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ArtProductInformation .MuiPaper-root {
    margin-bottom: 18px;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ArtProductInformation .MuiAccordionSummary-content {
    margin: 18px 0;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ArtProductInformation .MuiAccordionSummary-root {
    padding: 0px 25px;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ArtProductInformation .MuiCollapse-root {
    background: #f9f9f9;
    padding: 0 25px;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ArtProductInformation .accordionUlist {
    padding: 0;
    width: 100%;
    list-style-type: none;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ArtProductInformation .MuiAccordionSummary-root {
    background: #ffffff;
}
.ArtAuction .ProductViewArtThemeAuctionDetails .ActionDecription {
    margin-bottom: 60px;
}
.ArtAuction .ProductViewArtThemeAuctionDetails {
    margin-top: 30px;
}

.productViewStandalone .mssBidHistory .material-icons {
    margin-right: 10px;
}

.ArtAuction .productViewStandalone .mssBidHistory .material-icons {
    margin-right: 9px;
}

.ArtAuction .productViewStandalone .followUp .avatharInfo .userCrd {
    flex-wrap: wrap;
}

.ArtAuction .productViewStandalone .followUp .avatharInfo .userIdenty {
    width: 100%;
}

.ArtAuction .productViewStandalone .similarArtSection .followUp .avatharInfo {
    width: 100%;
}
@media (max-width: 1305px) {
    .ArtAuction .productViewStandalone .sl-pbids.slidView .artBiddingFlex {
        display: block;
    }
}
@media (max-width: 990px) {
    .FoodIndustry .productViewStandalone .pvsCnt {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .ArtAuction .productViewStandalone .timerWrapper .timeLabel::after {
        width: 100%;
    }
    .ArtAuction .productViewStandalone .mpTop span {
        width: 100%;
        margin: 25px auto 15px;
    }
}

@media (max-width: 767px) {
    .ArtAuction .productViewStandalone .pvsCnt {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 600px) {
    .productViewStandalone .image-gallery {
        width: unset !important;
    }

    .FoodIndustry .productViewStandalone .productInfo h5 {
        display: flex;
        flex-wrap: wrap;
    }

    .ArtAuction .productViewStandalone .mpTop {
        flex-direction: column;
    }

    .ArtAuction .similarArtSection h4 {
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    .ArtAuction .productViewStandalone .sl-pbids.slidView .artBiddingFlex {
        flex-wrap: wrap;
    }

    .ArtAuction .productViewStandalone .sl-pbids.slidView .artBiddingFlex .mssBidHistory {
        margin-left: -15px;
        margin-bottom: 10px;
    }

    .ArtAuction
        .productViewStandalone
        .sl-pbids.slidView
        .artBiddingFlex
        .mssBidHistory
        .MuiButton-label {
        font-size: 16px;
        display: flex;
    }
}
