.MultiSeller_twoDashboardLayout table .ssActBtn button:first-child {
    color: var(--primColor);
}

.MultiSeller_twoDashboardLayout table .ssActBtn button:last-child {
    color: #ff6c6c;
}

.MultiSeller_twoDashboardLayout .savedSearches .ssBtn {
    margin: 0 0 30px auto;
    width: max-content;
}
/* .multisellerDashboardLayout .savedSearches .ssBtn
{
    display: none;
} */
.multisellerDashboardLayout .savedSearches .table thead th:last-child {
    text-align: center;
}
