.FoodIndustryDashboardLayout {
    display: flex;
    justify-content: flex-start;
    margin-top: 45px;
    margin-bottom: 45px;
}

.FoodIndustryDashboardLayout .dashboardLt {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.FoodIndustryDashboardLayout .dashboardLt ul {
    background: #f5f5f5;
    margin: 0;
    border-radius: 5px;
}

.FoodIndustryDashboardLayout .dashboardLt ul > .MuiListItem-root {
    padding: 0;
}

.FoodIndustryDashboardLayout .dashboardLt ul > .MuiListItem-root a {
    color: #646464;
    font-size: 16px;
    padding: 20px;
    text-decoration: none;
    display: flex;
    width: 100%;
}

.FoodIndustryDashboardLayout .dashboardLt ul > .MuiListItem-root a .material-icons {
    margin-right: 10px;
}

.FoodIndustryDashboardLayout .dashboardLt ul > .MuiListItem-root a.active {
    background: var(--primColor);
    color: #fff;
}

.FoodIndustryDashboardLayout .dashboardLt ul > .MuiListItem-root a:not(.active):hover {
    background: var(--primColor);
    color: #fff;
    opacity: 0.75;
}

.FoodIndustryDashboardLayout .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.FoodIndustryDashboardLayout .dashboardRt .notificationPara {
    padding: 30px 0;
    text-align: center;
}

.FoodIndustryDashboardLayout .dashboardRt .maTitle {
    padding: 15px 30px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
}

.FoodIndustryDashboardLayout .dashboardRt .dashTitle {
    font-size: 20px;
    font-weight: 600;
    color: #0e131f;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 0;
}

.FoodIndustryDashboardLayout .moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    -webkit-margin-end: 5px;
    margin-inline-end: 5px;
    padding: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 20px;
}

.FoodIndustryDashboardLayout .dashSubtitle {
    font-size: 16px;
    color: #212529;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.FoodIndustry .multisellerDashboardLayout .table tbody td:last-child button {
    min-width: 135px;
    margin-bottom: 10px;
}

.FoodIndustry .multisellerDashboardLayout .table tbody td:last-child button.subLotSearch {
    width: 50px;
    min-width: unset;
}

.FoodIndustry .dashboardLt .sdDvdrHdr {
    color: #afbbcc;
}

/* Responsive Code */

@media (max-width: 1024px) {
    .FoodIndustryDashboardLayout .dashboardLt {
        max-width: 25%;
    }
    .FoodIndustryDashboardLayout .dashboardRt {
        max-width: 75%;
    }
    .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
        background: #fff;
    }
    .DashfilterWrpper .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }
    .DashfilterWrpper .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }
    .DashfilterWrpper .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-family: var(--FuturaStdMedium);
        /* justify-content: space-between; */
    }
    .DashfilterWrpper .navRespLinks a.active,
    .DashfilterWrpper .navRespLinks a.active span {
        color: var(--primColor);
    }
    .DashfilterWrpper .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-family: var(--FuturaStdMedium);
        flex-wrap: wrap;
    }
}

@media (max-width: 991px) {
    .FoodIndustryDashboardLayout .dashboardRt {
        max-width: 100%;
    }

    .FoodIndustryDashboardLayout .dashboardLt {
        display: none;
    }

    .FoodIndustryDashboardLayout .dashboardRt .dashTitle {
        align-items: center;
        display: flex;
    }

    .FoodIndustryDashboardLayout .dashboardRt .dashTitle .MuiButtonBase-root.d-none {
        display: block !important;
    }
    .FoodIndustryDashboardLayout
        .dashboardRt
        .dashTitle
        .MuiButtonBase-root.d-none
        .MuiButton-label {
        text-align: left;
    }
    .FoodIndustryDashboardLayout
        .dashboardRt
        .dashTitle
        .MuiButtonBase-root.d-none
        .MuiButton-label
        .MuiButtonBase-root {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 600px) {
    .FoodIndustry .favGrid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

@media (max-width: 475px) {
    .preferences-form-wrapper {
        overflow-x: auto;
    }
    .liquidationDashTabs .MuiTabs-flexContainer {
        white-space: pre-wrap;
        width: 100%;
    }
    .liquidationDashTabs .MuiTab-root {
        width: 48%;
        margin: 2px;
    }
    .FoodIndustryDashboardLayout .dashboardRt .maTitle {
        padding: 10px;
    }
    .FoodIndustryDashboardLayout .dashboardRt .dashTitle {
        font-size: 16px;
    }
}

@media (max-width: 425px) {
    .FoodIndustryDashboardLayout .dashboardRt .dashTitle {
        font-size: 14px;
    }
}
