@keyframes ldio-0xmhh735auim-o {
    0% {
        opacity: 1;
        transform: translate(0 0);
    }
    49.99% {
        opacity: 1;
        transform: translate(80px, 0);
    }
    50% {
        opacity: 0;
        transform: translate(80px, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 0);
    }
}
@keyframes ldio-0xmhh735auim {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(80px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
.ldio-0xmhh735auim div {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 60px;
    left: 20px;
}
.ldio-0xmhh735auim div:nth-child(1) {
    background: var(--primColor);
    animation: ldio-0xmhh735auim 1s linear infinite;
    animation-delay: -0.5s;
}
.ldio-0xmhh735auim div:nth-child(2) {
    background: var(--secColor);
    animation: ldio-0xmhh735auim 1s linear infinite;
    animation-delay: 0s;
}
.ldio-0xmhh735auim div:nth-child(3) {
    background: var(--primColor);
    animation: ldio-0xmhh735auim-o 1s linear infinite;
    animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-jcnnn8dl11r {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
}
.ldio-0xmhh735auim {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
.ldio-0xmhh735auim div {
    box-sizing: content-box;
}
