.LiveStock .vcaLogin {
    width: 100%;
    margin: 0;
    background: #efefef;
}

.LiveStock .vcaLogin > [class^='col-'] {
    padding: 0;
}

.LiveStock .vcaLogin .loginFormBox {
    margin: 0;
    padding: 0;
    width: 100%;
    border-radius: 12px;
    background: #fff;
    height: 100%;
    display: flex;
    align-items: center;
}

.LiveStock .vcaLogin .loginFormBox form {
    max-width: 475px;
    margin: 60px auto;
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.LiveStock .vcaLogin .nav-standard-logo {
    text-align: center;
    display: block;
}

.LiveStock .vcaLogin .nav-standard-logo img {
    width: 100%;
    height: 150px;
    object-fit: contain;
}

.LiveStock .vcaLogin .loginFormBox form .primButton button {
    border-radius: 12px;
}

.LiveStock .vcaLogin .loginFormBox form .MuiFormControlLabel-root {
    margin: 0;
}

.LiveStock .vcaLogin .newAcc a {
    padding-left: 5px;
    color: var(--primColor);
}

.LiveStock .vcaLogin .loginFormBox h1 {
    font-size: 28px;
    font-weight: 600;
    color: #323749;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: center;
    position: relative;
}
/* 
.LiveStock .vcaLogin .loginFormBox h1::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 35%;
    width: 15px;
    height: 1px;
    background: var(--primColor);
}

.LiveStock .vcaLogin .loginFormBox h1::after {
    position: absolute;
    content: '';
    top: 50%;
    right: 35%;
    width: 15px;
    height: 1px;
    background: var(--primColor);
} */

.LiveStock .vcaLogin .loginFormBox h4 {
    font-size: 16px;
    font-weight: 400;
    color: #323749;
    margin-bottom: 35px;
    text-align: center;
}

.LiveStock .vcaLogin .loginFormBox .borderlessTextField {
    margin-bottom: 25px;
    max-width: 100%;
}

.LiveStock .vcaLogin .loginFormBox .fpRm .customCheckbox .MuiListItem-root {
    font-size: 15px;
    color: #000;
    margin-bottom: 0;
    padding: 0;
}

.LiveStock .vcaLogin .loginFormBox .loginCta {
    margin-top: 45px;
}

.LiveStock .vcaLogin .loginFormBox .loginCta p {
    font-size: 15px;
    margin-bottom: 4px;
    color: #000;
}
.LiveStock .vcaLogin .loginFormBox .loginCta a,
.LiveStock .vcaLogin .loginFormBox .fpRm a {
    font-size: 15px;
    color: var(--primColor);
    font-weight: 500;
    margin-top: 10px;
}

.LiveStock .vcaLogin .loginImgBox {
    background-image: url('../../../assets/images/auth-image.png');
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

.LiveStock .vcaLogin .loginImgBox img {
    border-radius: 5px;
    width: 145px;
    margin: 30px 30px 0 auto;
    height: 145px;
    object-fit: contain;
    background: #fff;
    padding: 0 10px;
    display: block;
}

.LiveStock .vcaLogin .loginImgBox p {
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .mw-200 {
        max-width: 200px;
        margin: 0 auto;
    }

    .LiveStock .vcaLogin .loginFormBox {
        display: block;
        height: auto;
        padding: 20px;
        z-index: 110;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    .LiveStock .vcaLogin {
        width: 100%;
        margin: 0;
        justify-content: center;
        align-content: center;
        min-height: 100vh;
    }

    .LiveStock .vcaLogin:after {
        /* background: rgba(0, 0, 0, 0.5) url(/image/loginImg.png) no-repeat; */
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        opacity: 0.75;
    }

    .LiveStock .vcaLogin .loginFormBox h1 {
        font-size: 28px;
        margin-bottom: 25px;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .LiveStock .vcaLogin .loginFormBox .fpRm .customCheckbox .MuiListItem-root,
    .LiveStock .vcaLogin .loginFormBox .loginCta a,
    .LiveStock .vcaLogin .loginFormBox .fpRm a,
    .regCnt .loginFormBox form .customCheckbox .MuiFormControlLabel-root a,
    .newAcc,
    .LiveStock .regCnt .loginFormBox form .MuiFormControlLabel-root .MuiButtonBase-root {
        font-size: 13px;
    }
    .LiveStock .vcaLogin .loginFormBox form,
    .LiveStock .regCnt .loginFormBox form {
        padding: 15px;
    }
    .LiveStock .regCnt .loginFormBox form .MuiFormControlLabel-root .MuiButtonBase-root,
    .LiveStock .vcaLogin .loginFormBox form .MuiFormControlLabel-root .MuiButtonBase-root {
        padding-left: 0;
    }
}

@media (max-width: 475px) {
    .LiveStock .vcaLogin .loginFormBox h1,
    .LiveStock .regCnt .loginFormBox h1 {
        font-size: 20px;
    }
}
