.customDialog .MuiPaper-root.MuiDialog-paper {
    min-width: 300px;
}

.Liquidation .customDialog .MuiPaper-root.MuiDialog-paper {
    min-height: fit-content;
}

.ArtAuction .customDialog .MuiPaper-root.MuiDialog-paper {
    min-height: fit-content;
}

.customDialog .MuiPaper-root.MuiDialog-paper .customInput,
.customDialog .MuiPaper-root.MuiDialog-paper .customSelect {
    margin-bottom: 15px;
    margin-top: 15px;
}

.customDialog .MuiDialogContent-root {
    padding-bottom: 20px;
    max-height: 650px;
    height: 100%;
    /* overflow-y: scroll; */
}

.customDialog .actionWrapper {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}

.customDialog .actionWrapper > button:first-child {
    margin-inline-end: 30px;
}

.customDialog .MuiDialogTitle-root {
    padding-bottom: 10px;
}

.customDialog .actionWrapper.secondaryActions {
    justify-content: center;
}

.customDialog .MuiDialogContent-root h5 {
    font-size: 18px;
}

.customDialog .modalCloseBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: unset;
    border-radius: 100px;
    width: 40px;
    height: 40px;
}
