.searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.searchContainer .searchLt {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.searchContainer .searchRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.MultiSeller_two .searchContainer .lotResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.MultiSeller_two .searchSaveCnt {
    background: #f6f6f6;
    padding: 10px 20px;
}

.MultiSeller_two .searchSaveCnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MultiSeller_two .searchSaveCnt h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-right: 15px;
    font-style: italic;
}

.MultiSeller_two .searchSaveCnt button {
    color: var(--primColor);
    border: 1px solid currentColor;
}

.MultiSeller_two .searchSaveCnt button .MuiButton-label {
    text-transform: initial;
}

.MultiSeller_two .searchSaveCnt button .material-icons {
    margin-right: 5px;
}

@media (max-width: 1145px) {
    .searchContainer .searchLt {
        max-width: 250px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }
    .searchContainer .searchLt {
        display: none;
    }

    .searchContainer .searchRt {
        width: 100%;
        max-width: none;
        min-height: 210px;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff;
        border-color: var(--primColor) !important;
    }
    .customBreadcrumbs ol {
        width: 100%;
    }
    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }
}

@media (max-width: 992px) {
    .MultiSeller_two .searchContainer .lotResults.Grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
    }
    .grid-card .grid-img-wrap img {
        height: 250px;
    }
    .MultiSeller_two .grid-card .grid-img-wrap img {
        height: 200px;
    }
    .searchContainer .searchRt {
        min-height: 280px;
    }
}

@media (max-width: 600px) {
    .MultiSeller_two .gridListToggle {
        display: none;
    }
}

@media (max-width: 475px) {
    .toggleRespDrawer.MuiButton-root {
        padding: 3px 10px;
    }
}

@media (max-width: 500px) {
    .MultiSeller_two .searchContainer .lotResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    .MultiSeller_two .timerWrapper .timeLabel::before {
        left: 10%;
    }
    .MultiSeller_two .timerWrapper .timeLabel::after {
        right: 10%;
    }
    .MultiSeller_two .card.grid-card .biddingCnt button .material-icons-outlined {
        display: none;
    }

    .MultiSeller_two .searchSaveCnt {
        padding: 10px;
        flex-wrap: wrap;
    }
    .MultiSeller_two .searchSaveCnt h5 {
        font-size: 15px;
        margin-bottom: 5px;
    }
    .MultiSeller_two .searchSaveCnt button {
        margin-top: 5px;
    }
}
