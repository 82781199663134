.postProductContainer {
    max-width: 930px;
    width: 100%;
    margin: 60px auto;
    padding: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
}

.postProductContainer .ppTitle {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 35px;
    width: 100%;
    text-align: center;
    color: #2e2e2e;
}
.Auctioneer .carPstWrpr .description .ck-editor__main p {
    min-height: 150px;
}
.Auctioneer .dates .react-datepicker__navigation--years-previous {
    background-image: url('/src/assets/images/slotArrow.png');
    height: 20px;
    width: 20px;
    background-size: cover;
    transform: rotate(180deg);
    margin: 10px auto;
}
.Auctioneer .dates .react-datepicker__navigation--years {
    background-image: url('/src/assets/images/slotArrow.png');
    height: 20px;
    width: 20px;
    background-size: cover;
    margin: 10px auto;
}
.dates {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.react-datepicker-popper {
    z-index: 10;
}
.dates .react-datepicker__input-container #start_date,
.dates .react-datepicker__input-container #end_date {
    height: 1.1876em;
    margin: 0;
    display: block;
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    width: 93%;
    padding: 18.5px 14px;
    border-radius: 4px;
    border: 1px solid grey;
}
.postProductContainer .ck-content.ck-editor__editable {
    min-height: 290px;
}

.postProductContainer .ck.ck-editor {
    margin-bottom: 25px;
}
.postProductContainer .MuiTabs-fixed {
    text-align: center;
}

.postProductContainer .MuiTabs-fixed .MuiTabs-flexContainer {
    border-bottom: none;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root {
    position: relative;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root.Mui-selected {
    background: #fff;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root.Mui-selected::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 50%;
    height: 3px;
    background: var(--primColor);
}

.postProductContainer .MuiTab-wrapper {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
}

.postProductContainer .MuiTabs-root {
    margin-bottom: 45px;
}

.errLst .errValu:last-child .cma {
    display: none;
}

@media (max-width: 600px) {
    .postProductContainer .primButton {
        margin-bottom: 20px;
    }
}
