.footer-main-standard {
    padding: 0;
    padding-top: 15px;
    margin-top: 15px;
    color: var(--footerFontColor);
    background: var(--footerColor);
    font-size: 15px;
}

.footer-main-standard a {
    color: var(--footerFontColor);
}

.footer-copy-right {
    padding: 10px 30px;
    background: var(--footerColor);
}

.footer-copy-right p {
    margin: 0;
}

a.cvc-link {
    font-size: 15px;
}

.footer-main-standard-title {
    margin-bottom: 15px;
    color: var(--primColor);
    font-size: 20px;
    position: relative;
    padding-bottom: 15px;
}

.footer-main-standard-newsletter {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
}

.newsletter-wrapper {
    position: relative;
}
.footer-main-standard-newsletter-input {
    width: 100%;
    border: none;
    border-radius: 45px;
    padding: 13px 26px;
    color: #4a4031;
    font-size: 16px;
    border: 1px solid #ddd;
}

.footer-main-standard-newsletter-submit {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    height: 52px;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    background: var(--primColor);
    color: #ffffff;
    border: none;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    padding: 0px 20px;
}

.footer-main-standard-newsletter-submit {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
    line-height: 16px;
}

.footer-main-standard-social-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-left: -7px;
}

.footer-main-standard-social-list > li {
    display: inline-block;
    margin: 5px 6px;
}

.footLogoCnt address {
    font-size: 14px;
    color: #474747;
    font-weight: 500;
    margin: 0;
}

.HeavyEquipment .footerLinks li {
    width: auto;
}

.HeavyEquipment .footerLinks li:not(:last-child) {
    margin-right: 60px;
}

.ftrDtlCntnr .footerMisc {
    max-width: 25%;
    align-content: space-between;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-main-standard-social-list > li > a {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: unset;
    color: var(--Black);
    opacity: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.footer-main-standard-social-list > li > a > svg {
    fill: #fff;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
}

.HeavyEquipment .ftrDtlCntnr {
    text-align: center;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    border-top: 1px solid transparent;
    padding-top: 35px;
    padding-bottom: 45px;
    margin-top: 0px;
}

.footer-main-standard-social-list {
    margin: 0;
    width: 100%;
    text-align: right;
}

.HeavyEquipment .ftrDtlCntnr .footerLogo {
    width: fit-content;
    max-width: fit-content;
    height: 70px;
    object-fit: contain;
    margin: auto;
    border-radius: 0px;
    margin-bottom: 15px;
}

.HeavyEquipment .footLogoCnt {
    text-align: left;
    max-width: 25%;
}

.ftrDtlCntnr .cvc-link {
    color: var(--footerFontColor);
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
}

.ftrDtlCntnr .cvc-link:hover {
    text-decoration: none;
}

.ftrDtlCntnr .cvc-link .material-icons {
    padding-right: 10px;
    color: var(--footerFontColor);
}

.ftrDtlCntnr .fa,
.ftrDtlCntnr .fab,
.ftrDtlCntnr .fal,
.ftrDtlCntnr .far,
.ftrDtlCntnr .fas {
    font-size: inherit;
    margin: 0 auto;
}

.HeavyEquipment .footerLinks {
    list-style: none;
    padding: 0;
    margin: 0px 0;
    /* display: flex; */
    /* line-height: 60px; */
    flex-wrap: wrap;
    justify-content: center;
}
.HeavyEquipment .footer-main-standard-social-list > li > a i {
    font-size: 24px;
}

.HeavyEquipment .footerLinks li:not(:last-child) {
    margin-left: 0;
    padding-bottom: 35px;
}

.HeavyEquipment footer .copyright {
    font-size: 12px;
    color: #6f6f6f;
    margin: 0;
}

.HeavyEquipment .footerLinks li a {
    color: inherit;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.HeavyEquipment .footerLinks li a:hover {
    color: var(--primColor);
}

.HeavyEquipment .footerLinks li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: var(--primColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.HeavyEquipment .footerLinks li a:hover:after {
    width: 100%;
    left: 0;
}

@media (max-width: 991px) {
    .footerLinks {
        display: none;
    }
}

@media (max-width: 600px) {
    .HeavyEquipment .ftrDtlCntnr {
        flex-wrap: wrap;
    }
    .ftrDtlCntnr .footerLogo {
        width: 100px;
    }
    .ftrDtlCntnr {
        margin-bottom: 20px !important;
    }
}
