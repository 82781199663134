.stcPgWrpr {
    padding: 90px;
}

.staticPgWrapper {
    min-height: calc(100vh - 274px);
}

/*about us page css*/
.CarAuction .abtSttcPg .topContr {
    background: url(../../../assets/images/blowfish.png);
    background-size: cover;
    padding-top: 80px;
    padding-bottom: 80px;
    color: white;
    margin-bottom: 80px;
}

.CarAuction .abtSttcPg .topContr p {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: bold;
}

.CarAuction .abtSttcPg .topContr h2 {
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 35px;
}

.CarAuction .abtSttcPg .topContr h5 {
    font-size: 26px;
}

.CarAuction .brdrTpDv {
    border-top: 1px dashed rgb(223, 223, 223);
}

.CarAuction .brdrBtmDv {
    border-bottom: 1px dashed rgb(223, 223, 223);
}

.CarAuction .abtSttcPg .hwBlFCntn h4 {
    font-size: 34px;
    font-weight: 800;
    color: var(--primColor);
    margin-bottom: 34px;
}

.CarAuction .abtSttcPg .hwBlFCntn p {
    margin-bottom: 15px;
    font-size: 15px;
    color: rgb(92, 92, 92);
    font-weight: 500;
}

.CarAuction .stcHdrCntnr h3 {
    color: var(--primColor);
    font-size: 22px;
    font-weight: 800;
    text-align: center;
}

.CarAuction .abtSttcPg .pdtGrd {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 35px 0;
}

.CarAuction .abtSttcPg .stpCard {
    display: flex;
    align-items: flex-start;
}

.CarAuction .abtSttcPg .stpCard .stpImgWrpr {
    max-width: 300px;
    width: 100%;
    height: 300px;
    background: whitesmoke;
}

.CarAuction .abtSttcPg .stpCard .stpImgWrpr img {
    width: 100%;
    height: 100%;
}

.CarAuction .abtSttcPg .stpCard .stpDtlsCls {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.CarAuction .abtSttcPg .stpCard .stpDtlsCls small {
    font-size: 13px;
}

.CarAuction .abtSttcPg .stpCard .stpDtlsCls h4 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.CarAuction .abtSttcPg .gtStrdWrpr h5 {
    color: var(--primColor);
    font-size: 22px;
    font-weight: 500;
}

.CarAuction .abtSttcPg .btnWrpr button {
    border: 1px solid var(--primColor);
    width: 350px;
    color: var(--primColor);
}
