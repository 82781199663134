/* basic styles */
.skeleton {
    background: #ddd;
    overflow: hidden;
    /* margin: 10px 0; */
    border-radius: 4px;
}
.skeleton.text {
    width: 100%;
    height: 12px;
}
.skeleton.title {
    width: 50%;
    height: 30px;
    /* margin: 5px 0; */
}

.skeleton.input {
    width: 100%;
    height: 40px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.skeleton.thumbnail {
    width: 100px;
    height: 100px;
}

.skeleton.banner {
    width: 100%;
    height: 300px;
}

.skeleton.search {
    width: 100%;
    height: 40px;
    padding: 0;
}

.skeleton.leftPanel {
    width: 100%;
    height: 500px;
}

.skeleton.gridImage {
    width: 100%;
    height: 180px;
}

.skeleton.actionButton {
    width: 100%;
    height: 45px;
}

.skeleton.smallButton {
    width: 100%;
    height: 30px;
}

.skeleton.box {
    width: 100%;
    height: 80px;
}

.skeletonWrapper {
    /* margin: 20px auto; */
    padding: 0px 15px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    height: auto;
    padding: 10px 15px;
    background: #ffffff;
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
}

/* animation effects */
.shimmerWrapper {
    position: absolute;
    /* parent must be relative position & overflow hidden */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
}
.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.2);
}

@keyframes loading {
    0% {
        transform: translateX(-150%);
    }
    50% {
        transform: translateX(-60%);
    }
    100% {
        transform: translateX(150%);
    }
}
