/* .vInvoice.container {
  width: 800px;
}

.wrapper {
  width: 100%;
  border: 1px solid #000;
  margin: 10px 0;
  padding: 10px
}
.vInvoiceRow {
  border-bottom: 1px solid #000;
}
.border-right {
  border-right: 1px solid #000;
}

.no-border {
  border-bottom: 0;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.vInvoice img {
  height: 30px;
}

.table.vInvoiceTable {
  width: 100%;
  margin: 0;
}
.table.vInvoiceTable .td {
  border: 1px solid #000;
  padding: 5px 0 5px 10px;
  width: 50%;
}

.table.vInvoiceTable .first {
  border-top: 0;
}

.table.vInvoiceTable .last {
  border-bottom: 0;
}

.vInvoice b {
  display: block;
}

.vInvoice span {
  font-size: 12px;
}

.vInvoice .invoice-bill {
  width: 100%;
}
.table.vInvoiceTable td {
  border: 1px solid #000;
  padding: 5px;
}

.vInvoice .serial-no {
  width: 70px;
}

.vInvoice .particulars {
  width: 500px;
}

.vInvoice .amount {
  width: 150px;
}

.vInvoice .particular-items {
  padding: 5px 10px;
}

.vInvoice .amount-items {
  padding: 5px 0;
}

.vInvoice .entry-id {
  margin: 35px 0;
}

.vInvoice .bank-details {
  margin-top: 30px;
}

.vInvoice .authorised-sign-wrapper {
  width: 300px;
  border: 1px solid #000;
  padding: 5px 20px;
} */

.vcInvoice {
    padding: 2rem !important;
}
.vcInvoice .invLogo {
    width: 150px;
}
.vcInvoice .right {
    text-align: right !important;
}
.vcInvTot span {
    font-weight: 500;
}

.invoiceCard {
    margin-bottom: 30px;
    border: none;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
}

.card-header {
    background-color: #fff;
    border-bottom: 1px solid #e6e6f2;
}

.invoiceCard h3 {
    font-size: 20px;
}
.invoiceTitle {
    font-size: 18px;
    margin: 35px 0 15px 0;
    font-weight: 600;
    color: #3a3a3a;
}
.vcInvoice .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(241, 241, 241);
}
.vcInvoice .invoiceCard .table thead th {
    font-weight: 500;
    font-size: 15px;
}
.vcInvoice .invoiceCard .table tbody td {
    font-weight: 400;
    font-size: 15px;
}
.invoiceCard h5 {
    font-size: 15px;
    line-height: 26px;
    color: #3d405c;
    margin: 0px 0px 15px 0px;
}

.text-dark {
    color: #3d405c !important;
}
.transDet .invDiv {
    /* width: 25%; */
    margin-bottom: 15px;
}
.transDet p {
    font-size: 15px;
    margin-bottom: 0px;
    color: #5f5f5f;
}
.transDet span {
    width: 100%;
    font-weight: 500;
}

@media (max-width: 767px) {
    .Collectibles .vcInvoice {
        padding: 15px !important;
    }

    .Collectibles .table-responsive.invoice-data table {
        min-width: 992px;
    }
}
