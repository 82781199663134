.CarAuction .regCnt {
    width: 100%;
    margin: 0;
    background: #efefef;
}

.CarAuction .regCnt > [class^='col-'] {
    padding: 0;
}

.CarAuction .regCnt .loginFormBox {
    margin: 0;
    padding: 0;
    width: 100%;
    border-radius: 12px;
    background: #fff;
    height: 100%;
    display: flex;
    align-items: center;
}

.CarAuction .regCnt .loginFormBox form {
    max-width: 750px;
    margin: 60px auto;
    border-radius: 10px;
    padding: 25px;
    width: 100%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.CarAuction .regCnt .loginFormBox form .primButton {
    width: 300px;
    margin: 0 auto;
}

.CarAuction .regCnt .nav-standard-logo {
    text-align: center;
    display: block;
}

.CarAuction .regCnt .nav-standard-logo img {
    width: 100%;
    height: 150px;
    object-fit: contain;
}

.CarAuction .regCnt .loginFormBox form .MuiFormControlLabel-root {
    margin: 0;
}

.CarAuction .regCnt .newAcc a {
    padding-left: 5px;
}

.CarAuction .regCnt .loginFormBox h1 {
    font-size: 28px;
    font-weight: 600;
    color: #323749;
    margin-top: 0px;
    margin-bottom: 15px;
    width: fit-content;
    margin: auto;
    text-align: center;
    position: relative;
}

.CarAuction .regCnt .loginFormBox h1::before {
    position: absolute;
    content: '';
    top: 50%;
    left: -20px;
    width: 15px;
    height: 1px;
    background: var(--primColor);
}

.CarAuction .regCnt .loginFormBox h1::after {
    position: absolute;
    content: '';
    top: 50%;
    right: -20px;
    width: 15px;
    height: 1px;
    background: var(--primColor);
}

.CarAuction .regCnt .loginFormBox h4 {
    font-size: 16px;
    font-weight: 400;
    color: #323749;
    margin-bottom: 35px;
    text-align: center;
}

.CarAuction .regCnt .loginFormBox .borderlessTextField {
    margin-bottom: 25px;
    max-width: 100%;
}

.CarAuction .regCnt .loginFormBox .fpRm .customCheckbox {
    font-size: 15px;
    color: #000;
    margin-bottom: 0;
}

.CarAuction .regCnt .loginFormBox .loginCta {
    margin-top: 45px;
}

.CarAuction .regCnt .loginFormBox .loginCta p {
    font-size: 15px;
    margin-bottom: 4px;
    color: #000;
}
.CarAuction .regCnt .loginFormBox .loginCta a,
.CarAuction .regCnt .loginFormBox .fpRm a {
    font-size: 15px;
    color: var(--primColor);
    font-weight: 500;
}

.CarAuction .regCnt .loginImgBox {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

.CarAuction .regCnt .loginImgBox img {
    border-radius: 5px;
    width: 145px;
    margin: 30px 30px 0 auto;
    height: 145px;
    object-fit: contain;
    background: #fff;
    padding: 0 10px;
    display: block;
}

.CarAuction .regCnt .loginImgBox p {
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
}

.CarAuction .regCnt .termsCheck .customCheckbox {
    margin: 0 0 20px;
}

.CarAuction .regCnt .termsCheck .MuiFormGroup-root {
    justify-content: center;
    margin: 0;
}

.CarAuction .regCnt .termsCheck a {
    padding-left: 8px;
    display: inline-block;
}

@media (max-width: 991px) {
    .mw-200 {
        max-width: 200px;
        margin: 0 auto;
    }

    .CarAuction .regCnt .loginFormBox {
        display: block;
        height: auto;
        padding: 20px;
        z-index: 110;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    .CarAuction .regCnt {
        width: 100%;
        margin: 0;
        justify-content: center;
        align-content: center;
    }

    .CarAuction .regCnt:after {
        /* background: rgba(0, 0, 0, 0.5) url(/image/loginImg.png) no-repeat; */
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        opacity: 0.75;
    }

    .CarAuction .regCnt .loginFormBox h1 {
        font-size: 28px;
        margin-bottom: 25px;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .CarAuction .regCnt .loginFormBox form .primButton {
        width: auto;
        margin: 0 auto;
        max-width: 300px;
    }
    .CarAuction .regCnt .loginFormBox form {
        margin: 30px auto;
    }
}
