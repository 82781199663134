.CarAuction .mainHeader {
    height: 100%;
    /* position: relative; */
    top: 0;
    z-index: 10;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
    position: sticky;
    top: 0;
}

.CarAuction .mainHeader.homeHeader {
    top: 0;
    z-index: 10;
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    overflow: hidden;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110px;
    background: linear-gradient(1turn, transparent, rgba(0, 0, 0, 0.36) 29%, rgba(0, 0, 0, 0.73));
}

.CarAuction .mainHeader .nav-standard-logo img {
    width: 100%;
    max-width: 370px;
    height: 80px;
    object-fit: contain;
}

.CarAuction .mainHeader ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0px;
}

.CarAuction .mainHeader ul .MuiButton-root,
.CarAuction .mainHeader ul a {
    color: var(--headerFontColor) !important;
    font-size: 15px;
    font-weight: 600;
}

.CarAuction a {
    color: var(--primColor);
}

.CarAuction .mainHeader ul.lftNv {
    justify-content: flex-end;
}

.CarAuction .mainHeader ul.lftNv li:not(:first-child) {
    margin-left: 40px;
}

.CarAuction .mainHeader ul.rtNv {
    justify-content: flex-start;
}

.CarAuction .mainHeader ul.rtNv li:not(:last-child) {
    margin-right: 40px;
}

.CarAuction .mainHeader ul button .MuiButton-label {
    text-transform: initial;
}

.CarAuction .nocardBanner.hmBnr {
    background: #000000bf;
    padding: 10px 20px;
    position: absolute;
    width: 100%;
    top: 110px;
    left: 0;
    z-index: 99;
}

.CarAuction .nocardBanner.hmBnr p {
    font-size: 16px;
}

.CarAuction .headerIcons .usrCrcl {
    background: whitesmoke;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-right: 15px;
    font-size: 14px;
    color: var(--primColor);
}

.CarAuction .headerIcons .usrNm {
    text-transform: capitalize;
    text-align: left;
    margin-right: 3px;
}

.CarAuction .MuiDrawer-paperAnchorTop {
    max-height: 100px;
    height: 100%;
    background: var(--primColor);
}

.CarAuction .respHeaderSearch .nav-standard-top-area-search {
    height: 100%;
}

.CarAuction .respHeaderSearch .nav-standard-top-area-search-inner {
    display: flex;
    align-items: center;
    height: 100%;
}

.CarAuction .respHeaderSearch .nav-standard-top-area-search-inner .hdInptWrpr {
    width: 100%;
    height: 100%;
}

.CarAuction .respHeaderSearch .nav-standard-top-area-search-inner input {
    height: 100%;
    width: 100%;
    padding: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: white;
}

.CarAuction .respHeaderSearch .nav-standard-top-area-search-inner input::placeholder {
    font-size: 24px;
    color: white;
    opacity: 0.2;
    font-weight: 500;
}

.CarAuction .respHeaderSearch .nav-standard-top-area-search-inner button {
    width: 100px;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.CarAuction .respHeaderSearch .nav-standard-top-area-search-inner button .material-icons {
    font-size: 40px;
}

.CarAuction .fullScreenPopup.slctTypePopup .MuiDialog-paperFullScreen {
    background: url(../../../assets/images/carback.jpg);
    background-size: cover;
    background-position: center;
}

.CarAuction .fullScreenPopup.slctTypePopup.bikeBack .MuiDialog-paperFullScreen {
    background: url(../../../assets/images/bikeback.jpg);
    background-size: cover;
    background-position: center;
}

.CarAuction .fullScreenPopup.slctTypePopup .MuiAppBar-colorPrimary {
    background-color: transparent !important;
    box-shadow: none;
}

.CarAuction .fullScreenPopup.slctTypePopup .postTypePop {
    max-width: 600px;
    width: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    backdrop-filter: blur(51px) brightness(1.5);
    border-radius: 10px;
    padding: 30px;
}

.CarAuction .fullScreenPopup.slctTypePopup .postTypePop h2 {
    color: white;
}

.CarAuction .fullScreenPopup.slctTypePopup .postTypePop .vehicleType {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 150px;
    margin-bottom: 30px;
}

.CarAuction .fullScreenPopup.slctTypePopup .postTypePop .vehicleType .vhclCard {
    background: white;
    border-radius: 10px;
    padding: 20px 40px;
    position: relative;
    width: 100%;
    cursor: pointer;
}

.CarAuction .fullScreenPopup.slctTypePopup .postTypePop .vehicleType .vhclCard.active {
    background: var(--primColor);
    color: white;
}

.CarAuction .fullScreenPopup.slctTypePopup .postTypePop .vehicleType .vhclCard img {
    width: 220px;
    position: absolute;
    bottom: 58px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 120px;
    object-fit: contain;
    object-position: bottom;
}

.CarAuction .fullScreenPopup.slctTypePopup .postTypePop .vehicleType .vhclCard p {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

.CarAuction .fullScreenPopup.slctTypePopup .modalChildren {
    padding: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CarAuction .mobBtNvgtn {
    width: 100%;
    height: 50px;
    bottom: 0;
    position: fixed;
    background: white;
    z-index: 999;
    box-shadow: 0 -5px 12px #00000026;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.CarAuction .mobBtNvgtn.noAuthNav {
    grid-template-columns: 1fr 1fr;
    padding: 5px;
}

.CarAuction .mobBtNvgtn .icnWrpr {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CarAuction .mobBtNvgtn .icnWrpr button {
    height: 100%;
    width: 50px;
    min-width: unset;
    border-radius: 10px;
    transition: ease-in-out 0.2s;
    transform: translateY(0);
}

.CarAuction .mobBtNvgtn a {
    color: black;
}

.CarAuction .mobBtNvgtn .icnWrpr button.active {
    background: var(--primColor);
    color: white;
    border-radius: 100px;
    /* transform: translateY(-15px); */
    border: 5px solid white;
    position: absolute;
    top: -22px;
    height: 60px;
    width: 60px;
}

.CarAuction .mobBtNvgtn .icnWrpr button.active a {
    color: white;
}

.CarAuction .headRt.respNav .naLogoHead {
    background: var(--headerColor);
}

.CarAuction .headRt.respNav .naLogoHead img {
    width: 100%;
    height: max-content;
    max-height: 100px;
    min-height: 70px;
}

.CarAuction .headRt.respNav .headDrawerClose {
    color: var(--headerFontColor);
}

.CarAuction .headRt.respNav h4.sdDvdrHdr {
    font-size: 15px;
    padding-left: 16px;
    color: var(--secColor);
}

.CarAuction .mobBtNvgtn.noAuthNav .icnWrpr button.active {
    background: var(--primColor);
    color: white;
    border-radius: 0px;
    border: none;
    position: relative;
    top: unset;
    height: 40px;
    width: 100%;
}

.CarAuction .mobBtNvgtn.noAuthNav button {
    background: rgb(236, 236, 236);
    width: 100%;
    border-radius: 0px;
}

.CarAuction .mobBtNvgtn.noAuthNav .lgnBtn button {
    background: var(--secColor);
    color: white;
}

@media (max-width: 1200px) {
    .CarAuction .app-wrapper {
        padding-bottom: 50px;
    }

    .CarAuction .respNav {
        display: block !important;
    }
    .CarAuction .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: var(--headerFontColor) !important;
    }

    .CarAuction .deskNav {
        display: none !important;
    }

    .CarAuction .headRt ul {
        list-style: none;
        margin: 0;
    }

    .CarAuction .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .CarAuction .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-family: var(--FuturaStdMedium);

        /* justify-content: space-between; */
    }

    .CarAuction .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .CarAuction .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }

    .CarAuction header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .CarAuction .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-family: var(--FuturaStdMedium);
        flex-wrap: wrap;
    }

    .CarAuction .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .CarAuction .headRt.respNav .navRespLinks a.active,
    .CarAuction .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .CarAuction .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
        background: #fff;
    }

    .CarAuction .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .CarAuction .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .CarAuction .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .CarAuction .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .CarAuction .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    .CarAuction .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .CarAuction .navCategories .navBack {
        color: #8a8a8a;
    }

    .CarAuction .navCategories .MuiListItem-button {
        width: 100%;
    }
    .CarAuction .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }
}

@media (max-width: 600px) {
    .CarAuction .fullScreenPopup.slctTypePopup .postTypePop .vehicleType {
        grid-template-columns: 1fr;
        row-gap: 130px;
    }

    .CarAuction .fullScreenPopup.slctTypePopup .postTypePop {
        backdrop-filter: blur(51px) brightness(0.7);
    }
}
