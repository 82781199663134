.subCard {
    display: flex;
    justify-content: center;
}
.liquidationSubCard {
    max-width: 300px;
    width: 100%;
}
.liquidationSubCard .grid-card {
    box-shadow: 0px 3px 6px #d3d3d3;
    border-radius: 4px;
    padding: 0px 10px 10px 10px;
}
.liquidationSubCard .grid-card .card-body .premium {
    position: relative;
    text-align: center;
}
.thisc {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.yesButton {
    background: #f78d2f;
    border: none;
    padding: 6px 20px;
    border-radius: 30px;
}
.yesButton a {
    color: #fff;
    text-decoration: none;
}
.liquidationSubCard .grid-card .card-body .premium::after {
    width: 83px;
    opacity: 1;
    visibility: visible;
    height: 2px;
    background-color: #f78d2f;
    position: absolute;
    content: '';
    bottom: -6px;
    right: 13%;
    transform: translateX(-50%);
}
.later {
    color: #f78d2f;
}
.fonting {
    font-size: 16px;
    font-weight: 600;
}
.liquidationSubCard .grid-card .grid-img-wrap img {
    height: 53px;
    width: 46px;
    border-radius: 0px;
    float: right;
    margin-right: 10px;
}
.amount b {
    color: grey;
}
.amnt {
    color: #f78d2f;
}
.amnt span {
    color: grey;
    font-size: 16px;
}
.paycard {
    border-radius: 30px;
    border: 1px solid #f78d2f;
    padding: 5px 10px;
    color: #f78d2f;
    margin-left: 10px;
}
.make_payment {
    border-radius: 30px;
    border: 1px solid #000;
    padding: 5px 10px;
    color: #000;
    margin-top: 10px;
}
.dueOn {
    padding: 4px 15px;
    background-color: #000;
    border-radius: 30px;
    color: #fff;
    border: none;
}
