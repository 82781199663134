.schedule,
.chooseDay,
.pickupSummary,
.pickupConfirmed {
    min-height: 100vh;
    background-color: #f1f1f1;
    padding: 30px 0px;
}
.schedule .schedulePop,
.chooseDay .chooseDayPop,
.pickupSummary .pickupSummaryPop,
.pickupConfirmed .pickupConfirmedPop {
    padding: 30px 0px;
    background-color: #fff;
}
.pickupConfirmed .pickupConfirmedPop {
    padding: 30px 50px;
}
.schedule .schedulePop a,
.pickupConfirmed .pickupConfirmedPop a {
    color: var(--primColor) !important;
    text-decoration: underline !important;
}
.chooseDay .chooseDayPop .date,
.pickupSummaryPop .date {
    background-color: var(--primColor);
    padding: 20px;
}
/* .map
{
    background-color: #E8E8E8;
} */
.chooseDay .chooseBtn {
    background: var(--primColor) !important;
}
.pickupSummary .pickupSummaryPop .date .calendar {
    max-width: 700px;
    margin: 20px auto 10px;
}
.pickupSummary .picking,
.pickupConfirmed .picking {
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
}
.pickupSummary .pickupConfirmedPop .Dropdown-control {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 18px;
}
.pickupSummary .pickupConfirmedPop .Dropdown-placeholder {
    text-align: left;
}
.pickupSummary .times a {
    color: var(--primColor) !important;
    text-decoration: underline !important;
}
.pickupConfirmed .paySummary .estimated {
    min-width: 225px;
    width: 100%;
}
.pickupConfirmed .paySummary .totaly {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}
.pickupConfirmed .paySummary .total {
    font-size: 18px;
    font-weight: 600;
    min-width: 220px;
    width: 100%;
    margin-top: 10px;
}
.pickupConfirmed .pickupSummaryPop .date .calendar h5 {
    margin-bottom: 30px;
}
.pickupConfirmed .pickupSummaryPop .date .calendar a {
    text-decoration: underline;
}
.chooseDay .chooseBtn:hover {
    background: var(--primColor) !important;
    color: #fff !important;
}
.chooseDay .pagin div {
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: flex;
    margin: 0px 5px;
}
.pickupSummary .date .calendar,
.pickupSummary .times,
.chooseDay .date .calendar {
    max-width: 600px;
    width: 100%;
    margin: auto;
    color: #fff;
}
.pickupSummary .times {
    color: #000;
    margin: 20px auto;
}
.chooseDay .pagin div span {
    color: #fff;
}
.chooseDay .scheduledates {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chooseDay .schedulerMain .MuiFormGroup-root {
    flex-direction: row !important;
}
.chooseDay .schedulerMain .MuiFormControlLabel-root.selected .dayPreview {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
}
.chooseDay .schedulerMain .MuiFormControlLabel-root .dayPreview span:nth-child(2) {
    font-size: 20px;
    font-weight: 600;
}
.chooseDay .calendar .scheduler .schedulerActions button {
    border-radius: 50%;
    min-width: 40px;
    margin: 0px 10px;
}
.chooseDay .calendar .scheduler .schedulerActions button:first-child {
    border: 2px solid #cdcdcd;
    color: #cdcdcd;
}
.chooseDay .calendar .scheduler .stTitle {
    text-align: left;
}
.chooseDay .calendar .scheduler .schedulerActions button:last-child {
    border: 2px solid #fff;
    color: #fff;
}
.chooseDay .schedulerMain .MuiFormControlLabel-root .dayPreview {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 10px 15px;
    border-radius: 15px;
    display: flex;
    margin: 0px 8px;
    flex-direction: column;
}
.chooseDay .schedulerMain .schedulerDate .MuiFormGroup-root .MuiButtonBase-root {
    display: none;
}
.chooseDay .schedulerMain .timeUnits .MuiFormGroup-root .MuiSvgIcon-root {
    height: 30px;
    width: 30px;
}
.chooseDay .schedulerMain .timeUnits .MuiFormGroup-root {
    justify-content: center;
}
.chooseDay .calendar .scheduler .schedulerMain .schedulerTime .stTitle {
    margin: 30px 0px 20px;
}
.chooseDay .schedulerMain .timeUnits .MuiFormGroup-root .MuiFormControlLabel-root {
    background-color: white;
    color: black;
    padding: 3px 30px;
    border-radius: 9px;
    margin: 9px;
    max-width: 175px;
    width: 100%;
}
.chooseDay .scheduledates .second {
    background-color: transparent;
    color: #fff;
}
.chooseDay
    .schedulerMain
    .timeUnits
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiFormControlLabel-label {
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
}
.chooseDay .schedulerMain .timeUnits .MuiFormGroup-root .MuiRadio-root {
    padding-left: 0px !important;
}
.chooseDay .scheduledates .third {
    background-color: #eaeaea7d;
    color: #fff;
    border: 2px solid #eaeaea7d;
}
.chooseDay .scheduledates p:last-child {
    margin-bottom: 0px;
}
/* .timeOne:first-child
{
    margin: 10px 20px 10px 0px;
}
.timeOne:last-child
{
    margin: 10px 00px 10px 20px;
}
.timeOne
{
    display: flex;
    align-items: center;
    border: 2px solid #e3e3e3;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
}
.timeOne p
{
    margin-bottom: 0px;
    margin-left: 20px;
}
.timeOne input[type=radio]
{
    height: 20px;
    width: 20px;
} */
/* .timings
{
    display: flex;
    align-items: center;
    justify-content: space-between;
} */
@media (max-width: 768px) {
    .pickupConfirmed .pickupConfirmedPop {
        padding: 30px;
    }
    .pickupConfirmed .pickupConfirmedPop h2,
    .pickupSummary .pickupSummaryPop h2,
    .schedule .schedulePop h2,
    .chooseDay .chooseDayPop h2 {
        font-size: 22px;
    }
    .map {
        margin-top: 20px;
    }
    .chooseDay .calendar h5,
    .pickupSummary .calendar h5 {
        font-size: 18px;
    }
    .chooseDay .calendar h4,
    .pickupSummary .calendar h4 {
        font-size: 16px;
        margin-bottom: 15px;
    }
}
