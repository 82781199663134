.donationModal .modal-body.cm-body {
    padding: 0;
    position: relative;
    max-height: 100%;
}

.donationModal .rtBack {
    background-size: cover;
    min-height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 0;
    border-radius: 0 5px 5px 0;
    background-position: center;
}

.donationModal .rtBack .txtContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    backdrop-filter: blur(30px) brightness(1.3);
    width: 100%;
}

.donationModal .closeButton {
    position: absolute;
    z-index: 999;
    right: 0;
    color: white;
}

.donationModal .modal-header {
    display: none;
}

.donationModal .sbtnWrpr .primButton {
    margin-left: auto;
}

.donationModal .sbtnWrpr .primButton button,
.donationModal .sbtnWrpr .primButton {
    width: max-content;
}

.donationModal .radContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
}

.donationModal .regType label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    border: 1px solid #b7b7b7;
    background: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    padding-left: 36px;
}

.donationModal .regType label img {
    margin-right: 20px;
}

.donationModal .regType h5 {
    font-size: 14px;
    font-weight: 600;
}

.donationModal .regType label,
.donationModal .regType h5 {
    margin-bottom: 0;
}

.donationModal .regType .radioIcon {
    width: 20px;
    height: 20px;
    border: 2px solid var(--primColor);
    border-radius: 30px;
    position: absolute;
    left: 10px;
}

.donationModal .regType .radioIcon::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background: var(--primColor);
    position: absolute;
    top: 3px;
    right: 3px;
    transform: scale(0);
    transition: ease-in-out 0.3s;
}
.donationModal .regType input:checked + label .radioIcon::after {
    transform: scale(1);
}

.donationModal .regType input:checked + label {
    color: var(--primColor);
}

.donationModal .custom-modal .modal-dialog.modal-lg {
    max-width: unset;
    margin: 0 auto;
    width: 90vw;
    height: 90vh;
}

.donationModal .custom-modal .modal-dialog.modal-lg .lftDnrCntnr {
    height: 90vh;
    overflow: auto;
    padding-left: 20px;
    padding-right: 20px;
}
