.searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.LiveStock .siteBanner {
    width: 100%;
    height: 550px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.LiveStock .siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.LiveStock .siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.LiveStock .siteBanner .bannerContent {
    position: absolute;
    top: 50px;
    left: 60px;
}

.LiveStock .siteBanner .bannerContent h2 {
    font-size: 34px;
    margin-bottom: 20px;
    color: var(--bannerTextColor);
    font-weight: 500;
    letter-spacing: 1px;
    max-width: 500px;
    width: 100%;
    line-height: 50px;
}

.LiveStock .siteBanner .bannerContent p {
    font-size: 15px;
    margin-bottom: 45px;
    color: #333;
    width: 100%;
    max-width: 600px;
}

.LiveStock .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.LiveStock .searchContainer {
    flex-wrap: wrap;
}

.LiveStock .searchContainer .searchRt {
    width: 100%;
    max-width: 100%;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.LiveStock .searchContainer .lotResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.LiveStock .card.buynow .gridItemAct,
.LiveStock .unauth .card.buynow .timer-text .primButton {
    margin-top: 32px;
}

.LiveStock .liquidationBuyCard.auth .mslMisc .biddingCnt button {
    border-radius: 4px !important;
    margin-left: 5px !important;
}

.LiveStock .homeFilter {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    padding: 30px 25px;
    position: relative;
    /* margin-top: -150px; */
}

.homeFilter {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;
}

.homeFilter .hfTitle,
.homeFilter .radioTitle {
    font-size: 16px;
    font-weight: 500;
}

.LiveStock .homeFilter .filterPanel .fpTitle {
    display: none;
}

.LiveStock .homeFilter .nav-standard-top-area-search-inner {
    border: 1px solid #ccc;
}

.customRadio .MuiIconButton-root {
    display: none;
}
.customRadio .MuiFormGroup-root {
    display: flex;
}
.customRadio .MuiFormControlLabel-root {
    margin: 0;
}

.customRadio .MuiFormControlLabel-root .MuiFormControlLabel-label {
    height: 50px;
    width: 100%;
    margin-bottom: 15px !important;
    margin-right: 10px;
    margin-left: 0;
    border: 1px dashed #cfcfcf;
    border-radius: 3px;
    padding: 8px 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.customRadio .MuiFormControlLabel-root .MuiRadio-root.Mui-checked ~ .MuiFormControlLabel-label {
    background: var(--primColor);
    color: #fff;
    border: 2px solid var(--primColor);
}

@media (max-width: 1145px) {
    .searchContainer .searchLt {
        max-width: 250px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }
    .searchContainer .searchLt {
        display: none;
    }

    .searchContainer .searchRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff;
        border-color: var(--primColor) !important;
    }
    .customBreadcrumbs ol {
        width: 100%;
    }
    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }
}

@media (max-width: 992px) {
    .LiveStock .searchContainer .lotResults.Grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
    }
    .grid-card .grid-img-wrap img {
        height: 250px;
    }
    .LiveStock .grid-card .grid-img-wrap img {
        height: 200px;
    }
}

@media (max-width: 750px) {
    .LiveStock .siteBanner {
        height: 140px;
    }

    .LiveStock .homeFilter h3 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
        color: var(--primColor);
    }
}

@media (max-width: 600px) {
    .LiveStock .gridListToggle {
        display: none;
    }
}

@media (max-width: 475px) {
    .toggleRespDrawer.MuiButton-root {
        padding: 3px 10px;
    }
}

@media (max-width: 500px) {
    .LiveStock .searchContainer .lotResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    .LiveStock .timerWrapper .timeLabel::before {
        left: 10%;
    }
    .LiveStock .timerWrapper .timeLabel::after {
        right: 10%;
    }
    .LiveStock .card.grid-card .biddingCnt button .material-icons-outlined {
        display: none;
    }
}
