.Marine .footer-main-standard {
    padding-top: 60px;
    padding-bottom: 30px;
    position: relative;
}

.Marine .footerInner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    justify-content: space-between;
}

.Marine .footerInner ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Marine .footerInner ul h6 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
}

.Marine .footerInner ul li a {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
}

.Marine footer .footSocialLinks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    width: max-content;
}

.Marine footer .footSocialLinks a i {
    font-size: 24px;
}

.Marine footer .copyright {
    margin: 20px 0 -30px;
    background: #000;
    padding: 10px;
    color: #b6b6b6;
}

@media (max-width: 767px) {
    .Marine .footerInner {
        display: flex;
        flex-wrap: wrap;
    }

    .Marine .footerInner ul {
        width: 100%;
    }
}
