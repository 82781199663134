.mw-900 {
    max-width: 900px;
}

.mp-stitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    color: #000;
    margin-bottom: 25px;
}

.profile-form-wrap {
    position: relative;
}

.ArtAuction .profile-form-wrap.myProfile .primButton button {
    background-color: #000000;
    border: 1px solid #000000;
}

.ArtAuction .stripeInfo.active {
    border-radius: 0;
}

.profile-form-wrap .vwBioBtn {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 180px;
}

.ArtAuction .profile-form-wrap .vwBioBtn {
    width: 200px;
}

.profile-form-wrap .bda-input {
    position: relative;
    margin-bottom: 35px;
}

.stripeInfo {
    background: #eee;
    padding: 15px;
    border-radius: 10px;
    margin: 10px 0;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    font-weight: 600;
}

.stripeInfo .material-icons {
    margin-right: 10px;
}

.stripeInfo.active {
    background: rgb(8 172 90 / 16%);
    color: #017539;
    text-align: center;
    justify-content: center;
}

.stripeInfo.partial {
    background: rgb(255 152 0 / 18%);
    color: #a76400;
}

.stripeInfo.inactive {
    background: rgb(244 67 54 / 11%);
    color: #b92318;
}

.strpStup .accChild {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /* border: 1px solid #dadada; */
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #f1fff4;
    box-shadow: 0 0 10px #0000001a;
}

.strpStup h4 {
    font-size: 21px;
    margin-bottom: 15px;
}

.ppic-wrap {
    position: relative;
    width: 152px;
    height: 152px;
    margin: 0 auto 50px;
}

.ppic-wrap .pp-img {
    width: 152px;
    height: 152px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
}

.pp-edit {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background: var(--primColor);
    color: #fff;
    border: 1px solid var(--primColor);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: block;
    position: absolute;
    text-align: center;
    padding: 5px;
    right: 19px;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

.ArtAuction .pp-edit {
    background: #000000;
    border: 1px solid #000000;
}

.pb12 {
    padding-bottom: 12px;
}
.Marine .profile-form-wrap .reg-step1 .thisInput .dropzone {
    background-color: #fff;
    border-radius: 4px;
}

@media (max-width: 750px) {
    .strpStup .accChild {
        flex-wrap: wrap;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .profile-form-wrap .vwBioBtn {
        position: relative;
        top: unset;
        right: unset;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 570px) {
    .MultiSeller_two .strpStup .accChild button {
        height: auto;
    }
}
