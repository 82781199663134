.searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.searchContainer .searchLt {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.searchContainer .searchRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.HeavyEquipment .searchContainer .lotResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

/* HeavyEquipment */
.HeavyEquipment .searchContainer .searchLt {
    max-width: 258px;
    margin-right: 12px;
}
.HeavyEquipment .searchContainer .searchRt {
    margin-bottom: 45px;
}

.HeavyEquipment .MuiPagination-root {
    margin: 45px 0;
    text-align: right;
}

.HeavyEquipment .MuiPagination-root .MuiPagination-ul {
    justify-content: flex-end;
}

@media (max-width: 1145px) {
    .searchContainer .searchLt {
        max-width: 250px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
        margin-bottom: 15px;
    }
    .searchContainer .searchLt {
        display: none;
    }

    .searchContainer .searchRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .HeavyEquipment
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .MuiFormGroup-root.MuiFormGroup-row,
    .HeavyEquipment
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .RadioBox
        .MuiFormControlLabel-root {
        width: 100%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff;
        border-color: var(--primColor) !important;
    }
    .customBreadcrumbs ol {
        width: 100%;
    }
    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }

    .HeavyEquipment .responsiveFilterDrawer .filterPanel .expIconBtn {
        position: absolute;
        top: -67px;
        z-index: 999;
        left: 43%;
        background: var(--primColor);
        opacity: 1;
        color: white;
    }

    .HeavyEquipment .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding-top: 55px;
    }
}

@media (max-width: 475px) {
    .toggleRespDrawer.MuiButton-root {
        padding: 3px 10px;
    }
}
