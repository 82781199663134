.pvStandaloneTwo .mpTop {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 5px;
}

.pvStandaloneTwo .plTitle span {
    display: block;
}

.pvStandaloneTwo .pvsRight .timerWrapper .timeLabel {
    text-align: left;
}

.pvStandaloneTwo .pvsRight .timerWrapper .timeLabel::before,
.pvStandaloneTwo .pvsRight .timerWrapper .timeLabel::after {
    display: none;
}

.pvStandaloneTwo .pvsRight .timerWrapper {
    width: max-content;
    min-width: 190px;
    border: 1px solid var(--primColor);
    padding: 5px 10px;
    margin-bottom: 15px;
}

.pvStandaloneTwo .pvsRight .multiSellerTimerView {
    justify-content: flex-start;
}

.pvStandaloneTwo .multiSellerTimerView {
    margin-bottom: 0;
}

.pvStandaloneTwo .plTitle span:first-child {
    font-size: 32px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 5px;
}

.pvStandaloneTwo .plTitle span:last-child {
    font-size: 16px;
    color: #747474;
    margin-bottom: 8px;
}

.pvStandaloneTwo .image-gallery {
    width: 100%;
}

.pvStandaloneTwo .image-gallery-thumbnails-container {
    text-align: start;
}

/* .pvStandaloneTwo .image-gallery-thumbnails {
    width: 75px;
} */

.pvStandaloneTwo .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}
/* 
.pvStandaloneTwo .image-gallery-slide-wrapper.left {
    width: 100%;
} */

.pvStandaloneTwo .image-gallery-icon:hover {
    color: var(--primColor);
}

.pvStandaloneTwo .image-gallery-thumbnail {
    border: 2px solid transparent;
    width: 75px;
}

.pvStandaloneTwo .image-gallery-thumbnail.active,
.pvStandaloneTwo .image-gallery-thumbnail:hover,
.pvStandaloneTwo .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 0px;
}

.pvStandaloneTwo .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 60px;
    object-fit: cover;
    border-radius: 0px;
}

.pvStandaloneTwo .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 350px;
    min-height: 300px;
    cursor: initial;
}

.pvStandaloneTwo .image-gallery-content .image-gallery-slide > div {
    background: #efefef;
}

.pvStandaloneTwo .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}

.pvStandaloneTwo .productInfo h5 {
    font-size: 18px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 25px;
}

.pvStandaloneTwo .productInfo h5 span {
    font-size: 15px;
    font-weight: 500;
    width: 200px;
    display: inline-block;
}

.pvStandaloneTwo .pvsCnt {
    display: grid;
    grid-template-columns: 600px auto;
    gap: 20px;
}

.pvStandaloneTwo .sl-pbids.slidView {
    width: auto;
    margin-top: 0;
    margin-left: auto;
}

.pvStandaloneTwo .biddingCnt .customInput {
    margin-bottom: 15px;
}

.pvStandaloneTwo form:last-child .biddingCnt .customInput {
    margin-bottom: 0px;
}

.pvStandaloneTwo .mssBidItem {
    display: flex;
}

.pvStandaloneTwo .mssBidHistory {
    /* float: right; */
    height: 45px;
    width: auto;
    text-transform: initial;
    color: var(--primColor);
}

.pvStandaloneTwo .mssBidHistory .material-icons {
    margin-right: 10px;
}

.pvsTwoBottom {
    margin-top: 45px;
}

.pvsTwoBottom .pvsbInfo {
    margin-bottom: 25px;
}

.pvsTwoBottom .pvsbInfo h4 {
    text-align: left;
    color: #333;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}

.pvsTwoBottom .pvsbInfo h5 {
    color: #333;
    font-size: 16px;
    text-align: left;
    margin: 0;
    display: grid;
    grid-template-columns: 35% 65%;
    gap: 15px;
    align-items: center;
}

/* .pvsTwoBottom .pvsbInfo h5:not(:last-child) {
    margin-bottom: 0;
} */

.pvsTwoBottom .pvsbInfo h5 span {
    color: #505050;
    display: block;
    padding: 15px 10px;
    background: whitesmoke;
    height: 100%;
}

.downloadDoc {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 15px;
    width: 100%;
}

.downloadDoc .docView {
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    background: whitesmoke;
}

.downloadDoc .docView img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 45px;
    height: 45px;
    object-fit: contain;
}

.downloadDoc .docView h6 {
    margin-bottom: 0px;
}

.downloadDoc .docView a {
    font-weight: 600;
    color: var(--primColor);
    font-size: 12px;
}

.vdoHdTx {
    text-align: left;
    color: #333;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}

.hrsVdoWrpr {
    margin-bottom: 15px;
}

.hrsVdoWrpr > div {
    width: 100% !important;
    height: 332px !important;
    border-radius: 10px;
    overflow: hidden;
}

.hrsVdoWrpr.mxHt > div {
    height: 540px !important;
}

.pvsTwoBottom .pvsbInfo h5 span.emlWrdBrk {
    word-break: break-all;
    background: transparent;
    padding: 0;
    height: unset;
}

.descCntnrValu ul,
.descCntnrValu ol,
.descCntnrValu dl {
    padding-left: 20px;
}

.descCntnrValu figure,
.descCntnrValu figure > * {
    width: 100%;
}

.adtnlAccordion .MuiAccordion-rounded {
    box-shadow: none;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    margin-bottom: 10px;
}

.adtnlAccordion .MuiAccordion-root:before {
    content: none;
}

.shrLable {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.shrLable .material-icons {
    font-size: 14px;
    width: 30px;
    height: 30px;
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-right: 5px;
}

.pvStandaloneTwo .closedText {
    background: #ffdada;
    width: 100%;
    padding: 10px;
    display: block;
    margin-bottom: 15px;
    text-align: center;
    border-radius: 10px;
    color: #b53200;
    font-size: 18px;
    font-weight: 600;
    border: 1px dashed #b53200;
}

@media (max-width: 750px) {
    .LiveStock .pvStandaloneTwo .pvsCnt {
        grid-template-columns: 100%;
    }

    .LiveStock .pvStandaloneTwo .plTitle span:first-child {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .LiveStock .pvStandaloneTwo .productInfo h5 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .LiveStock .pvStandaloneTwo .productInfo h5 span {
        width: 130px;
    }

    .LiveStock .hrsVdoWrpr > div {
        height: 200px !important;
    }

    .LiveStock .productStatus {
        width: 100%;
        height: unset;
        padding-left: 0;
    }

    .LiveStock .productStatus h4 {
        transform: unset;
        height: unset;
        padding: 10px;
    }
}
