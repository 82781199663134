.auctionGridCard .list-title {
    text-transform: uppercase;
    font-size: 500;
    margin-bottom: 8px;
}

.auctionGridCard .primButton button {
    border-radius: 25px;
}

.auctionGridCard {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.auctionGridCard .grid-card .grid-img-wrap img {
    height: 250px;
    object-fit: cover;
}

.auctionGridCard .card-body {
    padding: 10px;
}
