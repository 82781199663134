.posContainer {
    margin-top: 30px;
}

.posContainer .posMiscAct {
    display: flex;
}

.posContainer .posMiscAct button {
    border-radius: 20px;
}

.posContainer .posMiscAct button:not(:first-child) {
    margin-left: 15px;
}

.posContainer .posMiscAct button .MuiButton-label {
    text-transform: initial;
    color: #333;
}

.posContainer .posMiscAct button .MuiButton-label .material-icons {
    padding-right: 8px;
    color: #7f7f7f;
}

.posSearch {
    display: flex;
    justify-content: flex-end;
}

.posSearch form {
    margin-right: 20px;
    width: 300px;
}

.posMain {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 25px;
    margin-top: 25px;
}

.posMain .posMainLt {
    margin-bottom: 20px;
}

.posMain .posMainLt .nav-standard-top-area-search-input:first-child {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.posMain .posMainLt .nav-standard-top-area-search-sumbit {
    border-radius: 10px;
    height: 100%;
}

.posMain .posMainRt {
    padding: 30px;
    border: 1px dashed #9ab1cb;
    border-radius: 20px;
    background: #f7fafe;
    height: 100%;
    max-height: 500px;
    overflow-y: auto;
    padding-bottom: 10px;
}

.posMain .posItems {
    gap: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.posMainRt h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #464646;
}

.posMain .posProduct {
    height: max-content;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
}

.posMain .posProduct .lazy-load-image-background {
    height: 200px !important;
}

.posMain .posProduct .lazy-load-image-background img {
    object-fit: cover;
    border-radius: 10px;
}

.posMain .posProduct h5 {
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.posMain .posProduct h6 {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
}

.posMain .table thead th {
    background: #f5f5f5;
    color: #333;
}

.posMain .table thead th {
    font-size: 12px;
}

.posMain .table tbody td {
    font-size: 14px;
}

.posMain .table tbody td img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.posMain .table tbody td .addToCartBtn {
    min-width: initial !important;
    width: 45px;
    height: 45px;
    background: #f6f6f6;
    color: #818080;
    border-radius: 40px;
}

.posMain .currencyAmt {
    display: block;
    white-space: nowrap;
}

.posMain .primButton {
    /* box-shadow: 0px 3px 20px #41414175; */
    margin-top: 40px;
    position: sticky;
    bottom: 10px;
}

.posMain .tableInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.posModal .MuiAppBar-root {
    background-color: #333;
    margin-bottom: 30px;
}

.posModal .tableAct {
    display: flex;
    justify-content: center;
}

.posModal .tableAct button {
    min-width: initial;
    width: 45px;
    height: 45px;
    background: #f6f6f6;
    color: #818080;
    border-radius: 40px;
    border: none;
}

.posModal .table thead th {
    background: #f5f5f5;
    color: #333;
}

.posModal .addBtn button {
    background: var(--primColor);
    color: #fff;
}

.noRecords {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.noRecords img {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.noRecords h6 {
    font-size: 15px;
    font-weight: 600;
    margin: 40px 0 0;
    color: #617a91;
}

.posMain .table tbody td .addToCartBtn {
    background: #e3ffe3;
    color: green;
}

.posMain .table tbody td .addToCartBtn.inCart {
    background: #ffd9d8;
    color: #d75553;
}

@media (max-width: 1500px) {
    .posMain .posProduct .lazy-load-image-background {
        height: 150px !important;
    }
}

@media (max-width: 1200px) {
    .posMain .posProduct .lazy-load-image-background {
        height: 100px !important;
    }
}

@media (max-width: 1100px) {
    .posMain {
        display: block;
    }

    .posMain .posItems {
        grid-template-columns: 1fr;
    }

    .posMain .posProduct {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 20px;
    }
    .posMain .posProduct h5 {
        -webkit-line-clamp: 2;
    }
}

@media (max-width: 500px) {
    .posMain .posProduct .lazy-load-image-background {
        height: 70px !important;
    }
}
