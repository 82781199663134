.HeavyEquipment .home {
    background: #fff;
}
.HeavyEquipment .siteBanner {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
    border-radius: 0;
}

.siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.filters-by,
.sort-by {
    margin: 0 -5px;
}

.filters-by .filter-item,
.sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.sort-item a:hover,
.sort-item a:focus,
.sort-item a.active {
    color: #ba122b;
}

.search-wrapper {
    margin-bottom: 30px;
}

.HeavyEquipment .home .auctionListCnt {
    position: relative;
    margin-top: 0px;
    padding-top: 0;
    background: #fff;
    border-radius: 0px;
}

.HeavyEquipment .home .auctionListCnt .auctionListTitle {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #474747;
    text-align: left;
    text-transform: initial;
    margin: 45px 0;
    position: relative;
    text-align: center;
}

.HeavyEquipment .home .auctionListCnt .auctionListTitle::after {
    position: absolute;
    display: block;
    content: '';
    top: auto;
    bottom: -10px;
    right: 0;
    left: 0;
    width: 105px;
    background: var(--primColor);
    height: 2px;
    margin: auto;
}

/* .HeavyEquipment .home .auctionListCnt .auctionListTitle::before {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    width: 15px;
    transform: translateX(-110px);
    background: var(--primColor);
    height: 1px;
}

.HeavyEquipment .home .auctionListCnt .auctionListTitle::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    right: 50%;
    width: 15px;
    transform: translateX(110px);
    background: var(--primColor);
    height: 1px;
} */

.auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.HeavyEquipment .home .siteBanner .bannerContent {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .HeavyEquipment .siteBanner:after {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #2e2e2e80;
    width: 100%;
    height: 100%;
    content: '';
    left: 0;
    z-index: 0;
} */

.HeavyEquipment .home .siteBanner .bannerContent h2 {
    font-size: 30px;
    margin-bottom: 20px;
    color: var(--white);
    z-index: 999;
    font-weight: 500;
    letter-spacing: 1px;
    max-width: fit-content;
    width: 100%;
    line-height: 50px;
}

.HeavyEquipment .home .siteBanner .bannerContent p {
    font-size: 15px;
    margin-bottom: 45px;
    color: #333;
    width: 100%;
    max-width: 600px;
}

.HeavyEquipment .home .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.homeActiveAuction {
    background: #efefef;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.homeActiveAuction h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--primColor);
}

.homeActiveAuction h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2f2f2f;
}

.homeActiveAuction .primButton button {
    width: 180px;
    border-radius: 25px;
}

.HeavyEquipment .homeCnt {
    display: flex;
    justify-content: flex-start;
}

.HeavyEquipment .homeCnt .homeLt {
    position: sticky;
    top: 10px;
    max-width: 255px;
    width: 100%;
    margin-right: 20px;
    margin-top: 0px;
}

.HeavyEquipment .homeCnt .homeRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.HeavyEquipment .homeLt h3 {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
}

.HeavyEquipment .homeLt h3::after {
    position: absolute;
    content: '';
    bottom: -10px;
    left: 0;
    height: 2px;
    width: 80px;
    background: var(--primColor);
}

.HeavyEquipment .homeLt .homeCatg {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
}

.HeavyEquipment .homeLt .homeCatg .homeCatgInner .MuiCheckbox-root {
    display: none;
}

.HeavyEquipment .homeLt .homeCatg .homeCatgInner > div {
    width: 100%;
}

.HeavyEquipment .homeLt .homeCatg .homeCatgInner > div > .customCheckbox,
.HeavyEquipment .homeLt .homeCatg label,
.HeavyEquipment .homeLt .homeCatg .MuiTypography-root {
    width: 100%;
}

.HeavyEquipment .homeLt .homeCatg .homeCatgInner .catCount {
    height: 25px;
    font-size: 14px;
    background: #e9e9e9;
    color: #2e2e2e;
    padding: 0px 15px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.HeavyEquipment .homeLt .homeCatg .homeCatgInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeavyEquipment .homeLt .homeCatg .homeCatgInner label {
    margin: 0;
}

.HeavyEquipment .homeRt .MuiTabs-fixed {
    text-align: center;
}

.HeavyEquipment .homeRt .MuiTabs-fixed .MuiTabs-flexContainer {
    border-bottom: none;
}

.HeavyEquipment .homeRt .MuiTabs-fixed .MuiTab-root {
    position: relative;
}

.HeavyEquipment .homeRt .MuiTabs-fixed .MuiTab-root.Mui-selected {
    background: #fff;
}

.HeavyEquipment .homeRt .MuiTabs-fixed .MuiTab-root.Mui-selected::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 50%;
    height: 3px;
    background: var(--primColor);
}

.HeavyEquipment .homeRt .MuiTab-wrapper {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
}

.HeavyEquipment .homeRt .MuiTabs-root {
    margin-bottom: 45px;
}

/* .HeavyEquipment .homeContact {
    text-align: center;
    background: #fff;
    padding: 45px 0;
} */

.HeavyEquipment .homeContact h2 {
    font-size: 22px;
    font-weight: 600;
    color: #2e2e2e;
    margin-bottom: 10px;
}

.HeavyEquipment .homeContact p {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
    margin-bottom: 20px;
}

.HeavyEquipment .homeContact .subsBox {
    height: 50px;
    width: 400px;
    margin: 0 auto;
}

.HeavyEquipment .homeContact .subsBox input {
    height: inherit;
    border: none;
    width: calc(100% - 50px);
}

.HeavyEquipment .homeContact .subsBox button {
    height: inherit;
    background: var(--primColor);
    color: #fff;
    min-width: inherit;
    width: 50px;
}

.HeavyEquipment .homeCnt .toggleRespDrawer.MuiButton-root {
    display: none;
}

.HeavyEquipment .home .homeContactRequest:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #00000096;
    z-index: 0;
}

.HeavyEquipment .home .homeContactRequest {
    background: url('https://auction_io.ecommerce.auction/uploads/product/300330a99b7a2cb3c6e871607f58174cb6ad20ab.jpeg');
    display: grid;
    align-items: flex-start;
    justify-content: space-between;
    padding: 45px 60px;
    grid-template-columns: 55% 45%;
    position: relative;
    height: 500px;
    margin-bottom: 245px;
}

.HeavyEquipment .home .homeContactRequest .customInput {
    margin-bottom: 15px;
}

.HeavyEquipment .home .homeContactRequest .lftReqstCnt {
    padding-right: 75px;
}

.HeavyEquipment .home .homeContactRequest .lftReqstCnt,
.HeavyEquipment .home .homeContactRequest .rtReqFrm {
    z-index: 1;
}
.HeavyEquipment .home .homeContactRequest .lftReqstCnt p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 30px;
    color: var(--white);
}

.HeavyEquipment .home .homeContactRequest .lftReqstCnt button {
    width: fit-content;
    text-transform: capitalize;
}

.HeavyEquipment .home .homeContactRequest .rtReqFrm button {
    text-transform: capitalize;
}

.HeavyEquipment .filterPanel .filterTitleCnt {
    display: none !important;
}

.HeavyEquipment .home .homeContactRequest .lftReqstCnt h3 {
    font-size: 40px;
    font-weight: 600;
    line-height: 65px;
    padding-bottom: 15px;
    color: var(--white);
}

.HeavyEquipment .home .homeContactRequest .rtReqFrm h4 {
    font-size: 22px;
    font-weight: 500;
    position: relative;
    margin-bottom: 33px;
    text-align: center;
}

.HeavyEquipment .home .homeContactRequest .rtReqFrm h4::after {
    position: absolute;
    display: block;
    content: '';
    top: auto;
    bottom: -10px;
    right: 0;
    left: 0;
    width: 105px;
    background: var(--primColor);
    height: 2px;
    margin: auto;
}

.HeavyEquipment .home .homeContactRequest .rtReqFrm {
    background: var(--white);
    padding: 30px;
    min-width: 479px;
    width: auto;
    max-width: 479px;
    box-shadow: 0px 4px 13px 0px #00000026;
    border-radius: 13px;
    transform: translateY(20px);
}

.HeavyEquipment .home .lotResults.Grid .aucGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.HeavyEquipment .lotResults.Grid .viewAll {
    margin-top: 30px;
}

.HeavyEquipment .home .filterPanel .MuiAccordionDetails-root {
    padding: 0;
    margin-top: 10px;
}

.HeavyEquipment .home .filterPanel .MuiAccordionSummary-root {
    padding: 0px 10px;
}

.HeavyEquipment .homeAuctions.mobileView {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.HeavyEquipment .homeAuctions.mobileView .list-title {
    font-size: 15px;
    height: 5.5ex;
}

/* Responsive Code */
@media (max-width: 1150px) {
    .HeavyEquipment .home .homeContactRequest .lftReqstCnt h3 {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 0;
    }

    .HeavyEquipment .home .homeContactRequest .lftReqstCnt p {
        font-size: 15px;
    }

    .HeavyEquipment .home .homeContactRequest {
        grid-template-columns: 50% 50%;
    }

    .HeavyEquipment .home .homeContactRequest .lftReqstCnt {
        padding-right: 35px;
    }
}

@media (min-width: 1024px) {
    .HeavyEquipment .home .homeContactRequest {
        grid-template-columns: 46% 53%;
    }
}

@media (min-width: 992px) {
    .HeavyEquipment .home .homeContactRequest .rtReqFrm {
        min-width: 400px;
        margin-left: auto;
    }

    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 991px) {
    .HeavyEquipment .home .homeContactRequest .rtReqFrm {
        min-width: 400px;
        max-width: 400px;
    }
    .HeavyEquipment .homeCnt .homeRt {
        max-width: 100%;
    }
    .HeavyEquipment .home .auctionListCnt .auctionListTitle {
        text-align: center;
    }
    .HeavyEquipment .homeCnt .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
        position: absolute;
        top: 0px;
        right: auto;
        right: 60px;
    }
    .HeavyEquipment .homeCnt .homeLt {
        display: none;
    }
}

@media (max-width: 850px) {
    .HeavyEquipment .home .homeContactRequest .lftReqstCnt h3 {
        font-size: 26px;
        line-height: 40px;
    }

    .HeavyEquipment .home .homeContactRequest .lftReqstCnt {
        padding-right: 20px;
    }

    .HeavyEquipment .home .homeContactRequest .lftReqstCnt p {
        font-size: 14px;
        line-height: 22px;
    }

    .HeavyEquipment .home .homeContactRequest .rtReqFrm {
        min-width: 375px;
    }

    .HeavyEquipment .homeRt .MuiTab-wrapper {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .HeavyEquipment .home .homeContactRequest {
        max-height: 600px;
        height: auto;
        margin-bottom: 315px;
    }

    .HeavyEquipment .home .homeContactRequest .lftReqstCnt h3 {
        font-size: 22px;
        line-height: 35px;
    }

    .HeavyEquipment .home .homeContactRequest .rtReqFrm {
        margin: auto;
    }

    .HeavyEquipment .home .homeContactRequest .lftReqstCnt {
        text-align: center;
        padding-right: 0;
    }

    .HeavyEquipment .home .homeContactRequest {
        grid-template-columns: 100%;
    }

    .HeavyEquipment .home .homeContactRequest {
        flex-direction: column;
    }
    .homeCnt .toggleRespDrawer.MuiButton-root {
        top: 0px;
    }

    .siteBanner {
        margin-bottom: 0;
    }

    .HeavyEquipment .homeRt .MuiTabs-fixed {
        text-align: left;
    }

    .HeavyEquipment .home .auctionListCnt .auctionListTitle {
        margin: 20px auto;
    }

    .HeavyEquipment .homeCnt .homeLt {
        max-width: 100%;
        position: inherit;
        margin-right: 0px;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .HeavyEquipment .homeCnt .homeRt {
        max-width: 100%;
    }

    .HeavyEquipment .homeCnt {
        flex-direction: column;
    }

    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .siteBanner {
        height: 300px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }

    .Liquidation .home .siteBanner .bannerContent {
        top: 30px;
        left: 30px;
    }

    .Liquidation .home .siteBanner .bannerContent h2 {
        font-size: 25px;
    }

    .Liquidation .home .siteBanner .bannerContent p {
        font-size: 15px;
        margin-bottom: 25px;
    }
    /* .Liquidation .home .auctionListCnt {
        margin-top: -90px;
    } */
    .Liquidation .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
}

@media (max-width: 700px) {
    .HeavyEquipment .homeRt .MuiTab-wrapper {
        font-size: 16px;
    }
    .HeavyEquipment .homeRt .MuiTabs-fixed {
        overflow: scroll !important;
    }
    .HeavyEquipment .homeAuctions.mobileView {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
}

@media (max-width: 600px) {
    .HeavyEquipment .home .grid-card .list-title {
        text-align: center;
    }
    .HeavyEquipment .home .homeContactRequest {
        padding: 35px;
    }
    .HeavyEquipment .home .homeContactRequest .rtReqFrm {
        min-width: 320px;
    }
    .HeavyEquipment .home .homeContactRequest .lftReqstCnt p {
        font-size: 13px;
        line-height: 20px;
    }

    .HeavyEquipment .home .auctionListCnt .auctionListTitle {
        font-size: 22px;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        left: 15px;
    }
    .HeavyEquipment .home .siteBanner .bannerContent h2 {
        font-size: 24px;
        margin-bottom: 10px;
        max-width: 500px;
        width: 100%;
        line-height: 35px;
    }
    .HeavyEquipment .home .siteBanner .bannerContent {
        position: absolute;
        top: 70px;
        left: 25px;
    }
    .HeavyEquipment .homeCnt .toggleRespDrawer.MuiButton-root {
        right: 10px;
    }
}

@media (max-width: 545px) {
    .HeavyEquipment .homeAuctions.mobileView {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }

    .HeavyEquipment .home .homeContactRequest {
        padding-left: 15px;
        padding-right: 15px;
    }
    .HeavyEquipment .home .auctionListCnt .auctionListTitle {
        margin-bottom: 30px;
        text-align: left;
    }

    .HeavyEquipment .home .auctionListCnt .auctionListTitle::after {
        margin: 0;
    }
}

@media (max-width: 475px) {
    .HeavyEquipment .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
    .homeCnt .toggleRespDrawer.MuiButton-root {
        right: auto;
        left: 15px;
        padding: 3px 10px;
    }

    .HeavyEquipment .grid-card .grid-img-wrap img {
        object-fit: cover;
    }
}

@media (max-width: 450px) {
    .HeavyEquipment .home .homeContactRequest .rtReqFrm {
        min-width: 100%;
    }
    .siteBanner {
        height: 250px;
        top: 40%;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }
    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }
    .Liquidation .home .auctionListCnt {
        padding: 0;
        margin-top: 0;
    }
    .Liquidation .home {
        background: #fff;
    }
}
