.MarineDashboardLayout table .ssActBtn button:first-child {
    color: var(--primColor);
}

.MarineDashboardLayout table .ssActBtn button:last-child {
    color: #ff6c6c;
}

.MarineDashboardLayout .savedSearches .ssBtn {
    margin: 0 0 30px auto;
    width: max-content;
}

@media (max-width: 425px) {
    .MarineDashboardLayout .savedSearches .ssBtn {
        width: 100%;
        margin: 15px auto;
    }
}
