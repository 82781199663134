.sellerBio {
    background-color: #f9f9f9;
    padding-bottom: 40px;
}

.sellerBio .topBanner {
    height: 430px;
    background-size: cover !important;
    background-position: center !important;
}

.sellerBio .btmDivCntnr {
    display: flex;
    align-items: flex-start;
}

.ArtAuction .sellerBio .btmDivCntnr {
    flex-direction: column;
}

.sellerBio .btmDivCntnr .lftSlrInfo {
    width: 100%;
    max-width: 360px;
    margin-top: -80px;
    position: sticky;
    top: 110px;
    padding: 30px 20px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgb(25 25 25 / 10%);
}

.ArtAuction .sellerBio .btmDivCntnr .rtWrksLst,
.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo {
    max-width: 100%;
}

.ArtAuction .sellerBio .btmDivCntnr .rtWrksLst {
    margin-top: 67px;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo {
    margin-top: 0;
    position: unset;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    box-shadow: unset;
}

.sellerBio .btmDivCntnr .lftSlrInfo .slrPrflImg {
    width: 200px;
    height: 200px;
    border-radius: 300px;
    margin-left: auto;
    margin-right: auto;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .slrPrflImg img,
.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .slrPrflImg {
    margin-left: 0;
    border-radius: 0;
    margin-right: 0;
    width: 400px;
    height: 400px;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec {
    width: auto;
    min-width: calc(100% - 400px);
    padding-left: 80px;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .followCount .loctnInfo {
    justify-content: flex-end;
    margin-top: 22px;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;
}
.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .followCount,
.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .objectDeatais {
    width: 50%;
}

.sellerBio .btmDivCntnr .lftSlrInfo .slrPrflImg img {
    width: 100%;
    height: 100%;
    border-radius: 400px;
    object-fit: cover;
}

.sellerBio .btmDivCntnr .lftSlrInfo .flwDtlCntnr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.sellerBio .btmDivCntnr .lftSlrInfo .flwrsCount {
    background: whitesmoke;
    padding: 5px 10px;
    border-radius: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.sellerBio .btmDivCntnr .lftSlrInfo .flwrsCount p {
    margin: 0;
    font-size: 18px;
}
.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .flwrsCount p {
    font-size: 22px;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .flwrsCount {
    background-color: #fff;
}

.sellerBio .btmDivCntnr .lftSlrInfo .flwrsCount p span {
    font-size: 15px;
    color: gray;
    padding-right: 5px;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .flwrsCount p span {
    color: #000;
    font-size: 22px;
}

.sellerBio .btmDivCntnr .lftSlrInfo .primButton {
    width: 100%;
}

.sellerBio .btmDivCntnr .lftSlrInfo .primButton button {
    border-radius: 100px;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .primButton button {
    text-transform: capitalize;
    font-size: 22px;
    font-weight: 400;
    color: #fff;
    background-color: #000;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .secButton button {
    text-transform: capitalize;
    font-size: 22px;
    font-weight: 400;
    color: #000;
    background-color: #fff;
    border-radius: 100px;
}

.sellerBio .btmDivCntnr .lftSlrInfo .primButton button .material-icons {
    margin-right: 5px;
}

.sellerBio .btmDivCntnr .lftSlrInfo .slrNm {
    margin: 10px;
    text-align: center;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .objectDeatais h5 {
    margin: 0;
    text-align: left;
    font-size: 22px;
    font-weight: 400;
    color: #808080;
}
.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .slrNm {
    margin: 0;
    text-align: left;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 24px;
    color: #0a0a0a;
    text-transform: capitalize;
}

.sellerBio .btmDivCntnr .lftSlrInfo .abtMeCntnr {
    margin-top: 40px;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .abtMeCntnr p {
    font-size: 20px;
    line-height: 32px;
    color: #0a0a0a;
    font-weight: 400;
    display: inline;
}

.sellerBio .btmDivCntnr .lftSlrInfo .abtMeCntnr .readMore {
    white-space: pre;
}

.sellerBio .btmDivCntnr .lftSlrInfo .abtMeCntnr p,
.sellerBio .btmDivCntnr .lftSlrInfo .abtMeCntnr .shwMrBtn {
    font-size: 14px;
}

.ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .abtMeCntnr .shwMrBtn {
    color: #1a83f9;
    font-size: 26px;
}

.sellerBio .btmDivCntnr .lftSlrInfo h5.sbHdr {
    font-size: 13px;
    color: gray;
    margin-bottom: 15px;
    font-weight: 600;
}

.sellerBio .btmDivCntnr .rtWrksLst {
    width: 100%;
    max-width: calc(100vw - 390px);
    position: relative;
}

.sellerBio .btmDivCntnr .rtWrksLst .bioBackBtn {
    position: absolute;
    top: -56px;
    right: 20px;
    background: #0000008a;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
}

.topBanner .customContainer {
    height: 100%;
    position: relative;
}

.sellerBio .bioBackBtn.artsBckBtn {
    position: absolute;
    top: 370px;
    right: 50px;
    background: #0000008a;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
}

.sellerBio .btmDivCntnr .rtWrksLst .bioBackBtn .material-icons {
    margin-right: 10px;
}

.sellerBio .btmDivCntnr .rtWrksLst .wrksGrid {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 8px;
}

.ArtAuction .ArtTitle {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 20px;
}

.ArtAuction .sellerBio .btmDivCntnr .rtWrksLst .wrksGrid {
    grid-template-columns: unset;
    gap: 23px;
    padding: 0;
}

.ArtAuction .similarArtist .seeAllSimilarArtist {
    font-size: 18px;
    font-weight: 400;
    color: #1a83f9;
    text-transform: uppercase;
    text-decoration: underline;
}
.ArtAuction .similarArtist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 55px;
}

.bioCard {
    width: 100%;
    height: 220px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.ArtAuction .bioCard {
    height: 100%;
    border-radius: 0;
}
.ArtAuction .bioCard img {
    height: 400px;
}

.ArtAuction .artstCardGrid.bioPage .bioCard img {
    height: 300px;
}

.bioCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bioCard .crdDtlCnt {
    display: none;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 13px;
    color: white;
    background: linear-gradient(0deg, rgba(16, 16, 16, 1) 0%, rgb(4 4 4 / 5%) 100%);
}

.bioCard .crdDtlCnt h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ArtAuction .bioCard .crdDtlCnt {
    position: relative;
    display: flex;
    background: #fff;
    color: #0a0a0a;
    align-items: center;
    justify-content: space-between;
}

.ArtAuction .bioCard .crdDtlCnt .primButton .MuiButton-root {
    border-radius: 100px;
    background-color: #000;
    padding: 7px 15px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
    height: 36px;
    width: fit-content;
}

.ArtAuction .artLoad .primButton .MuiButton-root {
    background-color: #fff;
    color: #000;
    border-color: #000;
    border-radius: 100px;
}

.ArtAuction .bioCard .crdDtlCnt .secButton .MuiButton-root {
    border-color: #000;
    color: #000;
    padding: 7px 15px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 100px;
    text-transform: capitalize;
    height: 36px;
}

.bioCard:hover .crdDtlCnt {
    /* display: block; */
}

.bioCard .crdDtlCnt h5 {
    font-size: 16px;
    margin: 0;
}

.ArtAuction .bioCard .crdDtlCnt .artCreator {
    font-size: 16px;
    font-weight: 400;
    color: #808080;
}

.artstCardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 8px;
    padding-top: 10px;
    padding-bottom: 40px;
}

.ArtAuction .artstCardGrid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 23px;
}

.ArtAuction .artstCardGrid.bioPage {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 18px;
}

.artstLst {
    min-height: calc(100vh - 410px);
    padding-bottom: 40px;
}

.ArtAuction .artstLst .SearchFilter {
    margin-top: 58px;
    margin-bottom: 47px;
    display: grid;
    grid-template-columns: 45% 1fr;
    align-items: flex-start;
}

.ArtAuction .sellerBio .btmDivCntnr {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    margin-top: 58px;
    margin-bottom: 47px;
}

.artstLst h4.pgTtle {
    font-size: 40px;
    text-transform: capitalize;
    margin-bottom: 0px;
    margin-right: 25px;
    padding-bottom: 7px;
    border-bottom: 5px solid transparent;
    color: gray;
}

.sellerBio .sellerBio h4.pgTtle {
    font-size: 40px;
    text-transform: capitalize;
    margin-bottom: 0px;
    margin-right: 25px;
    padding-bottom: 7px;
    border-bottom: 5px solid transparent;
    color: gray;
}

.ArtAuction .sellerBio h4.pgTtle,
.ArtAuction .artstLst h4.pgTtle {
    padding-bottom: 0;
    border-bottom: 0;
}

.ArtAuction .sellerBio .MuiButtonBase-root:nth-last-child(1) .aTag::after,
.ArtAuction .artstLst .MuiButtonBase-root:nth-last-child(1) .aTag::after {
    display: none;
}

.ArtAuction .artTistGrisSys .bioTabs,
.ArtAuction .artTistGrisSys .artsTabs {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ArtAuction .artTistGrisSys {
    display: grid;
    align-items: center;
    place-content: center;
    grid-template-columns: 25% 50% 25%;
}

.ArtAuction .sellerBio .aTag::after,
.ArtAuction .artstLst .aTag::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    height: 50px;
    background-color: #0a0a0a;
    top: 0;
}
.ArtAuction .sellerBio .aTag,
.ArtAuction .artstLst .aTag {
    padding: 10px 30px;
    position: relative;
}

.ArtAuction .sellerBio h4.pgTtle,
.ArtAuction .artstLst h4.pgTtle {
    font-size: 26px;
    font-weight: 400;
    color: #808080;
    margin: auto;
}

.artstLst h4.pgTtle.active {
    border-bottom: 5px solid var(--primColor);
    color: black;
}

.ArtAuction .sellerBio h4.pgTtle.active,
.ArtAuction .artstLst h4.pgTtle.active {
    border-bottom: 0;
    color: black;
}

.lftSlrInfo .loctnInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    margin-bottom: 30px;
}

.lftSlrInfo .loctnInfo span.material-icons {
    font-size: 15px;
    margin-right: 5px;
}

.ArtAuction .lftSlrInfo .loctnInfo span.material-icons {
    font-size: 26px;
}

.lftSlrInfo .wrkExprnce .wrkExpDtl h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.lftSlrInfo .wrkExprnce .wrkExpDtl p {
    font-size: 13px;
    color: gray;
}

.lftSlrInfo .mbrSnce {
    font-weight: 600;
    font-size: 12px;
    color: gray;
    text-align: center;
}
.ArtAuction .lftSlrInfo .mbrSnce,
.ArtAuction .lftSlrInfo .rprtBtn {
    text-align: left;
    font-size: 20px;
    font-weight: 400;
}

.lftSlrInfo .tlsUsd {
    border: 1px solid rgb(225, 225, 225);
    border-radius: 5px;
    padding: 14px;
}

.lftSlrInfo .tlsUsd .tlDtls {
    /* border: 1px solid rgb(206, 206, 206); */
    background: whitesmoke;
    border-radius: 5px;
    padding: 6px 10px;
    font-size: 14px;
    text-align: center;
}

.lftSlrInfo .rprtBtn {
    text-align: center;
    margin-bottom: 0;
    font-size: 14px;
    color: gray;
    margin-bottom: 0px;
    margin-top: 10px;
    cursor: pointer;
}

.reportModal .RadioBox .MuiFormGroup-root {
    display: grid;
}

.reportModal .modal-header {
    background: white;
}
.artist-search .nav-standard-top-area-search-input:first-child {
    border-radius: 100px 0 0 100px;
    background: #f5f5f5;
    /* border-right: 1px solid #9b9b9b; */
    width: 120%;
}

.ArtAuction .artist-search .nav-standard-top-area-search-input::placeholder {
    color: #808080;
    font-size: 16px;
}

.ArtAuction .artist-search .nav-standard-top-area-search-input:first-child {
    background: transparent;
    padding: 14px 25px;
    font-style: italic;
    color: #808080;
    border-right: 0;
    font-size: 16px;
}

.artist-search .nav-standard-top-area-search-sumbit {
    width: 45px;
    height: 45px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: relative;
    top: 0;
    right: 0;
    color: #fff;
    background: var(--secColor);
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.ArtAuction .artist-search .nav-standard-top-area-search-sumbit {
    background: transparent;
}

.no-artist-o {
    padding-top: 31px;
    padding-bottom: 40px;
    padding-right: 49px;
    padding-left: 450px;
}

.sellerBio .noRecordsFound {
    margin-left: 20px;
}

.artstLst .nav-standard-top-area-search-sumbit {
    border-radius: 100px;
}

.artistBio.sellerBio .btmDivCntnr .rtWrksLst {
    max-width: 100%;
}

.artistBio.sellerBio .btmDivCntnr .rtWrksLst .wrksGrid {
    padding-left: 0;
    padding-right: 0;
}

.artistBio.sellerBio .btmDivCntnr .rtWrksLst .bioBackBtn {
    position: relative;
    top: unset;
}

.artstLst .artstSrchWrpr {
    display: flex;
    align-items: center;
    max-width: 400px;
}

.ArtAuction .artstLst .artstSrchWrpr {
    max-width: 100%;
    /* border: 1px solid #0000003b; */
    border-radius: 100px;
    /* border: 1px solid #ccc; */
}

.artstLst .artstSrchWrpr .nav-standard-top-area-search-sumbit {
    max-width: 45px;
    width: 100%;
    border-radius: 0 100px 100px 0;
}

.ArtAuction .artstLst .artstSrchWrpr .nav-standard-top-area-search-sumbit {
    max-width: 40px;
    width: 100%;
    border-radius: 100px;
    /* border: 1px solid #808080; */
    height: 40px;
    right: 7px;
}

.ArtAuction .artstLst .artstSrchWrpr .nav-standard-top-area-search-sumbit span {
    color: #000;
}

@media (max-width: 1200px) {
    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec {
        padding-left: 30px;
    }
}

@media (max-width: 700px) {
    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo {
        flex-wrap: wrap;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .slrPrflImg {
        margin-bottom: 30px;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec {
        padding-left: 0;
        min-width: 100%;
        width: 100%;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .flwrsCount p,
    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .primButton button {
        font-size: 16px;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .followCount .loctnInfo {
        font-size: 16px;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .flwrsCount p span {
        font-size: inherit;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .slrNm {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .objectDeatais h5 {
        font-size: 15px;
    }

    .artstLst h4.pgTtle {
        font-size: 22px;
    }

    .bioCard .crdDtlCnt {
        display: block;
    }

    .artistBio.sellerBio .btmDivCntnr .rtWrksLst .tpNmBtnLnk {
        flex-wrap: wrap-reverse;
    }

    .artistBio.sellerBio .btmDivCntnr .rtWrksLst .bioBackBtn {
        right: unset;
    }

    .sellerBio .btmDivCntnr {
        flex-wrap: wrap;
    }

    .sellerBio .btmDivCntnr .lftSlrInfo {
        max-width: unset;
        position: relative;
        top: unset;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo {
        margin-top: 0;
    }

    .sellerBio .btmDivCntnr .rtWrksLst {
        width: 100%;
        max-width: unset;
    }

    .sellerBio .btmDivCntnr .rtWrksLst .wrksGrid {
        padding-left: 0px;
        padding-right: 0px;
    }

    .sellerBio .bioBackBtn.artsBckBtn {
        bottom: 90px;
        right: 15px;
        top: unset;
    }
}

@media (max-width: 600px) {
    .ArtAuction .artTistGrisSys {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }

    .ArtAuction .artTistGrisSys .bioTabs,
    .ArtAuction .artTistGrisSys .artsTabs {
        margin-top: 20px;
    }

    .ArtAuction .sellerBio .aTag::after,
    .ArtAuction .artstLst .aTag::after {
        height: 30px;
        top: 6px;
    }

    .ArtAuction .sellerBio h4.pgTtle,
    .ArtAuction .artstLst h4.pgTtle {
        font-size: 18px;
    }
    .ArtAuction .sellerBio .btmDivCntnr,
    .ArtAuction .artstLst .SearchFilter {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 25px;
    }

    .ArtAuction .artstLst .SearchFilter .filterBtns {
        margin-left: 10px !important;
    }

    .ArtAuction .sellerBio .btmDivCntnr .filterBtns .MuiButton-root,
    .ArtAuction .artstLst .SearchFilter .filterBtns .MuiButton-root {
        padding: 0;
        min-width: max-content;
        width: 45px;
        height: 45px;
    }

    .ArtAuction .artstLst .SearchFilter .filterBtns .filterOption {
        margin-left: 10px !important;
    }

    .ArtAuction .bioTabs .MuiTab-textColorInherit,
    .ArtAuction .homeMainCntnr .MuiTab-textColorInherit {
        font-size: 18px;
    }
    .ArtAuction .artTistGrisSys .bioTabs {
        margin: 20px auto 0;
    }
}

@media (max-width: 500px) {
    .ArtAuction .similarArtist {
        flex-wrap: wrap;
    }
    .ArtAuction .artstCardGrid {
        grid-template-columns: 1fr;
    }
    .ArtAuction .bioCard img {
        height: 340px;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .slrPrflImg img,
    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .slrPrflImg {
        width: 100%;
        height: 400px;
    }
    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .followCount,
    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .objectDeatais {
        width: 100%;
    }

    .ArtAuction .sellerBio .btmDivCntnr .lftSlrInfo .ArtistDetailsSec .objectDeatais {
        margin-bottom: 15px;
    }

    .ArtAuction .sellerBio .btmDivCntnr .rtWrksLst {
        margin-top: 0px;
    }
}
