.Fundraiser .home {
    background: #fff;
}
.Fundraiser .home .siteBanner {
    width: 100%;
    height: 605px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
    border-radius: 0;
}

.Fundraiser .home .siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Fundraiser .home .siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.Fundraiser .filters-by,
.Fundraiser .sort-by {
    margin: 0 -5px;
}

.Fundraiser .filters-by .filter-item,
.Fundraiser .sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.Fundraiser .filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.Fundraiser .sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.Fundraiser .sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.Fundraiser .sort-item a:hover,
.Fundraiser .sort-item a:focus,
.Fundraiser .sort-item a.active {
    color: #ba122b;
}

.Fundraiser .search-wrapper {
    margin-bottom: 30px;
}

.Fundraiser .home .auctionListCnt {
    position: relative;
    margin-top: 0px;
    background: #fff;
    border-radius: 0px;
}

.Fundraiser .home .auctionListCnt .auctionListTitle {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #2e2e2e;
    text-align: left;
    text-transform: initial;
    margin: 45px 0;
    position: relative;
    text-align: center;
}

/* .Fundraiser .home .auctionListCnt .auctionListTitle::before {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    width: 15px;
    transform: translateX(-110px);
    background: var(--primColor);
    height: 1px;
}

.Fundraiser .home .auctionListCnt .auctionListTitle::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    right: 50%;
    width: 15px;
    transform: translateX(110px);
    background: var(--primColor);
    height: 1px;
} */

.Fundraiser .auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.Fundraiser .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.Fundraiser .home .siteBanner .bannerContent {
    position: absolute;
    top: 14%;
    left: 60px;
}

.Fundraiser .home .siteBanner .bannerContent h2 {
    font-size: 42px;
    margin-bottom: 20px;
    color: var(--bannerTextColor);
    font-weight: 500;
    letter-spacing: 1px;
    max-width: 500px;
    width: 100%;
    line-height: 56px;
    text-shadow: 2px 2px 4px #000000;
}

.Fundraiser .home .siteBanner .bannerContent p {
    font-size: 16px;
    margin-bottom: 45px;
    color: white;
    width: 100%;
    max-width: 600px;
}

.Fundraiser .home .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.Fundraiser .home .gradBack {
    background: rgb(224, 213, 241);
    background: linear-gradient(
        180deg,
        rgb(235, 235, 235) 0%,
        rgba(255, 255, 255, 1) 44%,
        rgba(255, 255, 255, 1) 100%
    );
}

.Fundraiser .home .topPdtsCtnr .customContainer {
    padding-top: 56px;
}

.Fundraiser .home .topPdtsCtnr .customContainer h2 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.Fundraiser .home .topPdtsCtnr .customContainer h3 {
    font-size: 30px;
    text-transform: uppercase;
}

.Fundraiser .homeActiveAuction {
    background: #efefef;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.Fundraiser .homeActiveAuction h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--primColor);
}

.Fundraiser .homeActiveAuction h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2f2f2f;
}

.Fundraiser .homeActiveAuction .primButton button {
    width: 180px;
    border-radius: 25px;
}

.Fundraiser .homeCnt .actnRt {
    width: 100%;
    min-height: 688px;
    border-radius: 10px;
    background: url('../../../assets/images/vectorBack.png');
    background-size: cover;
    padding: 55px 42px;
    color: white;
}

.Fundraiser .homeCnt .actnRt h2 {
    font-size: 52px;
    margin-bottom: 16px;
}

.Fundraiser .homeCnt .actnRt .secButton button {
    border: 1px solid white;
    background: transparent;
    width: max-content;
    color: white;
    margin-top: 42px;
}

.Fundraiser .homeContact {
    text-align: center;
    background: #f7f7f7;
    padding: 45px 0;
}

.Fundraiser .homeContact h2 {
    font-size: 22px;
    font-weight: 600;
    color: #2e2e2e;
    margin-bottom: 10px;
}

.Fundraiser .homeContact p {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
    margin-bottom: 20px;
}

.Fundraiser .homeContact .subsBox {
    height: 50px;
    width: 400px;
    margin: 0 auto;
}

.Fundraiser .homeContact .subsBox input {
    height: inherit;
    border: none;
    width: calc(100% - 50px);
    padding-left: 15px;
}

.Fundraiser .homeContact .subsBox button {
    height: inherit;
    background: var(--secColor);
    color: #fff;
    min-width: inherit;
    width: 50px;
}
.Fundraiser .homeCnt .toggleRespDrawer.MuiButton-root {
    display: none;
}

.Fundraiser .home .viewAllBtn {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 10px 0;
}

.Fundraiser .home .viewAllBtn hr {
    width: 45%;
}

.Fundraiser .home .viewAllBtn button {
    color: var(--primColor);
    text-transform: initial;
}

.Fundraiser .home .pdtGrdCntnr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    gap: 25px;
    margin-top: 40px;
}

.Fundraiser .name-txt {
    text-align: center;
    margin-bottom: 16px;
}

.Fundraiser .topPdtsCtnr .galleryPrice {
    background-blend-mode: color-burn;
    color: var(--primColor);
    border-radius: 100px;
    padding: 8px 15px;
    width: max-content;
    margin: auto;
    position: relative;
}

.Fundraiser .topPdtsCtnr .galleryPrice::after {
    content: '';
    background: var(--primColor);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    opacity: 0.1;
}

.Fundraiser .topPdtsCtnr .explrMrBtn button {
    border: 1px solid var(--secColor);
    border-radius: 8px;
    padding: 16px 25px;
    margin-top: 45px;
    color: var(--secColor);
    width: max-content;
}

.Fundraiser .topPdtsCtnr .explrMrBtn button:hover {
    color: var(--headerColor);
    border: 1px solid var(--primColor);
}

.Fundraiser .home .adBnr {
    background: url('../../../assets/images/bannerImg.png');
    height: 290px;
    width: 100%;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 70px;
    padding: 0 120px;
}

.Fundraiser .home .adBnr .adCntnr {
    width: 40%;
    margin-left: auto;
    text-align: right;
    padding: 30px 60px;
}

.Fundraiser .home .adBnr .adCntnr .primButton button {
    width: max-content;
    margin-top: 25px;
}

.Fundraiser .home .auctionListCnt {
    margin: 80px 0;
}

.Fundraiser .home .auctionListCnt .actnGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 13px;
}

.Fundraiser .auctionListCnt .grid-card,
.Fundraiser .auctionsSearch .grid-card {
    background: whitesmoke;
    padding: 10px;
    border-radius: 10px;
}

.Fundraiser .grid-card .list-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    height: auto;
    margin-bottom: 12px;
    margin-top: 20px;
}

.Fundraiser .grid-card .timer-wrapper {
    background: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.Fundraiser .grid-card .timer-wrapper .li-timer {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
}

.Fundraiser .grid-card .timer-wrapper .li-timer p {
    margin-bottom: 0;
}

.Fundraiser .grid-card .timer-wrapper .li-timer .timerCnt {
    color: var(--primColor);
}

.Fundraiser .grid-card .timer-wrapper .li-timer span {
    color: #afafaf;
    margin-right: 10px;
}

/* Responsive Code */

@media (max-width: 1280px) {
    .Fundraiser .home .gradBack {
        background: rgb(224, 213, 241);
        background: linear-gradient(
            180deg,
            rgba(224, 213, 241, 1) 0%,
            rgba(224, 213, 241, 1) 24%,
            rgba(255, 255, 255, 1) 24%,
            rgba(255, 255, 255, 1) 100%
        );
    }

    .Fundraiser .home .adBnr .adCntnr {
        width: max-content;
        margin-right: auto;
        margin-left: unset;
        text-align: left;
        padding: 30px 30px;
        backdrop-filter: blur(5px);
    }
}

@media (min-width: 992px) {
    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 991px) {
    .Fundraiser .homeCnt .homeRt {
        max-width: 100%;
    }
    .Fundraiser .home .auctionListCnt .auctionListTitle {
        text-align: left;
    }
    .homeCnt .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
        position: absolute;
        top: 72px;
        right: 60px;
        left: auto;
    }
    .Fundraiser .homeCnt .homeLt {
        display: none;
    }

    .Fundraiser .homeCnt .col-lg-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .Fundraiser .homeCnt .actnRt {
        height: max-content;
        min-height: unset;
        margin-top: 15px;
        width: 89vw;
        margin: auto;
    }
}

@media (max-width: 850px) {
    .Fundraiser .homeRt .MuiTab-wrapper {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .homeCnt .toggleRespDrawer.MuiButton-root {
        top: 50px;
    }

    .siteBanner {
        margin-bottom: 0;
    }

    .Fundraiser .homeRt .MuiTabs-fixed {
        text-align: left;
    }

    .Fundraiser .home .auctionListCnt .auctionListTitle {
        margin: 20px auto;
    }

    .Fundraiser .homeCnt {
        flex-direction: column;
    }

    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .siteBanner {
        height: 300px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }

    .Liquidation .home .siteBanner .bannerContent {
        top: 30px;
        left: 30px;
    }

    .Liquidation .home .siteBanner .bannerContent h2 {
        font-size: 25px;
    }

    .Liquidation .home .siteBanner .bannerContent p {
        font-size: 15px;
        margin-bottom: 25px;
    }
    /* .Liquidation .home .auctionListCnt {
        margin-top: -90px;
    } */
    .Liquidation .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
}

@media (max-width: 700px) {
    .Fundraiser .homeRt .MuiTab-wrapper {
        font-size: 16px;
    }
    .Fundraiser .homeRt .MuiTabs-fixed {
        overflow: scroll !important;
    }
}

@media (max-width: 600px) {
    .Fundraiser .home .auctionListCnt .auctionListTitle {
        font-size: 22px;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        right: 30px;
    }
    .Fundraiser .home .siteBanner .bannerContent h2 {
        font-size: 24px;
        margin-bottom: 10px;
        max-width: 500px;
        width: 100%;
        line-height: 35px;
    }
    .Fundraiser .home .siteBanner .bannerContent {
        position: absolute;
        top: 70px;
        left: 25px;
    }
    .home .viewAllBtn hr {
        width: 35%;
    }

    .Fundraiser .home .gradBack {
        background: rgb(224, 213, 241);
        background: linear-gradient(
            180deg,
            rgba(224, 213, 241, 1) 0%,
            rgba(224, 213, 241, 1) 14%,
            rgba(255, 255, 255, 1) 14%,
            rgba(255, 255, 255, 1) 100%
        );
    }

    .Fundraiser .home .adBnr {
        height: 290px;
        width: 100%;
        background-size: cover;
        background-position: 30%;
        padding: 0 30px;
    }
}

@media (max-width: 475px) {
    .Fundraiser .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
    .homeCnt .toggleRespDrawer.MuiButton-root {
        right: 15px;
        padding: 3px 10px;
    }
    .multiSellerTimerView h6 {
        font-size: 15px;
    }
    .multiSellerTimerView h6:not(:last-child) {
        margin-right: 20px;
    }
    .Fundraiser .grid-card .list-title {
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .siteBanner {
        height: 250px;
        top: 40%;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }
    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }
    .Liquidation .home .auctionListCnt {
        padding: 0;
        margin-top: 0;
    }
    .Liquidation .home {
        background: #fff;
    }
}
