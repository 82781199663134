.stripAdCard {
    max-width: 450px;
    width: 100%;
}

.stripAdCard input,
.stripAdCard button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}

@keyframes fade {
    from {
        opacity: 0;
        transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
        opacity: 1;
        transform: scale3D(1, 1, 1);
    }
}

.stripAdCard .FormRow:first-child {
    border-top: none;
}

.stripAdCard .FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #ffe4c4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}
.stripAdCard .FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #fce883;
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}

.stripAdCard .FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #fff;
    background-color: transparent;
    animation: 1ms void-animation-out;
}

.stripAdCard .FormRowInput::placeholder {
    color: #87bbfd;
}

.stripAdCard .StripeElement--webkit-autofill {
    background: transparent !important;
}

.stripAdCard .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
    max-width: 450px;
    height: 50px;
}

.stripAdCard .SubmitButton {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #f6a4eb;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #ffb9f6;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.stripAdCard .SubmitButton:active {
    background-color: #d782d9;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 0 1px 0 #e298d8;
    transform: scale(0.99);
}

.stripAdCard .SubmitButton.SubmitButton--error {
    transform: translateY(15px);
}
.stripAdCard .SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
}

.stripAdCard .SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
}

.stripAdCard .ErrorMessage {
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
}

.stripAdCard .ErrorMessage svg {
    margin-right: 10px;
}

.stripAdCard .Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
}

.stripAdCard .ResultTitle {
    color: #fff;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
}

.stripAdCard .ResultMessage {
    color: #9cdbff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
}

.stripAdCard .ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
}

.stripAdCard .StripeElement {
    box-sizing: border-box;
    height: 50px;
    padding: 14px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.stripAdCard .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.stripAdCard .StripeElement--invalid {
    border-color: var(--primColor);
}

.stripAdCard .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.stripAdCard button {
    width: 180px;
    height: 50px;
    border-radius: 5px;
    background: var(--primColor);
    color: white;
    font-size: 16px;
    margin-top: 10px;
}

.stripAdCard .form-row {
    width: 100%;
}

.ElementsApp,
.ElementsApp .InputElement {
    color: #5d3b32 !important;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.stripAdCard .cstmInput {
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    padding: 14px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
    color: #32325d;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
}

.stripAdCard .cstmInput:focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.stripAdCard .cstmInput::placeholder {
    color: #32325d;
    opacity: 0.3;
    font-weight: bold;
    font-style: italic;
    font-size: 16px;
}
