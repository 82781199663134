* {
    margin: 0;
    padding: 0;
}
i {
    margin-right: 10px;
}

/*------------------------*/
input:focus,
button:focus,
.form-control:focus {
    outline: none;
    box-shadow: none;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
}
.table tr,
.table tr td {
    vertical-align: middle;
    text-align: left;
}

.Auctioneer .cart-table {
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-gap: 15px;
}
.extraHeading .divider-header span {
    margin-bottom: 80px;
    background-color: #fff;
    min-width: 100%;
    max-width: fit-content;
    padding: 0px 25px;
    font-size: 20px;
}
.extraHeading .divider-header {
    text-align: center;
    display: block;
    position: relative;
    font-size: 20px;
    font-weight: 700;
}
.extraHeading::after {
    content: '';
    width: 100%;
    height: 3px;
    background-color: #1d52dd;
    position: absolute;
    z-index: -1;
    left: 0px;
    right: 0px;
    top: 11px;
}
.addingOn {
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Auctioneer .cartAmtPay {
    padding: 15px;
    border: 2px solid #000;
    border-radius: 8px;
    height: fit-content;
}
.Auctioneer .cartAmtPay .cartActBtn {
    margin-bottom: 0px !important;
}
.addingOn img {
    height: 150px;
    width: 190px;
    margin-bottom: 10px;
    object-fit: contain;
}
.addingOn .similarTitle {
    max-width: 345px;
    width: 100%;
    min-height: 40px;
    height: 100%;
}
.addingOn h5 {
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;
    color: #1d52dd;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    text-transform: capitalize;
}
.addOns .btn-accent {
    font-size: 14px;
    font-weight: 700;
    background-color: #1d52dd;
    color: #fff;
}
.addingOn span {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
    color: #646161;
}
.Auctioneer .extraAdd .swiper-button-prev,
.Auctioneer .extraAdd .swiper-button-next {
    background-color: #f5f5f5;
    color: #1d52dd;
}
.Auctioneer .extraAdd .swiper {
    width: 100%;
    height: fit-content;
    padding: 0px 0px 30px;
    border-bottom: 3px solid #1d52dd;
}
.button-container {
    display: flex;
    align-items: center;
}
.button-container .form-control {
    max-width: 80px;
    text-align: center;
    display: inline-block;
    margin: 0px 5px;
}
.Liquidation #qty .customInput {
    max-width: 70px;
}
#qty .customInput {
    margin-bottom: 0px;
    min-width: 60px;
}
#myTable .form-control {
    width: auto;
    display: inline-block;
}
.cart-qty-plus,
.cart-qty-minus {
    width: 38px;
    height: 38px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.cart-qty-plus:hover,
.cart-qty-minus:hover {
    background-color: #5161ce;
    color: #fff;
}
.img-prdct {
    width: 50px;
    height: 50px;
    background-color: #fff;
    object-fit: cover;
    border-radius: 4px;
}
.img-prdct img {
    width: 100%;
    height: 100%;
}
.Auctioneer .img-prdct img {
    object-fit: contain;
}

.cartAmtPay {
    width: max-content;
    margin: 30px left;
}
.cartAmtPay p {
    margin: 0;
    margin-right: 30px;
    font-weight: 500;
}

.cartAmtPay .caVal {
    margin-bottom: 20px;
}

.cartActBtn .primButton {
    min-width: max-content;
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
}
.cart-items-notes {
    /* width: 300%; */
    color: red;
    margin-left: 90px;
}

@media (max-width: 767px) {
    .cart-table table,
    .cart-table tbody,
    .cart-table tbody tr,
    .cart-table tbody td {
        display: block;
        width: 100%;
    }
    .Auctioneer .cart-table {
        grid-template-columns: 1fr;
    }
    .Auctioneer .cartAmtPay {
        margin: auto;
    }
    .cart-table {
        border: none;
    }

    .cart-table tr.cart-row td {
        text-align: left !important;
        padding: 8px 10px;
        padding-left: 60%;
        border-top: none;
    }

    .cart-table tr.cart-row td p {
        margin-bottom: 5px;
    }

    .cart-table table thead {
        display: none;
    }

    .cart-table table tbody tr.cart-row {
        position: relative;
        padding-left: 50px;
        padding-right: 50px;
        padding: 0 50px 10px;
        border-bottom: 1px solid #cccc;
    }

    .cart-table tr.cart-row td.ct-img {
        position: absolute;
        width: 50px;
        left: 0;
        top: 0;
        padding: 0;
    }

    .cart-table tr.cart-row td.ct-action {
        position: absolute;
        width: 50px;
        right: 0;
        top: 0;
        padding: 0;
        text-align: center !important;
        height: 50px;
        padding: 14px;
    }

    .cart-table tr.cart-row td.ct-data {
        vertical-align: middle;
        display: block;
        width: 100%;
        border: none;
        padding: 0 10px;
        text-align: left !important;
    }

    .ct-data .ct-ptitle {
        margin: 0 0 5px;
        font-weight: 600;
    }
    .cart-table thead {
        display: none;
        font-weight: bold;
    }

    .cart-table td {
        display: block;
    }

    .cart-table tbody td:before {
        content: attr(data-title);
        position: absolute;
        left: 65px;
        font-weight: 500;
    }
    .Collectibles .cart-table tbody td:before {
        content: attr(data-title);
        position: relative;
        left: 0px;
        font-weight: 500;
    }
}

@media (max-width: 600px) {
    .cartAmtPay {
        width: 100%;
        max-width: 400px;
    }

    .cartAmtPay .cartActBtn .primButton {
        margin-right: 0;
    }

    td.carNote {
        padding-left: 0px !important;
    }

    td.carNote .cart-items-notes {
        margin-left: 0px !important;
        width: 100%;
    }
}
