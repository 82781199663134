.liquidationTwoDashboardLayout table .ssActBtn button:first-child {
    color: var(--primColor);
}

.liquidationTwoDashboardLayout table .ssActBtn button:last-child {
    color: #ff6c6c;
}

.liquidationTwoDashboardLayout .savedSearches .ssBtn {
    margin: 0 0 30px auto;
    width: max-content;
}

@media (max-width: 600px) {
    .table.savesearch tr td {
        white-space: nowrap;
    }
    .liquidationTwo .multisellerDashboardLayout .table tbody td:last-child button {
        min-width: auto;
    }
    .liquidationTwo .mobile-table td::before {
        content: attr(data-label);
        float: left;
        font-weight: 400;
        text-transform: capitalize;
        display: none;
    }
    .liquidationTwo .MuiAutocomplete-root {
        margin-bottom: 30px;
    }
}
