.broadcastCnt {
    margin-top: 45px;
}

.broadcastCnt .broadcasterVideo {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
}

.broadcastCnt .broadcastCount {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px dashed var(--primColor);
    padding: 10px;
    border-radius: 8px;
    margin: 15px auto;
    width: max-content;
    min-width: 200px;
    color: var(--primColor);
}

.broadcasterVideo .productCardGrid .gridBidBox .biddingCnt .primButton,
.broadcasterVideo .productCardGrid .gridBidBox .biddingCnt .secButton {
    width: 100%;
}
