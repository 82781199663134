.banner-wrapper {
    height: 500px;
    overflow: hidden;
}

.MultiAuction .home-banner,
.MultiAuction .banner-wrapper {
    height: 450px;
    overflow: hidden;
}

/* .banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
} */

.uac-slider.singleSlider .slick-list .slick-track {
    margin: auto;
}

.MultiAuction .siteBanner {
    height: 750px;
    margin-bottom: 0px;
}

.MultiAuction .oneMinAuction .minAuctionGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 50px;
}

.MultiAuction .oneMinAuction .minAuctionGrid .minAuctionGridCrd {
    padding: 35px 15px;
    text-align: center;
    box-shadow: 0px 0px 15px -3px #00000030;
    border-radius: 10px;
}

.MultiAuction .fu-head {
    font-size: 40px;
    margin-bottom: 50px;
}

.MultiAuction .oneMinAuction .minAuctionGrid .minAuctionGridCrd .gridMedia .mediaBody p span {
    color: #9c206f;
}

.MultiAuction .oneMinAuction .minAuctionGrid .minAuctionGridCrd .gridMedia .mediaBody p {
    color: #060606;
    font-size: 23px;
    margin: 0;
    padding: 0 32px;
}

.MultiAuction .oneMinAuction .minAuctionGrid .minAuctionGridCrd .gridMedia .mediaBody {
    margin-top: 25px;
}

.MultiAuction
    .oneMinAuction
    .minAuctionGrid
    .minAuctionGridCrd
    .gridMedia
    .mediaLeft
    .MediaImg
    object {
    width: 200px;
    height: 200px;
    padding: 10px;
}

.MultiAuction .oneMinAuction .minAuctionGrid .minAuctionGridCrd .gridMedia .mediaLeft .MediaImg {
}

.MultiAuction .oneMinAuction .minAuctionGrid .minAuctionGridCrd .gridMedia {
    display: block;
}

.MultiAuction .oneMinAuction .homeTitleText {
    text-align: center;
    margin-bottom: 50px;
}

.MultiAuction .oneMinAuction .oneMinFinalDiv {
    text-align: center;
}

.MultiAuction .oneMinAuction .oneMinFinalDiv p {
    font-size: 50px;
    color: var(--primColor);
    font-weight: 400;
    margin-bottom: 40px;
}

.MultiAuction .oneMinAuction .oneMinFinalDiv .primButton .MuiButtonBase-root {
    font-size: 20px;
    color: #ffffff;
    text-transform: capitalize;
    background-color: #000000;
    border: 1px solid #000000;
}

.MultiAuction .oneMinAuction .oneMinFinalDiv .primButton {
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: auto;
    min-width: 250px;
}

.MultiAuction .oneMinAuction .homeTitleText .mainTit {
    font-size: 50px;
    color: #000000;
    font-weight: 400;
}

.MultiAuction .oneMinAuction .homeTitleText .subTit {
    font-size: 50px;
    color: #e8aa2f;
    font-weight: 400;
}

.MultiAuction .oneMinAuction {
    padding: 0 50px;
    margin: 50px auto;
}

.banner-content-wrapper {
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    max-width: 540px;
    width: 100%;
    height: 100%;
    background: #505050;
}

.MultiAuction .banner-content-wrapper {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    background: #00000047;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.MultiAuction .banner-content-wrapper .bannerContent {
    text-align: center;
}

.MultiAuction .banner-content-wrapper .bannerContent .timerCnt {
}

.MultiAuction .banner-content-wrapper .bannerContent .timerCnt .timerWrapper {
}

.MultiAuction .banner-content-wrapper .bannerContent .timerCnt .timerWrapper .timeLabel {
    display: none;
}

.MultiAuction .banner-content-wrapper .bannerContent .timerCnt .timerWrapper .multiSellerTimerView {
}

.MultiAuction
    .banner-content-wrapper
    .bannerContent
    .timerCnt
    .timerWrapper
    .multiSellerTimerView
    h6
    span {
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
    text-transform: uppercase;
}

.MultiAuction
    .banner-content-wrapper
    .bannerContent
    .timerCnt
    .timerWrapper
    .multiSellerTimerView
    h6 {
    display: flex;
    color: #ffffff;
    flex-direction: column-reverse;
}

.banner-tn {
    font-size: 32px;
    color: #efefef;
    font-weight: 600;
    margin-bottom: 15px;
}

.MultiAuction .multiSellerTimerView h6 {
    font-size: 30px;
    font-weight: 600;
}
.MultiAuction .multiSellerTimerView h6 span {
    font-size: 20px;
}
.MultiAuction .bannerInner .multiSellerTimerView {
    text-shadow: 2px 2px #000;
}
.MultiAuction .bannerInner .btn-white-trans {
    border: 1px solid #9c206f;
    background: #9c206f;
    padding: 8px 25px;
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    width: fit-content;
    height: auto;
}

.MultiAuction .bannerInner .banner-tn {
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 5px;
}

.banner-thead {
    text-decoration: underline;
    font-size: 34px;
    line-height: 50px;
    color: #fff;
    margin-bottom: 20px;
}

.ra-link {
    font-size: 16px;
    color: #24c0eb;
}

.btn-white-trans {
    width: 120px;
    height: 44px;
    background: transparent;
    border: 2px solid #fff;
    font-family: Lato;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    color: #fff;
    border-radius: 0;
    margin-top: 35px;
    padding: 8px;
}

.btn-white-trans:hover,
.btn-white-trans:focus {
    background: #fff;
    color: #24c0eb;
}

.home-container .slick-next,
.home-container .slick-prev {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 30px;
    width: 30px;
    left: inherit !important;
    font-size: 30px;
    background: transparent;
    color: #000;
    z-index: 9;
}

.MultiAuction .home-banner .home-container .slick-next,
.MultiAuction .home-banner .home-container .slick-prev {
    color: #fff;
}

.home-banner {
    background: #505050;
    padding: 0 !important;
    height: 500px;
}

.home-banner .slick-prev {
    color: #fff;
    z-index: 999;
}

.home-container .slick-prev {
    right: inherit !important;
    left: 30px !important;
}

.home-container .slick-next:before,
.home-container .slick-prev:before {
    display: none;
}

.fu-head {
    font-size: 36px;
    letter-spacing: 0.03em;
    line-height: 1.67;
    text-align: center;
    color: #000;
    margin: 100px auto 50px;
    position: relative;
}

.fu-head:after {
    /* position: absolute;
    height: 1px;
    background: #000;
    width: 105px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -15px; */
}

.insta-slider-wrapper {
    margin-bottom: 50px;
}

.MultiAuction .insta-slider-wrapper .item.instagallery {
    /* max-width: 20%; */
}

.insta-slider-wrapper h6 {
    font-size: 20px;
    letter-spacing: 0.03em;
    line-height: 23px;
    margin-bottom: 30px;
    color: #505050;
}

.insta-slider-wrapper h6 i {
    color: #2fb8de;
}

.uac-slider .card {
    width: 106% !important;
    display: flex;
    flex-direction: row;
    padding: 15px;
    height: 328px;
    background: #fff;
    border: 1px solid #eaebee;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.MultiAuction .upComingSlider.uac-slider .card {
    width: 100% !important;
    height: 200px;
}

.uac-slider .card img {
    width: 180px;
    height: 100%;
    object-fit: cover;
}

.uac-slider .card-body {
    padding: 0.5rem 1.25rem;
    max-width: calc(100% - 125px);
}

.uac-slider .slick-list {
    padding-bottom: 10px;
}

.uac-slider .slick-slide > div {
    padding-right: 10px;
}

.uac-slider h3 {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
    line-height: initial;
    color: #505050;
    margin: 0 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ua-note {
    font-size: 14px;
    letter-spacing: 0.03em;
    line-height: 23px;
    text-align: left;
    color: #505050;
}

.ua-date {
    font-size: 18px;
    letter-spacing: 0.03em;
    line-height: 23px;
    display: flex;
}

.ua-lots {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.03em;
    line-height: 23px;
    text-align: left;
    color: #2fb8de;
}

.card .btn-white-trans {
    margin: 0;
    border-color: var(--primColor);
    padding: 8px;
}

.card .btn-white-trans:hover,
.card .btn-white-trans:focus {
    background: var(--primColor);
    color: #fff;
}

.uac-slider.news-wrapper .slick-slide {
    padding: 0 10px;
}

.uac-slider.news-wrapper .slick-slide .card {
    max-width: unset;
    width: 100% !important;
}

.uac-slider.news-wrapper .slick-slide .card .card-body p.descrptnWrpr {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
}

.uac-slider.news-wrapper .slick-slide .card .card-body p.descrptnWrpr.expndWrp {
    display: block;
    overflow: auto;
    max-height: 218px;
    height: 100%;
}

.uac-slider a.sm-link,
.au-container .sm-link {
    text-decoration: underline;
    font-size: 18px;
    letter-spacing: 0.03em;
    line-height: 23px;
    text-align: left;
    color: #2fb8de;
}

.au-container {
    min-height: auto;
    margin-top: 75px;
}

.au-container .sm-link {
    font-size: 16px;
}

.au-circle {
    width: 94px;
    height: 94px;
    background: transparent;
    border: 3px solid #2fb8de;
    margin-right: 32px;
    text-align: center;
    border-radius: 50%;
    position: relative;
}

.au-circle img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.bannerGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 10px;
    width: calc(100% - 540px);
    background: #fff;
}

.MultiAuction .bannerGrid {
    width: 100%;
    grid-template-columns: 100%;
    gap: 0px;
    display: block;
    height: 100%;
}

.bannerInner {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.MultiAuction .bannerInner {
    display: block;
    position: relative;
}

.bannerGrid img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.MultiAuction .bannerGrid img {
    height: 100%;
    object-fit: fill;
}

.MultiAuction .customContainer {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
}

.MultiAuction .insta-slider-wrapper .insta-slider {
}

.MultiAuction .insta-slider-wrapper .insta-slider .primButton {
}

.MultiAuction .insta-slider-wrapper .insta-slider .timerCnt {
    font-size: 18px;
}

.MultiAuction .insta-slider-wrapper .insta-slider .primButton button {
    font-size: 20px;
}

.MultiAuction .insta-slider-wrapper .multiSellerTimerView h6 {
    margin-bottom: 0;
    color: #000000;
}

.MultiAuction .insta-slider-wrapper h6 {
    margin-bottom: 10px;
}

.MultiAuction .multiSellerTimerView h6 span {
    margin-bottom: 0;
}

.MultiAuction .insta-slider-wrapper .insta-slider .card-body h3 {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
}

.MultiAuction .insta-slider-wrapper .insta-slider .card-body .timerCnt .timerWrapper {
    margin-top: 5px;
}

.MultiAuction .whoVR .whoVRCnt {
}

.MultiAuction .whoVR .whoVRCnt .tit {
    font-size: 40px;
    color: #000;
    margin-bottom: 10px;
}

.MultiAuction .whoVR .whoVRCnt .des {
    font-size: 20px;
    color: #000;
    margin-bottom: 40px;
    line-height: 30px;
}

.MultiAuction .whoVR .whoVRCnt .MuiButtonBase-root {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
}

.MultiAuction .whoVR .whoVRCnt .primButton {
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: auto;
    min-width: 250px;
}

.MultiAuction .whoVR .wvrImg {
    max-height: 400px;
    overflow: hidden;
}

.MultiAuction .whoVR .wvrImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.MultiAuction .whoVR {
    display: grid;
    grid-template-columns: 63% 35%;
    grid-gap: 25px;
    gap: 25px;
    padding: 25px;
    box-shadow: 0px 0px 15px -3px #00000030;
    border-radius: 10px;
    background: #fff;
}

.MultiAuction .insta-slider-wrapper .insta-slider .card-body {
    padding: 13px 10px;
    text-align: center;
}

.MultiAuction .insta-slider-wrapper .insta-slider figure {
    height: 250px;
    overflow: hidden;
    margin-bottom: 0;
}

.MultiAuction .insta-slider-wrapper .insta-slider .slick-slide {
    /* margin: 0 20px; */
}

.MultiAuction .insta-slider-wrapper .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* responsive */

@media (max-width: 1380px) {
    .MultiAuction .oneMinAuction .minAuctionGrid .minAuctionGridCrd .gridMedia .mediaBody p {
        padding: 0 5px;
        font-size: 20px;
    }

    .home-banner {
        height: 360px;
    }

    .banner-wrapper {
        height: 360px;
    }

    .bannerGrid img {
        height: 175px;
    }

    .fu-head {
        margin-top: 30px;
        font-size: 25px;
        margin-bottom: 30px;
    }

    .fu-head:after {
        bottom: -5px;
    }

    .MultiAuction .mainHeader {
        padding: 13px 0;
    }
}

@media (min-width: 1300px) {
    .insta-slider .slick-prev {
        left: 10px !important;
    }

    .insta-slider .slick-next {
        right: 10px !important;
    }
}

@media (max-width: 1300px) {
    .insta-slider .slick-prev {
        left: 0px !important;
    }

    .insta-slider .slick-next {
        right: 0px !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .uac-slider .card-body {
        padding: 0.5rem 0 0.5rem 1.25rem;
    }

    .uac-slider .slick-slide img {
        max-width: 125px;
    }

    .ua-date,
    .au-container p,
    .au-container .sm-link {
        font-size: 12px;
    }

    .au-container h2 {
        font-size: 20px;
    }

    .au-circle {
        margin-right: 15px;
        width: 72px;
        height: 72px;
    }

    .au-circle img {
        max-width: 45px;
    }
}

@media (max-width: 767px) {
    .MultiAuction .oneMinAuction {
        padding: 0 20px;
    }

    .fu-head {
        font-size: 20px;
        margin: 25px auto;
    }

    .home-container .slick-next {
        right: 5px;
    }

    .home-container .slick-prev {
        left: 5px !important;
        z-index: 99;
    }

    .insta-slider-wrapper,
    .au-container {
        margin: 0;
    }

    .au-container .col-sm-4 + .col-sm-4 {
        margin-top: 25px;
    }

    .au-container h2 {
        font-size: 24px;
    }

    .banner-content-wrapper .ua-date {
        justify-content: center;
    }

    .btn-white-trans {
        margin: 10px auto;
    }

    .home-banner .slick-prev,
    .home-banner .slick-next {
        color: #fff;
    }
}

@media (max-width: 650px) {
    .MultiAuction .staticPsgs {
        padding: 0 20px !important;
    }

    .MultiAuction
        .banner-content-wrapper
        .bannerContent
        .timerCnt
        .timerWrapper
        .multiSellerTimerView
        h6
        span,
    .MultiAuction .bannerInner .banner-tn {
        font-size: 24px;
    }

    .MultiAuction .oneMinAuction .homeTitleText {
        margin-bottom: 30px;
    }

    .MultiAuction .bannerInner .btn-white-trans {
        font-size: 16px;
    }

    .MultiAuction .oneMinAuction .minAuctionGrid {
        gap: 30px;
    }

    .MultiAuction .oneMinAuction .minAuctionGrid .minAuctionGridCrd .gridMedia .mediaBody p {
        font-size: 18px;
    }

    .MultiAuction
        .oneMinAuction
        .minAuctionGrid
        .minAuctionGridCrd
        .gridMedia
        .mediaLeft
        .MediaImg
        object {
        width: 100px;
        height: 100px;
        padding: 5px;
    }

    .MultiAuction .whoVR .whoVRCnt .tit {
        font-size: 26px;
    }

    .MultiAuction .whoVR .whoVRCnt .des {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 26px;
    }

    .MultiAuction .whoVR {
        grid-template-columns: 100%;
    }

    .uac-slider .card,
    .MultiAuction .upComingSlider.uac-slider .card {
        flex-direction: column;
        height: auto;
        width: 100% !important;
    }

    .MultiAuction .siteBanner {
        height: 350px;
    }
}

@media (max-width: 479px) {
    .uac-slider .card,
    .MultiAuction .upComingSlider.uac-slider .card {
        flex-direction: column;
        height: auto;
        width: 100% !important;
    }

    .uac-slider .card-body {
        padding: 1.25rem 0;
        max-width: unset;
    }
}
