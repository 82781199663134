.borderlessTextField {
    max-width: 410px;
    width: 100%;
}
.borderlessTextField .btlLabel {
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    display: block;
}

.eb-input-error {
    color: red;
}

.borderlessTextField .btlInput {
    font-size: 16px;
    color: #000;
    padding: 8.5px 15px;
    border: 1px solid #cfcece;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.borderlessTextField .btlInput::placeholder {
    color: #828282;
}

.borderlessTextField .btlInput:hover,
.borderlessTextField .btlInput:focus {
    background: #fff;
    border: 1px solid var(--primColor);
    outline: 0;
}

@media (max-width: 767px) {
    .ArtAuction .od-box .borderlessTextField {
        max-width: 100%;
    }
}
