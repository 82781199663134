.gridViewSkeleton {
    width: 100%;
    height: 460px;
    max-width: 350px;
}

.listViewSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    margin: 15px 0;
    background: #f7f7f7;
}

.listViewSkeleton.dark {
    background: #272727;
}
