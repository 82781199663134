.supports {
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding-top: 5px;
}
.imageh {
    max-width: 100%;
    max-height: 100%;
}
