.carPstWrpr {
    background: white;
    padding: 40px 0;
}

.carPstWrpr .carPostList {
    padding: 40px 30px;
    background: white;
    box-shadow: 0 0 30px #00000017;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    width: 100%;
    border: 1px solid #e3e3e3;
}

.Auctioneer .carPstWrpr .carPostList .ck-sticky-panel__content {
    z-index: 1;
}
.carPstWrpr .carPostList .pstHdr {
    color: var(--primColor);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 40px;
}

.carPstWrpr .carPostList .infrmInfo .alrtInfo {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}

.carPstWrpr .MuiButtonBase-root.MuiAccordionSummary-root .MuiTypography-root {
    font-weight: 500;
}

.carPstWrpr .actnBtnWrpr {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carPstWrpr .actnBtnWrpr .primButton,
.carPstWrpr .actnBtnWrpr .secButton,
.carPstWrpr .actnBtnWrpr button {
    max-width: 250px;
    width: 100%;
    margin-top: 30px;
}

.carPstWrpr .brdrTop {
    border-top: 1px solid #c2c2c2;
    margin-top: 15px;
    padding-top: 12px;
}

.carPstWrpr .brdrDv {
    border: 1px dashed #d3d0d0;
    position: relative;
}

.carPstWrpr .brdrDv:last-child {
    border-left: none;
}

.carPstWrpr .brdrDv .dvLbl {
    position: absolute;
    top: -10px;
    left: 10px;
    background: white;
    padding: 0 10px;
    color: gray;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
}
@media (max-width: 475px) {
    .Auctioneer .carPstWrpr .carPostList .MuiStepper-root {
        padding: 24px 0px;
        overflow-x: scroll;
    }
}
